import axios from 'axios';

const UserService = {

  async initialization() {
    const response = await axios.get('/api/v1/auth/profile');
    return response.data.user;
  },

  async updateUser(formData) {
    const response = await axios.patch('/api/v1/user/update', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data.user;
  },

  async registrationByEmail(body) {
    const response = await axios.post('/api/v1/auth/email/register', body);
    return response.data;
  },

  async getDeeplinkAuth() {
    const response = await axios.get('/api/v1/diia/auth');
    return response.data;
  },

  async registrationByPhone(body) {
    const response = await axios.post('/api/v1/auth/phone/send-code', body);
    return response.data.code;
  },

  async removeGoogle() {
    const response = await axios.patch('/api/v1/user/google-remove');
    return response.data;
  },

  async setPassword(body) {
    const response = await axios.patch('/api/v1/auth/password/create', body);
    return response.data;
  },

  async loginByPhone(body) {
    const response = await axios.patch('/api/v1/auth/phone/send-code', body);
    return response.data.code;
  },

  async verifiedCode(body) {
    const response = await axios.post('/api/v1/auth/phone/verified-code', body);
    return response.data;
  },

  async loginByEmail(body) {
    const response = await axios.post('/api/v1/auth/email/login', body);
    return response.data;
  },

  async logout() {
    await axios.delete('/api/v1/auth/logout');
    return true;
  },

  // Cars
  async addCar(formData) {
    const response = await axios.post('/api/v1/user/car', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data.user;
  },

  async updateCar(formData) {
    const response = await axios.patch('/api/v1/user/car', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data.user;
  },

  async deleteCar(body) {
    const response = await axios.delete(`/api/v1/user/car/${body.idCar}`, body.idUser);
    return response.data.user;
  },

  async generatePDF(body) {
    const response = await axios.post('/api/v1/user/pdf', body, {
      responseType: 'blob',
    });
    return response.data;
  },

};

export default UserService;
