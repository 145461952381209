import React, { useEffect, useState } from 'react';
import './style.scss';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { ReactComponent as Edit } from '../../../../../assets/edit.svg';
import { setAnswers, useCase } from '../../../../store/modules/Case';
import ModalWrapper from '../../../../components/ModalWrapper';
import WarningModal from '../../../../components/WarningModal';

function ItemTypeInquirer({ item, index }) {
  const dispatch = useDispatch();
  const { currentCase } = useCase();
  const [update, setUpdate] = useState(false);
  const [checkBoxValue, setCheckBoxValue] = useState('');
  const [openWarningModal, setOpenWarningModal] = useState(false);
  const answer = currentCase?.answers?.find((el) => el.question === item._id);

  const handleOpenWarningModal = (isOpen) => {
    setOpenWarningModal(isOpen);
  };

  const finishInquirer = {
    checkBoxValue,
  };

  useEffect(() => {
    if (answer) {
      setCheckBoxValue(answer.value.checkBoxValue);
    }
  }, [answer]);

  const handleSelect = () => () => {
    if (checkBoxValue === null) {
      toast.error('Заповніть дані');
      return;
    } if (checkBoxValue === 'Нi') {
      setOpenWarningModal(true);
    }
    setUpdate(false);
    if (answer) {
      dispatch(setAnswers({ question: item._id, value: finishInquirer }));
    } else {
      dispatch(setAnswers({ question: item._id, value: finishInquirer, new: true }));
    }
  };

  return (
    <>
      {!answer || update
        ? (
          <>{index > currentCase?.answers?.length
            ? (
              <div className="content-item-step-inactive">
                <div className="title-item-step-inactive"><div>{item.title}</div></div>
                <div className="description-item-step-inactive">Не пройдено</div>
              </div>
            )
            : (
              <div className="content-item-type-questionnaire">
                <div className="bang-item-type-questionnaire" />
                <div className="title-item-type-questionnaire">{item.title}</div>
                <div className="title-entry-field-item-type-delivery">У 4 пункті постанови вказані ваші ПІБ</div>
                <div className="container-radio-item-type-questionnaire">
                  <label className="item-radio-item-type-questionnaire"> <span className="text-item-radio-item-type-questionnaire">Так</span>
                    <input type="radio" name="policeConfirm" value="Так" checked={checkBoxValue === 'Так'} onChange={() => setCheckBoxValue('Так')} />
                    <span className="checkmark-item-radio-item-type-questionnaire" />
                  </label>
                  <label className="item-radio-item-type-questionnaire"> <span className="text-item-radio-item-type-questionnaire">Нi</span>
                    <input type="radio" name="policeConfirm" value="Нi" checked={checkBoxValue === 'Нi'} onChange={() => setCheckBoxValue('Нi')} />
                    <span className="checkmark-item-radio-item-type-questionnaire" />
                  </label>
                </div>
                <div className="btn-item-type-delivery" onClick={handleSelect('Пройдено')}>{item.button}</div>
              </div>
            )}
          </>
        )
        : (
          <>
            <div className="content-item-step-inactive">
              <div className="title-item-step-inactive"><div>{item.title}</div>{answer ? <span onClick={() => setUpdate(true)}><Edit /></span> : null}</div>
              <div className="description-item-step-inactive">Пройдено</div>
            </div>
          </>
        )}
      <ModalWrapper
        isOpened={openWarningModal}
        onClose={() => handleOpenWarningModal(false)}
      >
        {(onClose) => <WarningModal onClose={onClose} />}
      </ModalWrapper>
    </>

  );
}
export default ItemTypeInquirer;
