import React, { useState } from 'react';
// import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import config from 'config';
import { validateEmail } from 'app/utils/validation';
import Loading from 'app/components/Loading';
import {
  registrationByEmail,
  // registrationByPhone,
  // verifiedCode,
  useProfile,
} from 'app/store/modules/Profile';
import { setSection } from 'app/store/modules/Common';
import Button from 'app/components/ui/Button';
// import { ReactComponent as Diya } from 'assets/diya.svg';
// import { ReactComponent as Apple } from 'assets/apple.svg';
// import { ReactComponent as Email } from 'assets/email.svg';
import { ReactComponent as Gmail } from 'assets/gmail.svg';
import 'react-toastify/dist/ReactToastify.css';
import './style.scss';
import DiyaQR from '../../../DiyaQR';
import ModalWrapper from '../../../ModalWrapper';
import Rights from './components/RightsModal';

function Registration() {
  const dispatch = useDispatch();
  const { isLoading } = useProfile();

  // const [showCode, setShowCode] = useState(null);
  // const [code, setCode] = useState('');
  // const [type, setType] = useState('number');
  const [step, setStep] = useState(0);
  const [loginInput, setLoginInput] = useState('');
  const [passwordInput, setPasswordInput] = useState('');
  const [openRightsModal, setRightsModal] = useState(false);

  const [openDiya, setOpenDiya] = useState(false);

  const updateSection = (s) => {
    dispatch(setSection(s));
  };

  const handleRightsModal = (isOpen) => {
    setRightsModal(isOpen);
  };

  const handleRegistrationByEmail = () => {
    if (!validateEmail(loginInput) || !passwordInput.length) {
      toast.error('Перевірте правильність написання email');
    }

    dispatch(
      registrationByEmail({
        email: loginInput.toLowerCase(),
        password: passwordInput,
      }),
    );
  };

  // const handleSendCode = async () => {
  //   const result = loginInput.replace(/\D/g, '').replace(/^7/, '');

  //   if (result.length < 12) {
  //     toast.error('Невірно набраний номер');
  //   }

  //   dispatch(
  //     registrationByPhone({
  //       phone: result,
  //     })
  //   ).then((state) => {
  //     if (!state.error) {
  //       setShowCode(state.payload);
  //     }
  //   });
  // };

  // const handleRegistrationByPhone = async () => {
  //   if (!code.length || code.length < 6) {
  //     toast.error('Код повинен мати 6 цифр');
  //   }
  //   dispatch(
  //     verifiedCode({
  //       code,
  //     })
  //   );
  // };

  return (
    <>
      <ModalWrapper isOpened={openDiya} onClose={() => setOpenDiya(false)}>
        {(onClose) => <DiyaQR onClose={onClose} reg />}
      </ModalWrapper>
      <div className="wrapper-registration-ulex">
        {isLoading ? (
          <div className="loading">
            <Loading white />
          </div>
        ) : null}
        <div className="content-registration-ulex">
          <div className="container-registration first">
            <div className="item-registration-title">Реєстрація</div>
            <div className="item-registration-info">
              Створіть свій власний аккаунт та отримайте можливість користуватися сервісом.
            </div>
            {/*<Button onClick={() => handleRightsModal(true)} className="item-registration-rights">*/}
            {/*  Рееструючись у Ulex ви погоджуютесь з нашими Умовами Користування та Політикою*/}
            {/*  Приватності*/}
            {/*</Button>*/}
            <div className="item-registration-rights">
              Рееструючись у Ulex ви погоджуютесь з нашими <a target="_blank" rel="noreferrer" href={`${config.apiUrl}/public/pages/terms_admin.html`}>Умовами Використання</a> та <a target="_blank" rel="noreferrer" href={`${config.apiUrl}/public/pages/politics.html`}>Політикою Конфіденційності.</a><a target="_blank" rel="noreferrer" href={`${config.apiUrl}/public/pages/info_service.html`}> Iнформацiя про сервiс </a>
            </div>
            <div className="item-registration-link-authorization">
              Вже маєте аккаунт?{' '}
              <span onClick={() => updateSection('authorization')}>Увійдіть</span>
            </div>
          </div>
          <div className="container-registration second">
            <form className="form-registration">
              {/* {type === 'number' ? (
                <>
                  {!showCode ? (
                    <>
                      <div className="item-registration">
                        <label>Номер телефону:</label>
                        <InputMask mask="+380 (99) 999 9999" value={loginInput} onChange={(e) => { setLoginInput(e.target.value); }} placeholder="+380 (00) 000 0000" />
                      </div>
                      <div className="number-registration-note">На вказаний номер прийде код підтвердження:</div>
                      <div className="btn-form-registration" onClick={handleSendCode}>Відправити код</div>
                    </>
                  ) : (
                    <>
                      <div className="item-registration">
                        <label>Код:</label>
                        <input type="number" onChange={(e) => (e.target.value.length < 7 ? setCode(e.target.value) : null)} value={code} placeholder="000000" />
                      </div>
                      <div className="btn-form-registration" onClick={handleRegistrationByPhone}>Зареєструватися</div>
                    </>
                  )}
                </>
              ) : null } */}

              <>
                <div className="item-registration">
                  <label>Електронна пошта:</label>
                  <input
                    type="text"
                    onChange={(e) => {
                      setLoginInput(e.target.value);
                    }}
                    value={loginInput}
                    placeholder="ulex@example.ua"
                  />
                </div>
                <div className="item-authorization-password">
                  <label>Створіть пароль:</label>
                  <input
                    type="password"
                    onChange={(e) => {
                      setPasswordInput(e.target.value);
                    }}
                    value={passwordInput}
                    placeholder="********"
                  />
                </div>
                <div className="btn-form-registration" onClick={handleRegistrationByEmail}>
                  Зареєструватися
                </div>
              </>
            </form>

            {step === 0 ? (
              <>
                <div className="item-registration-link-authorization-mobile">
                  Вже маєте аккаунт?{' '}
                  <span onClick={() => updateSection('authorization')}>Увійдіть</span>
                </div>
                <div className="item-or-registration">
                  <div className="title-or-registration">Зареєструватися використовуючи</div>
                  <div className="line-or-registration" />
                </div>
                <div className="item-other-options-registration">
                  {/* {type === 'number' ? (
                    <Button onClick={() => { setType('email'); setLoginInput(''); setStep(0); }}><Email /> Електронну пошту
                    </Button>
                  ) : null}
                  {type === 'email' ? (
                    <Button onClick={() => { setType('number'); setLoginInput(''); setStep(0); }}><Email /> Мобільний номер
                    </Button>
                  ) : null} */}
                  {/* <Button>
                    <Apple /> Apple аккаунт
                  </Button> */}
                  <Button link={`${config.apiUrl}/api/v1/auth/google`}>
                    <Gmail /> Google аккаунт
                  </Button>
                  {/* <Button onClick={() => setOpenDiya(true)} className="diya"><Diya /> Дія аккаунт</Button> */}
                </div>
              </>
            ) : null}
            {step === 1 ? (
              <div
                className="btn-back-start-authorization"
                onClick={() => {
                  setStep(0);
                }}
              >
                Повернутися назад
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div>
        <ModalWrapper isOpened={openRightsModal} onClose={() => handleRightsModal(false)}>
          {(onClose) => <Rights onClose={onClose} />}
        </ModalWrapper>
      </div>
    </>
  );
}
export default Registration;
