import React, { useEffect, useState } from 'react';
import './style.scss';
import ulexBlack from 'assets/ulexBlack.svg';
import arrowDouble from 'assets/arrowDouble.svg';
import diya, { ReactComponent as Diya } from 'assets/diya.svg';
import { ReactComponent as Email } from 'assets/email.svg';
import Button from '../../components/ui/Button';
import { MoreDataProvider, useMoreData } from './context';
import ItemStep1 from './steps/itemStep1';
import ItemStep2 from './steps/itemStep2';
import ItemStep3 from './steps/itemStep3';
import ModalWrapper from '../../components/ModalWrapper';
import DiyaQR from '../../components/DiyaQR';
import ItemStep4 from './steps/itemStep4';
import ItemStep5 from './steps/itemStep5';

function MoreDataRender({ onClose: onCloseMore, edit = false }) {
  const { step, setStep } = useMoreData();
  const [openModal, setOpenModal] = useState(false);
  const [dataDiya, setDataDiya] = useState(null);

  useEffect(() => {
    if (dataDiya) {
      setStep(1);
    }
  }, [dataDiya]);

  const titles = {
    1: 'Загальна інформація',
    2: 'Персональні дані',
    3: 'Місце проживання за пропискою',
    4: 'Ваші Документи',
    5: 'Підтвердження збереження',
  };

  const whatTitle = () => {
    return edit ? 'Редагувати дані' : titles[step] || 'Необхідні додаткові дані!';
  };

  return (
    <>
      <ModalWrapper isOpened={openModal} onClose={() => setOpenModal(false)}>
        {(onClose) => <DiyaQR onClose={onClose} setDataDiya={setDataDiya} />}
      </ModalWrapper>
      <div className="wrapper-more-data-ulex">
        <div className="container-more-data-ulex">
          <div className="content-more-data-ulex">
            <div className="header-more-data">
              {step ? (
                <>
                  <img style={{ height: '62px' }} src={diya} alt="" />
                  <img src={arrowDouble} alt="" />
                  <img style={{ height: '40px' }} src={ulexBlack} alt="" />
                </>
              )
                : (
                  <>
                    <img src={ulexBlack} alt="" />
                    <div className="separator" />
                    <img src={diya} alt="" />
                  </>
                )}
            </div>
            <div className="title-more-data">{whatTitle()}</div>
            {step && step !== 5 ? (
              <div className="steps-more-data">
                <Button onClick={() => setStep(1)} className={`step ${step === 1 ? 'active' : 'finish'}`}>{step === 1 ? 'Крок' : ''} 1</Button>
                <img src={arrowDouble} alt="" />
                <Button onClick={() => setStep(2)} disabled={step < 2} className={`step ${step === 2 ? 'active' : step < 2 ? '' : 'finish'}`}>{step === 2 ? 'Крок' : ''} 2</Button>
                <img src={arrowDouble} alt="" />
                <Button onClick={() => setStep(3)} disabled={step < 3} className={`step ${step === 3 ? 'active' : step < 3 ? '' : 'finish'}`}>{step === 3 ? 'Крок' : ''} 3</Button>
                <img src={arrowDouble} alt="" />
                <Button onClick={() => setStep(3)} disabled={step < 4} className={`step ${step === 4 ? 'active' : step < 4 ? '' : 'finish'}`}>{step === 4 ? 'Крок' : ''} 4</Button>
              </div>
            ) : null}
            {!step ? (
              <>
                {!edit ? (
                  <div className="desc-more-data">Для створення заявок нам знадобляться більш широкий список Ваших
                    даних. <br /> <br /> Ви
                    можете вручну їх заповнити, або автоматично, завядки використанню Дії.
                  </div>
                ) : null}
                <div className={`buttons-more-data ${edit ? 'edit' : ''}`}>
                  <Button onClick={() => setStep(1)}><Email />{edit ? 'Редагувати вручну' : 'Заповнити вручну'}</Button>
                  <Button onClick={() => setOpenModal(true)} className="diya"><Diya />{edit ? 'Редагувати через Дію' : 'Заповнити через Дію'}</Button>
                </div>
                <div className="footer-more-data">
                  <Button onClick={onCloseMore} className="clear">{edit ? 'Відмінити' : 'Добавлю пізніше'}</Button>
                </div>
              </>
            ) : null}
            {step === 1 ? (
              <ItemStep1 />
            ) : null}
            {step === 2 ? (
              <ItemStep2 />
            ) : null}
            {step === 3 ? (
              <ItemStep3 />
            ) : null}
            {step === 4 ? (
              <ItemStep4 onClose={onCloseMore} />
            ) : null}
            {step === 5 ? (
              <ItemStep5 onClose={onCloseMore} />
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}

function MoreData({ onClose, edit = false }) {
  return (
    <MoreDataProvider>
      <MoreDataRender onClose={onClose} edit={edit} />
    </MoreDataProvider>
  );
}

export default MoreData;
