import React, { useEffect, useState } from 'react';
import './style.scss';
import { ReactComponent as Edit } from 'assets/edit.svg';
import { ReactComponent as Save } from 'assets/save.svg';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { useMoreData } from '../../../../context';
import { optionsSelectRegiment } from '../../../../../CreatingStatement/typeStep/ItemTypeDelivery';
import { colourStylesData } from '../dataBlock2';

function DataBlock3({ checkEdit, setCheckEdit }) {
  const { answers, setAnswers } = useMoreData();
  const [cityInput, setCityInput] = useState('');
  const [streetInput, setStreetInput] = useState('');
  const [indexInput, setIndexInput] = useState('');
  const [houseInput, setHouseInput] = useState('');
  const [currentRegiment, setCurrentRegiment] = useState(optionsSelectRegiment[0]);
  const [cityInputSecond, setCityInputSecond] = useState('');
  const [streetInputSecond, setStreetInputSecond] = useState('');
  const [indexInputSecond, setIndexInputSecond] = useState('');
  const [houseInputSecond, setHouseInputSecond] = useState('');
  const [currentRegimentSecond, setCurrentRegimentSecond] = useState(optionsSelectRegiment[0]);
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    setCityInput(answers.city);
    setStreetInput(answers.street);
    setIndexInput(answers.index);
    setHouseInput(answers.house);
    setCurrentRegiment(optionsSelectRegiment.find((item) => item.label === answers.regiment) || optionsSelectRegiment[0]);
    setCurrentRegimentSecond(optionsSelectRegiment.find((item) => item.label === answers.regimentSecond) || optionsSelectRegiment[0]);

    if (!answers.regiment) {
      setAnswers({ ...answers, regiment: optionsSelectRegiment[0].label });
    }
    setCityInputSecond(answers.citySecond);
    setStreetInputSecond(answers.streetSecond);
    setIndexInputSecond(answers.indexSecond);
    setHouseInputSecond(answers.houseSecond);
  }, []);

  const updateCity = () => {
    if (cityInput.length) {
      if (cityInput.length > 2) {
        setAnswers({ ...answers, city: cityInput });
      } else {
        toast.error('Заповніть місто проживання');
      }
    }
  };

  const updateCitySecond = () => {
    if (cityInputSecond.length) {
      if (cityInputSecond.length > 2) {
        setAnswers({ ...answers, citySecond: cityInputSecond });
      } else {
        toast.error('Заповніть місто проживання');
      }
    }
  };

  const updateStreet = () => {
    if (streetInput.length) {
      if (streetInput.length > 2) {
        setAnswers({ ...answers, street: streetInput });
      } else {
        toast.error('Заповніть вулицю проживання');
      }
    }
  };

  const updateStreetSecond = () => {
    if (streetInputSecond.length) {
      if (streetInputSecond.length > 2) {
        setAnswers({ ...answers, streetSecond: streetInputSecond });
      } else {
        toast.error('Заповніть вулицю проживання');
      }
    }
  };

  const updateIndex = () => {
    if (indexInput.length) {
      if (indexInput.length === 5) {
        setAnswers({ ...answers, index: indexInput });
      } else {
        toast.error('Невірний індекс');
      }
    }
  };

  const updateIndexSecond = () => {
    if (indexInputSecond.length) {
      if (indexInputSecond.length === 5) {
        setAnswers({ ...answers, indexSecond: indexInputSecond });
      } else {
        toast.error('Невірний індекс');
      }
    }
  };

  const updateHouse = () => {
    if (houseInput.length) {
      setAnswers({ ...answers, house: houseInput });
    }
  };

  const updateHouseSecond = () => {
    if (houseInputSecond.length) {
      setAnswers({ ...answers, houseSecond: houseInputSecond });
    }
  };

  const updateRegiment = (option) => {
    setCurrentRegiment(option);
    setAnswers({ ...answers, regiment: option.label });
  };

  const updateRegimentSecond = (option) => {
    setCurrentRegimentSecond(option);
    setAnswers({ ...answers, regimentSecond: option.label });
  };

  const handleEdit = () => {
    if (checkEdit && checkEdit !== 'block3') return toast.error('Спочатку відредагуйте минулі дані');
    if (!edit) {
      setCheckEdit('block3');
      return setEdit(true);
    }

    const allFieldsFilled = answers.identCode.length && answers.phoneNumber.length === 12 && answers.birthday.length && answers.passportID.length;
    if (!allFieldsFilled) {
      return toast.error('Заповніть всі поля');
    }

    setCheckEdit(false);
    setEdit(false);
  };

  return (
    <>
      <div className="dataFinishBlock">
        <div className="dataFinishBlock__wrapper">
          <div className="dataFinishBlock__header">
            <div className="dataFinishBlock__header__left">
              <div className="dataFinishBlock__header__step">Крок 3</div>
              <div className="dataFinishBlock__header__title">Місце проживання</div>
            </div>
            <div onClick={handleEdit} className="dataFinishBlock__header__edit">{edit ? <Save /> : <Edit />}</div>
          </div>
          <div className="dataFinishBlock__allItems">
            <div className="dataFinishBlock__item">
              <span>Місто</span>
              <input disabled={!edit} onBlur={updateCity} onChange={(e) => setCityInput(e.target.value)} value={cityInput} type="text" placeholder="Бориспіль" />
            </div>
            <div className="dataFinishBlock__item">
              <span>Вулиця</span>
              <input disabled={!edit} onBlur={updateStreet} onChange={(e) => setStreetInput(e.target.value)} value={streetInput} type="text" placeholder="Головатого" />
            </div>
            <div className="dataFinishBlock__item">
              <span>Область</span>
              <Select isDisabled={!edit} onChange={updateRegiment} value={currentRegiment} options={optionsSelectRegiment} styles={colourStylesData} />
            </div>
            <div className="dataFinishBlock__item">
              <span>Індекс</span>
              <input disabled={!edit} onBlur={updateIndex} onChange={(e) => (e.target.value.length < 6 ? setIndexInput(e.target.value) : null)} value={indexInput} type="number" placeholder="08300" />
            </div>
            <div className="dataFinishBlock__item">
              <span>Дім</span>
              <input disabled={!edit} onBlur={updateHouse} onChange={(e) => setHouseInput(e.target.value)} value={houseInput} type="text" placeholder="12" />
            </div>
            {answers.streetSecond
              ? (
                <>
                  <div className="dataFinishBlock__title">
                    Фактичне місце проживання
                  </div>
                  <div className="dataFinishBlock__item">
                    <span>Місто</span>
                    <input disabled={!edit} onBlur={updateCitySecond} onChange={(e) => setCityInputSecond(e.target.value)} value={cityInputSecond} type="text" placeholder="Бориспіль" />
                  </div>
                  <div className="dataFinishBlock__item">
                    <span>Вулиця</span>
                    <input disabled={!edit} onBlur={updateStreetSecond} onChange={(e) => setStreetInputSecond(e.target.value)} value={streetInputSecond} type="text" placeholder="Головатого" />
                  </div>
                  <div className="dataFinishBlock__item">
                    <span>Область</span>
                    <Select isDisabled={!edit} onChange={updateRegimentSecond} value={currentRegimentSecond} options={optionsSelectRegiment} styles={colourStylesData} />
                  </div>
                  <div className="dataFinishBlock__item">
                    <span>Індекс</span>
                    <input disabled={!edit} onBlur={updateIndexSecond} onChange={(e) => (e.target.value.length < 6 ? setIndexInputSecond(e.target.value) : null)} value={indexInputSecond} type="number" placeholder="08300" />
                  </div>
                  <div className="dataFinishBlock__item">
                    <span>Дім</span>
                    <input disabled={!edit} onBlur={updateHouseSecond} onChange={(e) => setHouseInputSecond(e.target.value)} value={houseInputSecond} type="text" placeholder="12" />
                  </div>
                </>
              ) : null}
          </div>
        </div>
      </div>
    </>
  );
}
export default DataBlock3;
