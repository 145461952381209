import React, { useEffect, useState } from 'react';
import './index.scss';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { ReactComponent as Edit } from '../../../../../assets/edit.svg';
import { setAnswers, useCase } from '../../../../store/modules/Case';

function ItemTypeCircumstances({ item, index }) {
  const dispatch = useDispatch();
  const { currentCase } = useCase();
  const [update, setUpdate] = useState(false);
  const answer = currentCase?.answers?.find((el) => el.question === item._id);
  const [textarea, setTextarea] = useState('');

  const finishCircumstances = {
    textarea,
  };

  useEffect(() => {
    if (answer) {
      setTextarea(answer.value.textarea);
    }
  }, [answer]);

  const handleSelect = () => {
    if (!textarea) {
      toast.error('Заповніть дані');
      return;
    }
    setUpdate(false);
    if (answer) {
      dispatch(setAnswers({ question: item._id, value: finishCircumstances }));
    } else {
      dispatch(setAnswers({ question: item._id, value: finishCircumstances, new: true }));
    }
  };

  return (
    <>
      {!answer || update
        ? (
          <>{index > currentCase?.answers?.length
            ? (
              <div className="content-item-step-inactive">
                <div className="title-item-step-inactive"><div>{item.title}</div></div>
                <div className="description-item-step-inactive">Не пройдено</div>
              </div>
            )
            : (
              <div className="content-item-type-circumst">
                <div className="bang-item-type-circumst" />
                <div className="title-item-type-circumst">{item.title}</div>
                <div className="description-item-type-circumst">{item.description}</div>
                <div className="entry-field-item-type-circumst">
                  <textarea className="placeholder-field-item-type-circumst" placeholder="Введіть дані" onChange={(e) => setTextarea(e.target.value)} />
                </div>

                <div className="btn-item-type-circumst" onClick={handleSelect}>{item.button}</div>
              </div>
            )}
          </>
        )
        : (
          <>
            <div className="content-item-step-inactive">
              <div className="title-item-step-inactive"><div>{item.title}</div>{answer ? <span onClick={() => setUpdate(true)}><Edit /></span> : null}</div>
              <div className="description-item-step-inactive">Пройдено</div>
            </div>
          </>
        )}
    </>
  );
}

export default ItemTypeCircumstances;
