import React, { useState } from 'react';
import './style.scss';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { updateUser } from 'app/store/modules/Profile/actions';
import { useMoreData } from '../../context';
import Button from '../../../../components/ui/Button';
import Index from './components/dataBlock1';
import DataBlock2 from './components/dataBlock2';
import DataBlock3 from './components/dataBlock3';
import DataBlock4 from './components/dataBlock5';

function ItemStep5({ onClose }) {
  const dispatch = useDispatch();
  const { answers, setStep } = useMoreData();
  const { passportFiles } = useMoreData();
  const [checkEdit, setCheckEdit] = useState(false);
  const [checkScroll, setCheckScroll] = useState(false);

  const handleCheckScroll = (event) => {
    const myDiv = event.target;
    if (myDiv.scrollTop + myDiv.clientHeight + 2 >= myDiv.scrollHeight) {
      setCheckScroll(true);
    }
  };

  const handleSave = () => {
    if (checkEdit) return toast.error('Спочатку збережіть відредаговані дані');
    if (!checkScroll) return toast.error('Догорніть до кінця');

    const formData = new FormData();

    formData.append('data', JSON.stringify(answers));

    if (passportFiles.passportFile3 && passportFiles.passportFile3.file) {
      formData.append('passportBook', passportFiles.passportFile1.file.originFileObj);
      formData.append('passportBook', passportFiles.passportFile2.file.originFileObj);
      formData.append('passportBook', passportFiles.passportFile3.file.originFileObj);
    } else if (passportFiles.passportFile1.file) {
      formData.append('passportCard', passportFiles.passportFile1.file.originFileObj);
      formData.append('passportCard', passportFiles.passportFile2.file.originFileObj);
    }

    if (passportFiles.identCode.file) {
      formData.append('identCode', passportFiles.identCode.file.originFileObj);
    }

    if (passportFiles.driveLicense.file) {
      formData.append('driveLicense', passportFiles.driveLicense.file.originFileObj);
    }

    if (passportFiles.driveLicenseSecond.file) {
      formData.append('driveLicenseSecond', passportFiles.driveLicenseSecond.file.originFileObj);
    }

    toast
      .promise(dispatch(updateUser(formData)), {
        pending: 'Завантаження даних',
      })

      .then((res) => {
        if (!res.error) {
          onClose();
        } else {
          toast.error('Помилка ');
        }
      });
  };

  return (
    <>
      <div className="wrapper-step">
        <div className="itemStep5">
          <div className="itemStep5__title">
            Переконайтеся у достовірності введеної Вами інформації. Для підтвердження необхідно полистати список до кінця.
          </div>
          <div onScroll={handleCheckScroll} className="itemStep5__allData">
            <div className="itemStep5__allData__wrapper">
              <Index setCheckEdit={setCheckEdit} checkEdit={checkEdit} />
              <DataBlock2 setCheckEdit={setCheckEdit} checkEdit={checkEdit} />
              <DataBlock3 setCheckEdit={setCheckEdit} checkEdit={checkEdit} />
              <DataBlock4 setCheckEdit={setCheckEdit} checkEdit={checkEdit} />
            </div>
          </div>
        </div>
        <div className="buttons-step">
          <Button disabled={!checkScroll || checkEdit} className="black" onClick={handleSave}>Зберегти дані</Button>
        </div>
        <div className="footer-step">
          <Button onClick={() => setStep(null)} className="clear">Відмінити</Button>
        </div>
      </div>
    </>
  );
}

export default ItemStep5;
