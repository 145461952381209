import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import WikiPost from '../views/Wiki/[id]';
import Main from '../views/Main';
import MyStatements from '../views/MyStatements';
import MyCars from '../views/MyCars';
import CreatingStatement from '../views/CreatingStatement';
import { initialization, useProfile } from '../store/modules/Profile';
import ModalWrapper from '../components/ModalWrapper';
import MoreData from '../views/MoreData';
import Wiki from '../views/Wiki';
import AuthGuard from './AuthGuard';

function Router() {
  const dispatch = useDispatch();
  const { profile } = useProfile();
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    dispatch(initialization());
  }, []);

  useEffect(() => {
    if (profile && !profile.data) {
      const checkShowedMoreData = sessionStorage.getItem('showedMoreData');
      if (checkShowedMoreData) return;
      setOpenModal(true);
    }
  }, [profile]);

  return (
    <>
      <ModalWrapper isOpened={openModal} onClose={() => setOpenModal(false)}>
        {(onClose) => {
          const handleClose = () => {
            sessionStorage.setItem('showedMoreData', 'true');
            onClose();
          };

          return <MoreData onClose={handleClose} />;
        }}
      </ModalWrapper>
      <Routes>
        <Route exact path="/" element={<Main />} />
        <Route element={<AuthGuard />}>
          <Route exact path="/my-statements" element={<MyStatements />} />
        </Route>
        <Route element={<AuthGuard />}>
          <Route exact path="/my-cars" element={<MyCars />} />
        </Route>
        <Route element={<AuthGuard />}>
          <Route exact path="/my-statements/:id" element={<CreatingStatement />} />
        </Route>
        <Route exact path="/wiki" element={<Wiki />} />
        <Route exact path="/wiki/:id" element={<WikiPost />} />
      </Routes>
    </>
  );
}

export default Router;
