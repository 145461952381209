import React from 'react';
import './style.scss';
import Button from '../ui/Button';

function WarningModal({ onClose }) {
  return (
    <div className="warning-modal">
      <div className="warning-modal__wrapper">
        <div className="warning-modal__content">
          <h4 className="warning-modal__content__text--regular">
            Нажаль, тільки володар ТЗ може оскаржити штраф
            &#x1F641; Але напішіть нам у ТГ, мі спробуємо вам допомогти
          </h4>
        </div>
        <div className="warning-modal__footer">
          <Button className="clear" onClick={onClose}>
            На головну
          </Button>
        </div>
      </div>
    </div>
  );
}

export default WarningModal;
