import React, {
  useRef,
  useState,
} from 'react';
import './style.scss';
import {
  Swiper,
  SwiperSlide,
} from 'swiper/react';
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';

// import { ReactComponent as Help } from 'assets/help.svg';
import circle from 'assets/circle-down.svg';
// import road from 'assets/road.svg';
import parking from 'assets/parking.svg';
// import notassigned from 'assets/not-assigned.svg';
// import road_active from 'assets/road-active.svg';
import parking_active from 'assets/parking-active.svg';
// import notassigned_active from 'assets/not-assigned-active.svg';
import hand from 'assets/hand.svg';
import ModalWrapper from 'app/components/ModalWrapper';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { createCase } from 'app/store/modules/Case/actions';
import HowToChoose from './components/HowToChoose';
import SuccessModal from '../../components/SuccessModal';
import { useProfile } from '../../store/modules/Profile';

function Main() {
  const { profile } = useProfile();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const notassignedRef = useRef();
  // const roadRef = useRef();
  const parkingRef = useRef();
  const [openAuth, setOpenAuth] = useState(false);

  const [openHowToChooseModal, setOpenHowToChooseModal] = useState(false);

  const handleOpenHowToChooseModal = (isOpen) => {
    setOpenHowToChooseModal(isOpen);
  };

  const handleCreateCase = async () => {
    const startCase = {
      client: profile?._id,
      answers: [
        // {
        //   questionId: 'string',
        //   value: 'string',
        //   questions: [
        //     'string',
        //   ],
        // },
      ],
      status: 'created',
    };

    dispatch(createCase(startCase)).then((res) => {
      if (!res.error) {
        navigate(`my-statements/${res.payload._id}`);
      } else {
        toast.error('Помилка ');
      }
    });
  };

  const createParkCase = () => {
    // if (!profile) {
    //   setOpenAuth(true);
    // } else {
    handleCreateCase().then((r) => console.log('f', r));
    // }
  };

  return (
    <>
      <ModalWrapper isOpened={openAuth} onClose={() => setOpenAuth(false)}>
        {() => <SuccessModal onClose={() => setOpenAuth(false)} type="auth" />}
      </ModalWrapper>
      <div className="wrapper-main-ulex">
        <div className="content-main-ulex">
          <div className="title-main">
            <span>Послуги щодо оскарження штрафів онлайн</span>
          </div>
          <div className="subtitle-main">
            <span>Усі проблеми мають власне рішення. Ми допоможемо його знайти!</span>
          </div>
          <div className="container-circle-down-main">
            <img src={circle} alt="" />
            <div className="line-circle-down" />
          </div>
          {/*<div className="container-info-main">*/}
          {/*  <div className="content-info-main">*/}
          {/*    <div className="title-info-main">*/}
          {/*      За порушення яких правил Вас притягнуто до відповідальності?*/}
          {/*    </div>*/}
          {/*    <div className="btn-info-main" onClick={() => handleOpenHowToChooseModal(true)}>*/}
          {/*      <div className="title-btn-info-main">*/}
          {/*        Як обрати*/}
          {/*      </div>*/}
          {/*      <Help />*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}

          <div className="container-swiper-main">
            <Swiper
              className="swiper-main"
              spaceBetween={24}
              slidesPerView="auto"
              pagination={{
                clickable: true,
              }}
              modules={[Pagination]}
            >
              {/*<SwiperSlide className="item-slide-main">*/}
              {/*  <div className="title-item-slide-main">На дорозі</div>*/}
              {/*  <img src={road} alt="" ref={roadRef} />*/}
              {/*  <div*/}
              {/*    onClick={createCase}*/}
              {/*    className="btn-item-slider-main"*/}
              {/*    onMouseLeave={() => {*/}
              {/*      roadRef.current.src = road;*/}
              {/*    }}*/}
              {/*    onMouseEnter={() => {*/}
              {/*      roadRef.current.src = road_active;*/}
              {/*    }}*/}
              {/*  >*/}
              {/*    Створити заяву*/}
              {/*  </div>*/}
              {/*</SwiperSlide>*/}
              <SwiperSlide className="item-slide-main">
                <div className="title-item-slide-main">На парковці</div>
                <img src={parking} alt="" ref={parkingRef} />
                <div
                  onClick={createParkCase}
                  className="btn-item-slider-main"
                  onMouseLeave={() => {
                    parkingRef.current.src = parking;
                  }}
                  onMouseEnter={() => {
                    parkingRef.current.src = parking_active;
                  }}
                >
                  Створити заяву
                </div>
              </SwiperSlide>
              {/*<SwiperSlide className="item-slide-main">*/}
              {/*  <div className="title-item-slide-main"> Не зазначено</div>*/}
              {/*  <img src={notassigned} alt="" ref={notassignedRef} />*/}
              {/*  <div*/}
              {/*    onClick={createCase}*/}
              {/*    className="btn-item-slider-main"*/}
              {/*    onMouseLeave={() => {*/}
              {/*      notassignedRef.current.src = notassigned;*/}
              {/*    }}*/}
              {/*    onMouseEnter={() => {*/}
              {/*      notassignedRef.current.src = notassigned_active;*/}
              {/*    }}*/}
              {/*  >*/}
              {/*    Створити заяву*/}
              {/*  </div>*/}
              {/*</SwiperSlide>*/}
              <div className="info-swiper-swipe-main">
                <img src={hand} alt="" />
                <div className="title-swiper-swipe-main">Перетягніть для прокрутки</div>
              </div>
            </Swiper>
          </div>
        </div>
        <div>
          <ModalWrapper
            isOpened={openHowToChooseModal}
            onClose={() => handleOpenHowToChooseModal(false)}
          >
            {(onClose) => <HowToChoose onClose={onClose} />}
          </ModalWrapper>
        </div>
      </div>
    </>
  );
}
export default Main;
