import React, { useEffect, useState } from 'react';
import './style.scss';
import { ReactComponent as Edit } from 'assets/edit.svg';
import { ReactComponent as Save } from 'assets/save.svg';
import { toast } from 'react-toastify';
import { useMoreData } from '../../../../context';

function DataBlock1({ checkEdit, setCheckEdit }) {
  const { answers, setAnswers } = useMoreData();
  const [firstNameInput, setFirstNameInput] = useState('');
  const [lastNameInput, setLastNameInput] = useState('');
  const [middleNameInput, setMiddleNameInput] = useState('');
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    setFirstNameInput(answers.firstName);
    setLastNameInput(answers.lastName);
    setMiddleNameInput(answers.middleName);
  }, []);

  const updateName = () => {
    if (firstNameInput.length) {
      setAnswers({ ...answers, firstName: firstNameInput });
    }
  };

  const updateLastName = () => {
    if (lastNameInput.length) {
      setAnswers({ ...answers, lastName: lastNameInput });
    }
  };

  const updateMiddleName = () => {
    if (middleNameInput.length) {
      setAnswers({ ...answers, middleName: middleNameInput });
    }
  };

  const handleEdit = () => {
    if (checkEdit && checkEdit !== 'block1') return toast.error('Спочатку відредагуйте минулі дані');
    if (!edit) {
      setCheckEdit('block1');
      return setEdit(true);
    }

    const allFieldsFilled = firstNameInput.length && lastNameInput.length && middleNameInput;
    if (!allFieldsFilled) {
      return toast.error('Заповніть всі поля');
    }

    setCheckEdit(false);
    setEdit(false);
  };

  return (
    <>
      <div className="dataFinishBlock">
        <div className="dataFinishBlock__wrapper">
          <div className="dataFinishBlock__header">
            <div className="dataFinishBlock__header__left">
              <div className="dataFinishBlock__header__step">Крок 1</div>
              <div className="dataFinishBlock__header__title">Загальна інформація</div>
            </div>
            <div onClick={handleEdit} className="dataFinishBlock__header__edit">{edit ? <Save /> : <Edit />}</div>
          </div>
          <div className="dataFinishBlock__allItems">
            <div className="dataFinishBlock__item">
              <span>Ім’я</span>
              <input disabled={!edit} onBlur={updateName} onChange={(e) => setFirstNameInput(e.target.value)} value={firstNameInput} type="text" placeholder="Iван" />
            </div>
            <div className="dataFinishBlock__item">
              <span>Прізвище</span>
              <input disabled={!edit} onBlur={updateLastName} onChange={(e) => setLastNameInput(e.target.value)} value={lastNameInput} type="text" placeholder="Iванов" />
            </div>
            <div className="dataFinishBlock__item">
              <span>По батькові</span>
              <input disabled={!edit} onBlur={updateMiddleName} onChange={(e) => setMiddleNameInput(e.target.value)} value={middleNameInput} type="text" placeholder="Iванович" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default DataBlock1;
