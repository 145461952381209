import React, { useState } from 'react';
import './style.scss';
import { useDispatch } from 'react-redux';
// import { ReactComponent as Edit } from '../../../../../assets/edit.svg';
import { setAnswers, useCase } from '../../../../store/modules/Case';
import ModalWrapper from '../../../../components/ModalWrapper';
import PaymentModal from '../../../../components/PaymentModal';

function ItemTypePayment({ item, index }) {
  const dispatch = useDispatch();
  const { currentCase } = useCase();
  const [update, setUpdate] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const answer = currentCase?.answers?.find((el) => el.question === item._id);

  const handleSelect = (el) => () => {
    setOpenModal(true);
    return;
    // eslint-disable-next-line no-unreachable
    setUpdate(false);
    if (answer) {
      dispatch(setAnswers({ question: item._id, value: el }));
    } else {
      dispatch(setAnswers({ question: item._id, value: el, new: true }));
    }
  };

  return (
    <>
      <ModalWrapper isOpened={openModal} onClose={() => setOpenModal(false)}>
        {() => <PaymentModal item={item} onClose={() => setOpenModal(null)} />}
      </ModalWrapper>
      {!answer || update
        ? (
          <>{index > currentCase?.answers?.length
            ? (
              <div className="content-item-step-inactive">
                <div className="title-item-step-inactive"><div>{item.title}</div></div>
                <div className="description-item-step-inactive">Не пройдено</div>
              </div>
            )
            : (
              <div className="content-item-type-payment">
                <div className="bang-item-type-payment" />
                <div className="title-item-type-payment">{item.title}</div>
                <div className="description-item-type-payment">{item.description}</div>
                <div className="payment-item-type-payment">
                  <div className="title-payment-item-type-payment">До сплати:</div>
                  <div className="body-payment-item-type-payment">{item.cost}₴ (грн)</div>
                </div>
                <div className="btn-item-type-payment" onClick={handleSelect('Пройдено')}>{item.button} {item.cost}₴</div>
              </div>
            )}
          </>
        )
        : (
          <>
            <div className="content-item-step-inactive">
              <div className="title-item-step-inactive"><div>{item.title}</div></div>
              <div className="description-item-step-inactive">Пройдено</div>
            </div>
          </>
        )}
    </>
  );
}
export default ItemTypePayment;
