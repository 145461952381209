import React, { useEffect, useState } from 'react';
import './style.scss';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { useMoreData } from '../../context';
import Button from '../../../../components/ui/Button';
import { optionsSelectRegiment } from '../../../CreatingStatement/typeStep/ItemTypeDelivery';

function ItemStep3() {
  const { answers, setAnswers, setStep } = useMoreData();
  const [cityInput, setCityInput] = useState('');
  const [streetInput, setStreetInput] = useState('');
  const [indexInput, setIndexInput] = useState('');
  const [houseInput, setHouseInput] = useState('');
  const [currentRegiment, setCurrentRegiment] = useState(optionsSelectRegiment[0]);
  const [cityInputSecond, setCityInputSecond] = useState('');
  const [streetInputSecond, setStreetInputSecond] = useState('');
  const [indexInputSecond, setIndexInputSecond] = useState('');
  const [houseInputSecond, setHouseInputSecond] = useState('');
  const [currentRegimentSecond, setCurrentRegimentSecond] = useState(optionsSelectRegiment[0]);
  const [checkAddress, setCheckAddress] = useState(true);

  useEffect(() => {
    if (!answers.regiment) {
      setAnswers((prevState) => ({
        ...prevState, regiment: currentRegiment.label, regimentSecond: currentRegimentSecond.label,
      }));
    }
    setCityInput(answers.city);
    setCityInputSecond(answers.citySecond);
    setStreetInput(answers.street);
    setStreetInputSecond(answers.streetSecond);
    setIndexInput(answers.index);
    setIndexInputSecond(answers.indexSecond);
    setHouseInput(answers.house);
    setHouseInputSecond(answers.houseSecond);
    setCurrentRegiment(optionsSelectRegiment.find((item) => item.label === answers.regiment) || optionsSelectRegiment[0]);
    setCurrentRegimentSecond(optionsSelectRegiment.find((item) => item.label === answers.regimentSecond) || optionsSelectRegiment[0]);
    if (answers.streetSecond) {
      setCheckAddress(false);
    }
  }, []);

  const colourStyles = {
    control: (styles) => {
      return {
        ...styles,
        marginTop: '12px',
        padding: '6px 6px',
        cursor: 'pointer',
        boxShadow: 'none',
        color: '#FC6B58',
        border: '1px solid #ccc',
        ':hover': {
          ...styles[':hover'],
          border: '1px solid #ccc',
        },
      };
    },
    option: (styles) => {
      return {
        ...styles,
        color: 'black',
        cursor: 'pointer',
        backgroundColor: 'transparent !important',
        ':hover': {
          ...styles[':hover'],
          cursor: 'pointer',
          color: '#FC6B58',
          backgroundColor: 'none',
        },
        '.active': {
          ...styles['.active'],
          backgroundColor: 'white',
          color: '#FC6B58',
        },
      };
    },
    singleValue: (styles) => {
      return {
        ...styles,
        backgroundColor: 'transparent',
        ':hover': {
          ...styles[':hover'],
          color: '#FC6B58',
        },
      };
    },
  };

  const updateCity = () => {
    if (cityInput.length) {
      if (cityInput.length > 2) {
        setAnswers({ ...answers, city: cityInput });
      } else {
        toast.error('Заповніть місто проживання');
      }
    }
  };

  const updateCitySecond = () => {
    if (cityInputSecond.length) {
      if (cityInputSecond.length > 2) {
        setAnswers({ ...answers, citySecond: cityInputSecond });
      } else {
        toast.error('Заповніть місто проживання');
      }
    }
  };

  const updateStreet = () => {
    if (streetInput.length) {
      if (streetInput.length > 2) {
        setAnswers({ ...answers, street: streetInput });
      } else {
        toast.error('Заповніть вулицю проживання');
      }
    }
  };

  const updateStreetSecond = () => {
    if (streetInputSecond.length) {
      if (streetInputSecond.length > 2) {
        setAnswers({ ...answers, streetSecond: streetInputSecond });
      } else {
        toast.error('Заповніть вулицю проживання');
      }
    }
  };

  const updateIndex = () => {
    if (indexInput.length) {
      if (indexInput.length === 5) {
        setAnswers({ ...answers, index: indexInput });
      } else {
        toast.error('Невірний індекс');
      }
    }
  };

  const updateIndexSecond = () => {
    if (indexInputSecond.length) {
      if (indexInputSecond.length === 5) {
        setAnswers({ ...answers, indexSecond: indexInputSecond });
      } else {
        toast.error('Невірний індекс');
      }
    }
  };

  const updateHouse = () => {
    if (houseInput.length) {
      setAnswers({ ...answers, house: houseInput });
    }
  };

  const updateHouseSecond = () => {
    if (houseInputSecond.length) {
      setAnswers({ ...answers, houseSecond: houseInputSecond });
    }
  };

  const updateRegiment = (option) => {
    setCurrentRegiment(option);
    setAnswers({ ...answers, regiment: option.label });
  };

  const updateRegimentSecond = (option) => {
    setCurrentRegimentSecond(option);
    setAnswers({ ...answers, regimentSecond: option.label });
  };

  const nextStep = () => {
    if (answers.city && answers.street && answers.index && answers.house && answers.regiment) {
      if (checkAddress) {
        setAnswers((prevState) => ({
          ...prevState, regimentSecond: '', citySecond: '', streetSecond: '', indexSecond: '', houseSecond: '',
        }));
        setStep(4);
      } else if (answers.citySecond && answers.streetSecond && answers.indexSecond && answers.houseSecond && answers.regimentSecond) {
        setStep(4);
      } else {
        toast.error('Заповніть всі поля');
      }
    } else {
      toast.error('Заповніть всі поля');
    }
  };

  return (
    <>
      <div className="wrapper-step">
        <div className="items-input-step">
          <div className="items-input-step__blockInputs">
            <div className="item-input-step">
              Місто:
              <input onBlur={updateCity} onChange={(e) => setCityInput(e.target.value)} value={cityInput} type="text" placeholder="Бориспіль" />
            </div>
            <div className="item-input-step">
              Вулиця:
              <input onBlur={updateStreet} onChange={(e) => setStreetInput(e.target.value)} value={streetInput} type="text" placeholder="Головатого" />
            </div>
          </div>
          <div className="item-input-step regiment">
            Область:
            <Select onChange={updateRegiment} value={currentRegiment} options={optionsSelectRegiment} styles={colourStyles} />
          </div>
          <div className="items-input-step__blockInputs">
            <div className="item-input-step">
              Індекс:
              <input onBlur={updateIndex} onChange={(e) => (e.target.value.length < 6 ? setIndexInput(e.target.value) : null)} value={indexInput} type="number" placeholder="08300" />
            </div>
            <div className="item-input-step">
              Дім:
              <input onBlur={updateHouse} onChange={(e) => setHouseInput(e.target.value)} value={houseInput} type="text" placeholder="12" />
            </div>
          </div>
          {/*<label className="itemStep3__label">*/}
          {/*  <span className="itemStep3__label__text">*/}
          {/*    Моє фактичне місце проживання співпадає із пропискою*/}
          {/*  </span>*/}
          {/*  <input checked type="radio" name="answer" />*/}
          {/*  <span className="itemStep3__label__checkmark" />*/}
          {/*</label>*/}
          <label className="item-radio-item-type-questionnaire">
            <span className="text-item-radio-item-type-questionnaire">
              Моє фактичне місце проживання співпадає із пропискою
            </span>
            <input checked={checkAddress} onChange={() => setCheckAddress(!checkAddress)} type="checkbox" name="answer" />
            <span className="checkmark-item-radio-item-type-questionnaire" />
          </label>
          {!checkAddress
            ? (
              <>
                <div className="itemStep3__title">
                  Фактичне місце проживання
                </div>
                <div className="items-input-step__blockInputs">
                  <div className="item-input-step">
                    Місто:
                    <input onBlur={updateCitySecond} onChange={(e) => setCityInputSecond(e.target.value)} value={cityInputSecond} type="text" placeholder="Бориспіль" />
                  </div>
                  <div className="item-input-step">
                    Вулиця:
                    <input onBlur={updateStreetSecond} onChange={(e) => setStreetInputSecond(e.target.value)} value={streetInputSecond} type="text" placeholder="Головатого" />
                  </div>
                </div>
                <div className="item-input-step regiment">
                  Область:
                  <Select onChange={updateRegimentSecond} value={currentRegimentSecond} options={optionsSelectRegiment} styles={colourStyles} />
                </div>
                <div className="items-input-step__blockInputs">
                  <div className="item-input-step">
                    Індекс:
                    <input onBlur={updateIndexSecond} onChange={(e) => (e.target.value.length < 6 ? setIndexInputSecond(e.target.value) : null)} value={indexInputSecond} type="number" placeholder="08300" />
                  </div>
                  <div className="item-input-step">
                    Дім:
                    <input onBlur={updateHouseSecond} onChange={(e) => setHouseInputSecond(e.target.value)} value={houseInputSecond} type="text" placeholder="12" />
                  </div>
                </div>
              </>
            ) : null}
        </div>
        <div className="buttons-step">
          {/*<Button className={checkRule ? 'active' : ''} onClick={() => setCheckRule(true)}><CheckDark />Я підтверджую правильність даних</Button>*/}
          {/*<Button onClick={nextStep}>{isLoading ? <SpinnerNew /> : 'Відправити'}</Button>*/}
          <Button className="black" onClick={nextStep}>Наступний крок</Button>
        </div>
        <div className="footer-step">
          <Button onClick={() => setStep(null)} className="clear">Відмінити</Button>
        </div>
      </div>
    </>
  );
}

export default ItemStep3;
