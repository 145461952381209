import React, { useState } from 'react';
import './style.scss';
import { Upload } from 'antd';
import { ReactComponent as Cloud } from 'assets/icon-cloud.svg';
import { ReactComponent as Eye } from 'assets/icon-eye.svg';
import { ReactComponent as Check } from 'assets/checkmarker.svg';
import { ReactComponent as Trash } from 'assets/icon-trash.svg';
import { toast } from 'react-toastify';
import ModalWrapper from '../../../../../ModalWrapper';
import PreviewModal from '../PreviewModal';

const { Dragger } = Upload;

function FileInput({
  file, setFile, previewUrl, setPreviewUrl, text, dataUser = null, setPassportFiles,
}) {
  const [previewOpen, setPreviewOpen] = useState(false);

  const handleFileChange = (info) => {
    if (info.file.size > 50000000) {
      toast.error('Файл більше 50мб');
      return;
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviewUrl(reader.result);
      setFile(info.file.originFileObj);

      if (dataUser) {
        setPassportFiles((prev) => {
          return {
            ...prev,
            [dataUser]: {
              url: reader.result,
              file: info.file,
            },
          };
        });
      }
    };

    reader.readAsDataURL(info.file.originFileObj);
  };

  const handleFileRemove = () => {
    if (dataUser) {
      setPassportFiles((prev) => {
        const updatedState = { ...prev };
        delete updatedState[dataUser];
        return updatedState;
      });
    }
    setPreviewOpen(false);
    setFile(null);
    setPreviewUrl(null);
  };

  const handleCancel = () => setPreviewOpen(false);

  const uploadButton = (
    <div className="fileInput__beforeUpload">
      <Cloud />
      <p>{text}</p>
      <div className="fileInput__beforeUpload__types">
        <div>.jpeg</div>
        <div>.jpg</div>
        <div>.png</div>
      </div>
    </div>
  );

  const finishButton = (
    <div className="fileInput__beforeUpload">
      <div className="fileInput__beforeUpload__corner"><Check /></div>
      <div onClick={() => setPreviewOpen(true)} className="fileInput__beforeUpload__eye">
        <Eye />
      </div>
      <div className="fileInput__beforeUpload__title">{file?.name}</div>
      <p>{text}</p>
      <div onClick={handleFileRemove} className="fileInput__beforeUpload__button">
        <Trash /> Видалити
      </div>
    </div>
  );

  return (
    <div className="fileInput">
      <Dragger
        name="avatar"
        listType="picture-card"
        className={`avatar-uploader custom-dragger ${previewUrl ? 'preview' : ''}`}
        showUploadList={false}
        disabled={previewUrl}
        onChange={handleFileChange}
        accept=".jpg,.png,.jpeg,.hevc"
        customRequest={({ onSuccess }) => (onSuccess ? setTimeout(() => onSuccess('ok'), 0) : '')}
      >
        {previewUrl ? finishButton : uploadButton}
      </Dragger>
      <ModalWrapper className="full" isOpened={previewOpen} onClose={() => handleCancel()}>
        {(onClose) => <PreviewModal file={file} previewUrl={previewUrl} handleFileRemove={handleFileRemove} onClose={onClose} />}
      </ModalWrapper>
    </div>
  );
}

export default FileInput;
