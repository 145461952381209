import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

const initialState = {
  section: '',
};

export const commonModule = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setSection: (state, action) => {
      state.section = action.payload;
    },
  },
  extraReducers: {},
});

export const { setSection } = commonModule.actions;

export default commonModule.reducer;

export const useCommon = () => useSelector((state) => state.common);
