import React from 'react';
import './style.scss';
import { ReactComponent as Plus } from 'assets/plus.svg';
import { ReactComponent as Trash } from 'assets/icon-trash.svg';

function PreviewModal({
  file = { name: 'image.jpg' }, previewUrl, handleFileRemove = null, onClose,
}) {
  return (
    <div className="modalPhotoPreview">
      <div className="modalPhotoPreview__wrapper">
        <div className="modalPhotoPreview__name">
          <span>{file?.name}</span>
          <div className="modalPhotoPreview__name__close" onClick={onClose}><Plus /></div>
        </div>
        <div className="modalPhotoPreview__image">
          <img src={previewUrl} alt="preview" />
        </div>
        {handleFileRemove ? (
          <button type="button" onClick={handleFileRemove} className="modalPhotoPreview__button">
            <Trash /> Видалити
          </button>
        ) : <div />}
      </div>
    </div>
  );
}

export default PreviewModal;
