import React from 'react';
import { ReactComponent as Submit } from 'assets/submit.svg';
import './style.scss';

import { Link } from 'react-router-dom';
import time from '../../../../../assets/time.svg';

function Post(props) {
  const {
    image, title, date, postId, content,
  } = props;

  return (
    <>
      <div key={postId} className="item-result-search">
        <img src={image} className="img-item-result-search" alt="" />
        <div className="body-item-result-search">
          <Link
            to={`/wiki/${postId}`}
            state={{
              postId,
              image,
              title,
              date,
              content,
            }}
            className="title-item-result-search"
          >
            {title}
          </Link>
          <div className="info-item-result-search">
            <div className="date-info-item-result-search">
              <img src={time} alt="" />
              <div>{date}</div>
            </div>
            <div className="point-info-item-result-search"> </div>
            <div className="submit-info-item-result-search">
              <Submit />
              <div>Поділитися</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Post;
