import Button from 'app/components/ui/Button';
import React from 'react';
import './style.scss';

function Rights({ onClose }) {
  return (
    <div className="rights">
      <h3 className="rights__text">rights</h3>
      <div className="how-to-choose__footer">
        <Button className="clear" onClick={onClose}>
          Закрити
        </Button>
      </div>
    </div>
  );
}

export default Rights;
