import React, { useState } from 'react';
import './style.scss';
import { useDispatch } from 'react-redux';
import { setSection } from 'app/store/modules/Common';
import { ReactComponent as Lock } from 'assets/lock.svg';
import { ReactComponent as Arrow } from 'assets/arrow-stick.svg';
import { toast } from 'react-toastify';
import {
  addCar, updateCar, setCar, useProfile,
} from '../../../../store/modules/Profile';
import Button from '../../../ui/Button';
import FileInput from './components/FileInput';
import SuccessModal from '../../../SuccessModal';
import ModalWrapper from '../../../ModalWrapper';
import config from '../../../../../config';

function AddCar({ addNewCar, onClose = false }) {
  const dispatch = useDispatch();
  const { selectedCar } = useProfile();
  const [values, setValues] = useState(selectedCar ?? {});
  const [step, setStep] = useState(1);
  const [fileFirst, setFileFirst] = useState(null);
  const [previewUrlFirst, setPreviewUrlFirst] = useState(selectedCar?.passport?.front ? `${config.apiUrl}/api/v1/files/?path=${selectedCar.passport.front}` : null);
  const [fileSecond, setFileSecond] = useState(null);
  const [previewUrlSecond, setPreviewUrlSecond] = useState(selectedCar?.passport?.back ? `${config.apiUrl}/api/v1/files/?path=${selectedCar.passport.back}` : null);
  const [successResp, setSuccessResp] = useState(false);

  const updateSection = (s) => {
    dispatch(setSection(s));
    dispatch(setCar(null));
    setValues({});
  };

  const handleChange = (e) => {
    if (e.target.name === 'owner') {
      return setValues({
        ...values,
        [e.target.name]: e.target.checked,
      });
    }

    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = () => {
    if (!values?.brand?.length || !values?.model?.length || !values?.number?.length) {
      toast.error('Введіть усі дані');
      return;
    }
    if (!values.owner) {
      toast.error('Ви маєте бути власником автомобіля');
      return;
    }

    if (step === 1) return setStep(2);

    if (step === 2 && (!previewUrlFirst || !previewUrlSecond)) {
      toast.error('Заповніть дані про техпаспорт');
      return;
    }

    const formData = new FormData();
    Object.keys(values).forEach((key) => {
      const value = values[key];
      formData.append(key, value);
    });
    if (fileFirst) {
      formData.append('photo_front', fileFirst);
    }

    if (fileSecond) {
      formData.append('photo_back', fileSecond);
    }

    if (selectedCar) {
      dispatch(updateCar(formData)).then(() => {
        setSuccessResp(true);
      });
    } else {
      if (onClose) {
        dispatch(addCar(formData)).then((resp) => {
          setSuccessResp(true);
          addNewCar(resp.payload.cars);
          onClose();
        });
        return;
      }
      dispatch(addCar(formData)).then(() => {
        setSuccessResp(true);
      });
    }
    updateSection('');
  };

  const handleExit = () => {
    if (onClose) {
      onClose();
    } else {
      updateSection('');
    }
  };

  return (
    <>
      <ModalWrapper isOpened={successResp} onClose={() => setSuccessResp(false)}>
        {() => <SuccessModal onClose={() => setSuccessResp(false)} type="carAdded" />}
      </ModalWrapper>
      <div className={`wrapper-add-car-ulex ${onClose ? 'modal' : ''}`}>
        <div className="headerAddCar">
          <div className="headerAddCar__lines">
            <div className={`headerAddCar__lines__line ${step === 1 ? 'active' : ''}`} />
            <div className={`headerAddCar__lines__line ${step === 2 ? 'active' : ''}`} />
          </div>
          <div className="headerAddCar__step">
            Крок {step}/2
          </div>
        </div>
        <div className="content-add-car-ulex">
          <div className="title-add-car">Додати автомобіль</div>
          <div className="subtitle-add-car">{step === 1 ? 'Загальна інформація' : 'Фото техпаспорту'}</div>
          <div className="description-add-car">
            {step === 1
              ? 'Уведіть загальну інформацію щодо Вашого авто. Після цього ви зможете використовувати введені дані при створенні заяви (зекономивши собі час).'
              : 'Для обробки Вашої позовної заяви суд повинен  отримати повний пакет документів, включаючи фотографії технічного паспорту Вашого авто.'}
          </div>
          <form>
            {step === 1 ? (
              <>
                <div className="container-input-brand-model">
                  <div className="item-input-brand-model">
                    <span>Марка авто:</span>
                    <input
                      name="brand"
                      type="text"
                      placeholder="Tesla"
                      value={values?.brand || ''}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="item-input-brand-model">
                    <span>Модель авто:</span>
                    <input
                      name="model"
                      type="text"
                      placeholder="Model X"
                      value={values?.model || ''}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="container-number-owner">
                  <div className="item-number-add-car">
                    <span>Реєстраційний номер:</span>
                    <input
                      name="number"
                      type="text"
                      placeholder="AA 0000 AA"
                      value={values?.number || ''}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="item-owner-add-car">
                    <label className="switch">
                      <input name="owner" type="checkbox" checked={values?.owner || ''} onChange={handleChange} />
                      <span className="slider round" />
                    </label>
                    <span>Я власник</span>
                  </div>
                </div>
              </>
            ) : (
              <div className="container-input-brand-model">
                <div className="addCarFiles">
                  <span>Фото/скани свідоцтва про реєстрацію ТЗ:</span>
                  <div className="addCarFiles__photo">
                    <FileInput text="Лицева сторона" file={fileFirst} setFile={setFileFirst} previewUrl={previewUrlFirst} setPreviewUrl={setPreviewUrlFirst} />
                    <FileInput text="Тилова сторона" file={fileSecond} setFile={setFileSecond} previewUrl={previewUrlSecond} setPreviewUrl={setPreviewUrlSecond} />
                  </div>
                </div>
              </div>
            )}
            <div className="container-descSecure">
              <Lock /> Ваші дані будуть зберігатися в повній безпеці
            </div>
            <div className="container-btn-add-car">
              {step === 2 ? (
                <div className="btn-add-car" onClick={() => setStep(1)}>
                  <Arrow />
                </div>
              ) : null}
              <Button onClick={handleSubmit} className={`black ${step === 2 && (!previewUrlFirst || !previewUrlSecond) ? 'disabledCar' : ''}`}>
                {step === 1 ? 'Наступний крок' : selectedCar ? 'Зберегти' : 'Додати авто'}
              </Button>
            </div>
          </form>
          <div className="btn-close-add-car" onClick={handleExit}>Закрити</div>
        </div>
      </div>
    </>
  );
}

export default AddCar;
