import { combineReducers, configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import config from '../../config';
import ProfileReducer from './modules/Profile';
import CaseReducer from './modules/Case';
import CommonReducer from './modules/Common';
import query from './query.options';

const rootReducer = combineReducers({
  common: CommonReducer,
  profile: ProfileReducer,
  case: CaseReducer,
  [query.reducerPath]: query.reducer,
});

const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => {
      let middleware = getDefaultMiddleware({
        serializableCheck: false,
      });

      if (config.debug) {
        middleware = middleware.concat(logger);
      }
      return middleware.concat(query.middleware);
    },
  });
};

export default setupStore;
