import React, { useEffect, useState } from 'react';
import './style.scss';
import { Link, useNavigate } from 'react-router-dom';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'react-circular-progressbar/dist/styles.css';
import { useDispatch } from 'react-redux';
import { ReactComponent as Arrow } from 'assets/arrow-stick.svg';
import svgbg from 'assets/not-assigned.svg';
import { useProfile, logout } from 'app/store/modules/Profile';
import { setSection } from 'app/store/modules/Common';
import moment from 'moment/moment';
import config from '../../../../../config';
import { statusCase, tabsCase } from '../../../../views/MyStatements';
import { useCase } from '../../../../store/modules/Case';

function Profile() {
  const dispatch = useDispatch();
  const { profile } = useProfile();
  const navigate = useNavigate();
  const { cases, isLoading } = useCase();
  const [allTabs, setAllTabs] = useState(tabsCase);

  const allCases = [...cases].reverse();

  useEffect(() => {
    if (cases.length) {
      const newTabs = tabsCase.map((item) => {
        return {
          ...item,
          length: cases.filter((el) => el.status.includes(item.filter)).length,
        };
      });
      setAllTabs(newTabs);
    }
  }, [cases]);

  const updateSection = (s) => {
    dispatch(setSection(s));
  };

  const handleOpenCase = (caseId) => {
    dispatch(setSection(''));
    navigate(`my-statements/${caseId}`);
  };

  const [filter, setFilter] = useState('');

  const logoutUser = async () => {
    await dispatch(logout());
    updateSection('');
  };

  return (
    <div className="wrapper-profile-ulex">
      <div className="content-profile-ulex">
        <div className="title-content-profile">Профіль</div>
        <div className="body-profile">
          <div className="container-profile-body first">
            <div className="avatar-profile">
              {profile?.image ? <img src={profile?.image.includes('googleusercontent') ? profile?.image : `${config.apiUrl}/${profile.image}`} alt="" /> : null}
            </div>
            <div className="welcone-user-profile-mobile">
              <div className="welcome-user-profile">Доброго дня!</div>
              <div className="name-user-profile">{profile?.data?.firstName && profile?.data?.lastName ? `${profile?.data?.firstName} ${profile?.data?.lastName}` : 'Iм\'я Прізвище'}</div>
            </div>
          </div>
          <div className="container-profile-body second">

            <div className="container-profile-body-filter-declare">
              <div className="content-profile-body-filter-declare first">
                <div className="title-profile-body-filter-declare">Мої Заяви</div>
                <div className="separator-profile-body-filter-declare" />
                <div className="list-profile-body-filter-declare">
                  {allTabs.map((tab, index) => (
                    <div
                      key={index}
                      className={`item-filter-my-statements-declare ${filter === tab.filter ? 'active' : ''}`}
                      onClick={() => setFilter(tab.filter)}
                    >
                      {tab.label} {tab.length}
                    </div>
                  ))}
                </div>
              </div>
              <Link to="/my-statements" onClick={() => updateSection('')} className="content-profile-body-filter-declare second">
                <div className="title-all-list-declare">Повний список</div> <Arrow />
              </Link>
            </div>
            <div className="container-profile-body-list-declare">
              <div className="profile-body-list-declare">
                <Swiper
                  className="swiper-profile"
                  slidesPerView={2}
                  breakpoints={{
                    920: {
                      slidesPerView: 4,
                    },
                  }}
                >
                  {!isLoading && cases.length ? allCases.filter((el) => el.status.includes(filter)).map((item, index) => (
                    <SwiperSlide className="item-my-declare-profile" key={index}>
                      <div onClick={() => handleOpenCase(item._id)} className="wrapper-img-item-declare-profile">
                        <div className="progress-status-item-my-statements">
                          {item.answers.length >= 10 || item.status !== 'created' ? null : (
                            <div className="progress-item-my-statements">
                              {item.answers.length ? (
                                <div style={{ width: 20, height: 20 }}>
                                  <CircularProgressbar
                                    value={item.answers.length / 11}
                                    maxValue={1}
                                    strokeWidth={20}
                                    styles={buildStyles({
                                      pathColor: '#FC6B58',
                                      trailColor: 'transparent',
                                      strokeLinecap: 'butt',
                                    })}
                                  />
                                </div>
                              ) : null}
                              <span>{item.answers.length * 11}%</span>
                            </div>
                          )}
                          <div className="status-item-my-statements">
                            {statusCase[item.status] || ''}
                          </div>
                        </div>
                        <img src={svgbg} alt="" />
                      </div>
                      <div className="title-item-declare-profile">Порушення швидкісного режиму.</div>
                      <div className="date-item-declare-profile">{moment(item.createdAt).format('DD.MM.YYYY [о] HH:mm')}</div>
                    </SwiperSlide>
                  )) : null}
                </Swiper>
              </div>
              <div className="profile-body-list-declare-note">
                У випадку, якщо Ви перервали процес створення заявки (наприклад вийшли з процесу створення до кінця не заповнивши), то така заява набуває статусу “Чернетка”. Ви в будь-який момент можете її завершити та відправити на розгляд.
              </div>
            </div>
          </div>
        </div>
        <div className="footer-profile">
          <div className="container-profile-footer first">
            <div className="user-name-profile">{profile?.data?.firstName && profile?.data?.lastName ? `${profile?.data?.firstName} ${profile?.data?.lastName}` : 'Iм\'я Прізвище'}</div>
            {/*<div className="container-language-network-profile">Українська</div>*/}
          </div>
          <div className="container-profile-footer second">
            <Link to="/my-cars" className="item-btn-footer-profile" onClick={() => updateSection('')}>Мої автомобілі</Link>
            <div className="item-btn-footer-profile" onClick={() => updateSection('settingsProfile')}>Налаштування профілю</div>
            <Link to="/" className="item-btn-footer-profile" onClick={logoutUser}>Вийти з кабінету</Link>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Profile;
