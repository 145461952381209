import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import arrow from 'assets/arrow.svg';
import { ReactComponent as Arrow } from 'assets/arrow-stick.svg';
import { ReactComponent as Save } from 'assets/save.svg';
import { ReactComponent as ArrowNext } from 'assets/arrowNextStep.svg';

import './style.scss';
import { useDispatch } from 'react-redux';
import {
  // eslint-disable-next-line no-unused-vars
  getCase, getQuestion, updateCase, useCase,
} from 'app/store/modules/Case';
import ItemTypeOption from './typeStep/ItemTypeOption';
import ItemTypeQuestionnaire from './typeStep/ItemTypeQuestionnaire';
import ItemTypeAuth from './typeStep/ItemTypeAuth';
import ItemTypeDecree from './typeStep/ItemTypeDecree';
import ItemTypeDelivery from './typeStep/ItemTypeDelivery';
import ItemTypeVehicle from './typeStep/ItemTypeVehicle';
import ItemTypePayment from './typeStep/ItemTypePayment';
import ItemTypeSigning from './typeStep/ItemTypeSigning';
import { SpinnerNew } from '../../components/Loading';
import FinishStep from './typeStep/finishStep';
import ModalWrapper from '../../components/ModalWrapper';
import SuccessModal from '../../components/SuccessModal';
import ItemTypeHearing from './typeStep/ItemTypeHearing';
import ItemTypeCircumstances from './typeStep/ItemTypeCircumstances';
import ItemTypePartArticle from './typeStep/ItemTypePartArticle';
import ItemTypeFineAmount from './typeStep/ItemTypeFineAmount';
import ItemTypeMeansFixation from './typeStep/ItemTypeMeansFixation';
import ItemTypeInquirer from './typeStep/ItemTypeInquirer';

function CreatingStatement() {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const { isLoading, questions, currentCase } = useCase();
  const [openModal, setOpenModal] = useState(false);
  const idCase = params.id;

  useEffect(() => {
    dispatch(getQuestion());
    dispatch(getCase(idCase));
  }, []);

  useEffect(() => {
    if (currentCase?.answers) {
      dispatch(updateCase(currentCase));
    }
  }, [currentCase?.answers]);

  useEffect(() => {
    if (currentCase && !currentCase.author) {
      navigate('/');
    }

    if (currentCase?.status === 'submitted') {
      setOpenModal(true);
    }
  }, [currentCase]);

  const whatTitle = () => {
    switch (currentCase?.status) {
      case 'created':
        return 'Створення заяви';
      case 'returned':
        return 'Виправлення заяви';
      case 'submitted':
        return 'Перегляд заяви';
      default:
        return 'Загрузка';
    }
  };

  return (
    <>
      <ModalWrapper isOpened={openModal} onClose={false}>
        {() => <SuccessModal onClose={false} type="statement" />}
      </ModalWrapper>
      <div className="wrapper-creating-statement-ulex" style={{ backgroundColor: '#D8EEED' }}>
        <div className="container-content-creating-statement-ulex">
          <div className="content-creating-statement-ulex">
            <div className="location-site-creating-statement">
              <Link to="/">Головна</Link> <img src={arrow} alt="" /> {currentCase && whatTitle()}
            </div>
            <div className="container-head-creating-statement">
              <div className="item-creating-statement-head first">
                <div className="item-backmain-title-creating-statement">
                  <Link to="/" className="btn-backmain-item-backmain-title-creating-statement"><Arrow /> Назад</Link>
                  <div className="separator-item-backmain-title-creating-statement" />
                  <div className="title-item-backmain-title-creating-statement">{currentCase && whatTitle()}</div>
                </div>
                <div className="item-progress-step-creating-statement">Прогрес заповнення: {currentCase?.answers?.length} / {questions?.length}</div>
              </div>
              <div className="item-creating-statement-head second">
                <div className="item-container-save-statement">
                  <Save /><span>Зберегти в чернетку</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {isLoading
          ? <div className="container-content-creating-statement-ulex loading"><SpinnerNew /></div>
          : (
            <div className="container-content-creating-statement-ulex step">
              <div className="content-creating-statement-ulex step">
                {currentCase?.messageReturned ? (
                  <div className="container-returned-creating-statement">
                    <span>Вказівки що до виправлення від адміністратора</span>
                    <div className="item-step-creating-statement">
                      {currentCase?.messageReturned}
                    </div>
                  </div>
                ) : null}
                {(questions && currentCase) && questions.map((item, index) => {
                  if (item.type === 'options') {
                    return (
                      <div className="container-item-creating-statement" key={index}>
                        <div
                          className={index === currentCase?.answers?.length ? 'point-step-creating-statement point-disable' : 'point-step-creating-statement'}
                        >
                          {questions.length === index + 1 ? null : <div />}
                          <span>{index + 1}</span>
                        </div>
                        <div className="item-step-creating-statement">
                          <ItemTypeOption item={item} index={index} />
                          {questions.length === index + 1 ? null : (
                            <ArrowNext
                              className={index > currentCase?.answers?.length ? 'arrow-next-creating-statement arrow-disable' : 'arrow-next-creating-statement'}
                            />
                          )}
                        </div>
                      </div>
                    );
                  }

                  if (item.type === 'decree') {
                    return (
                      <div className="container-item-creating-statement" key={index}>
                        <div
                          className={index > currentCase?.answers?.length ? 'point-step-creating-statement point-disable' : 'point-step-creating-statement'}
                        >
                          {questions.length === index + 1 ? null : <div />}
                          <span>{index + 1}</span>
                        </div>
                        <div className="item-step-creating-statement">
                          <ItemTypeDecree item={item} index={index} />
                          {questions.length === index + 1 ? null : (
                            <ArrowNext
                              className={index > currentCase?.answers?.length ? 'arrow-next-creating-statement arrow-disable' : 'arrow-next-creating-statement'}
                            />
                          )}
                        </div>
                      </div>
                    );
                  }
                  if (item.type === 'delivery') {
                    return (
                      <div className="container-item-creating-statement" key={index}>
                        <div
                          className={index > currentCase?.answers?.length ? 'point-step-creating-statement point-disable' : 'point-step-creating-statement'}
                        >
                          {questions.length === index + 1 ? null : <div />}
                          <span>{index + 1}</span>
                        </div>
                        <div className="item-step-creating-statement">
                          <ItemTypeDelivery item={item} index={index} />
                          {questions.length === index + 1 ? null : (
                            <ArrowNext
                              className={index > currentCase?.answers?.length ? 'arrow-next-creating-statement arrow-disable' : 'arrow-next-creating-statement'}
                            />
                          )}
                        </div>
                      </div>
                    );
                  }
                  if (item.type === 'hearing') {
                    return (
                      <div className="container-item-creating-statement" key={index}>
                        <div
                          className={index > currentCase?.answers?.length ? 'point-step-creating-statement point-disable' : 'point-step-creating-statement'}
                        >
                          {questions.length === index + 1 ? null : <div />}
                          <span>{index + 1}</span>
                        </div>
                        <div className="item-step-creating-statement">
                          <ItemTypeHearing item={item} index={index} />
                          {questions.length === index + 1 ? null : (
                            <ArrowNext
                              className={index > currentCase?.answers?.length ? 'arrow-next-creating-statement arrow-disable' : 'arrow-next-creating-statement'}
                            />
                          )}
                        </div>
                      </div>
                    );
                  }
                  if (item.type === 'vehicle') {
                    return (
                      <div className="container-item-creating-statement" key={index}>
                        <div
                          className={index > currentCase?.answers?.length ? 'point-step-creating-statement point-disable' : 'point-step-creating-statement'}
                        >
                          {questions.length === index + 1 ? null : <div />}
                          <span>{index + 1}</span>
                        </div>
                        <div className="item-step-creating-statement">
                          <ItemTypeVehicle item={item} index={index} />
                          {questions.length === index + 1 ? null : (
                            <ArrowNext
                              className={index > currentCase?.answers?.length ? 'arrow-next-creating-statement arrow-disable' : 'arrow-next-creating-statement'}
                            />
                          )}
                        </div>
                      </div>
                    );
                  }
                  if (item.type === 'circumstances') {
                    return (
                      <div className="container-item-creating-statement" key={index}>
                        <div
                          className={index > currentCase?.answers?.length ? 'point-step-creating-statement point-disable' : 'point-step-creating-statement'}
                        >
                          {questions.length === index + 1 ? null : <div />}
                          <span>{index + 1}</span>
                        </div>
                        <div className="item-step-creating-statement">
                          <ItemTypeCircumstances item={item} index={index} />
                          {questions.length === index + 1 ? null : (
                            <ArrowNext
                              className={index > currentCase?.answers?.length ? 'arrow-next-creating-statement arrow-disable' : 'arrow-next-creating-statement'}
                            />
                          )}
                        </div>
                      </div>
                    );
                  }
                  if (item.type === 'partArticle') {
                    return (
                      <div className="container-item-creating-statement" key={index}>
                        <div
                          className={index > currentCase?.answers?.length ? 'point-step-creating-statement point-disable' : 'point-step-creating-statement'}
                        >
                          {questions.length === index + 1 ? null : <div />}
                          <span>{index + 1}</span>
                        </div>
                        <div className="item-step-creating-statement">
                          <ItemTypePartArticle item={item} index={index} />
                          {questions.length === index + 1 ? null : (
                            <ArrowNext
                              className={index > currentCase?.answers?.length ? 'arrow-next-creating-statement arrow-disable' : 'arrow-next-creating-statement'}
                            />
                          )}
                        </div>
                      </div>
                    );
                  }
                  if (item.type === 'fineAmount') {
                    return (
                      <div className="container-item-creating-statement" key={index}>
                        <div
                          className={index > currentCase?.answers?.length ? 'point-step-creating-statement point-disable' : 'point-step-creating-statement'}
                        >
                          {questions.length === index + 1 ? null : <div />}
                          <span>{index + 1}</span>
                        </div>
                        <div className="item-step-creating-statement">
                          <ItemTypeFineAmount item={item} index={index} />
                          {questions.length === index + 1 ? null : (
                            <ArrowNext
                              className={index > currentCase?.answers?.length ? 'arrow-next-creating-statement arrow-disable' : 'arrow-next-creating-statement'}
                            />
                          )}
                        </div>
                      </div>
                    );
                  }
                  if (item.type === 'meansFixation') {
                    return (
                      <div className="container-item-creating-statement" key={index}>
                        <div
                          className={index > currentCase?.answers?.length ? 'point-step-creating-statement point-disable' : 'point-step-creating-statement'}
                        >
                          {questions.length === index + 1 ? null : <div />}
                          <span>{index + 1}</span>
                        </div>
                        <div className="item-step-creating-statement">
                          <ItemTypeMeansFixation item={item} index={index} />
                          {questions.length === index + 1 ? null : (
                            <ArrowNext
                              className={index > currentCase?.answers?.length ? 'arrow-next-creating-statement arrow-disable' : 'arrow-next-creating-statement'}
                            />
                          )}
                        </div>
                      </div>
                    );
                  }
                  if (item.type === 'payment') {
                    return (
                      <div className="container-item-creating-statement" key={index}>
                        <div
                          className={index > currentCase?.answers?.length ? 'point-step-creating-statement point-disable' : 'point-step-creating-statement'}
                        >
                          {questions.length === index + 1 ? null : <div />}
                          <span>{index + 1}</span>
                        </div>
                        <div className="item-step-creating-statement">
                          <ItemTypePayment item={item} index={index} />
                          {questions.length === index + 1 ? null : (
                            <ArrowNext
                              className={index > currentCase?.answers?.length ? 'arrow-next-creating-statement arrow-disable' : 'arrow-next-creating-statement'}
                            />
                          )}
                        </div>
                      </div>
                    );
                  }
                  if (item.type === 'questionnaire') {
                    return (
                      <div className="container-item-creating-statement" key={index}>
                        <div
                          className={index > currentCase?.answers?.length ? 'point-step-creating-statement point-disable' : 'point-step-creating-statement'}
                        >
                          {questions.length === index + 1 ? null : <div />}
                          <span>{index + 1}</span>
                        </div>
                        <div className="item-step-creating-statement">
                          <ItemTypeQuestionnaire item={item} index={index} />
                          {questions.length === index + 1 ? null : (
                            <ArrowNext
                              className={index > currentCase?.answers?.length ? 'arrow-next-creating-statement arrow-disable' : 'arrow-next-creating-statement'}
                            />
                          )}
                        </div>
                      </div>
                    );
                  }
                  if (item.type === 'inquirer') {
                    return (
                      <div className="container-item-creating-statement" key={index}>
                        <div
                          className={index > currentCase?.answers?.length ? 'point-step-creating-statement point-disable' : 'point-step-creating-statement'}
                        >
                          {questions.length === index + 1 ? null : <div />}
                          <span>{index + 1}</span>
                        </div>
                        <div className="item-step-creating-statement">
                          <ItemTypeInquirer item={item} index={index} />
                          {questions.length === index + 1 ? null : (
                            <ArrowNext
                              className={index > currentCase?.answers?.length ? 'arrow-next-creating-statement arrow-disable' : 'arrow-next-creating-statement'}
                            />
                          )}
                        </div>
                      </div>
                    );
                  }
                  if (item.type === 'auth') {
                    return (
                      <div className="container-item-creating-statement" key={index}>
                        <div
                          className={index > currentCase?.answers?.length ? 'point-step-creating-statement point-disable' : 'point-step-creating-statement'}
                        >
                          {questions.length === index + 1 ? null : <div />}
                          <span>{index + 1}</span>
                        </div>
                        <div className="item-step-creating-statement">
                          <ItemTypeAuth item={item} index={index} />
                          {questions.length === index + 1 ? null : (
                            <ArrowNext
                              className={index > currentCase?.answers?.length ? 'arrow-next-creating-statement arrow-disable' : 'arrow-next-creating-statement'}
                            />
                          )}
                        </div>
                      </div>
                    );
                  }
                  if (item.type === 'signing') {
                    return (
                      <div className="container-item-creating-statement" key={index}>
                        <div
                          className={index > currentCase?.answers?.length ? 'point-step-creating-statement point-disable' : 'point-step-creating-statement'}
                        >
                          {questions.length === index + 1 ? null : <div />}
                          <span>{index + 1}</span>
                        </div>
                        <div className="item-step-creating-statement">
                          <ItemTypeSigning item={item} index={index} />
                          {questions.length === index + 1 ? null : (
                            <ArrowNext
                              className={index > currentCase?.answers?.length ? 'arrow-next-creating-statement arrow-disable' : 'arrow-next-creating-statement'}
                            />
                          )}
                        </div>
                      </div>
                    );
                  }

                  return null;
                })}
                {!isLoading && currentCase?.answers?.length === questions?.length ? <FinishStep setOpenModal={setOpenModal} /> : null}
              </div>
            </div>
          )}
      </div>
    </>
  );
}

export default CreatingStatement;
