import React, { useState } from 'react';
import './style.scss';
import { formatDistance } from 'date-fns';
import { uk } from 'date-fns/locale';

import Button from 'app/components/ui/Button';
import { useGetAllPostsQuery } from 'app/services/post.service';
import Post from './components/Post';

import ulexWiki from '../../../assets/wiki/ulex-wiki.svg';
import arrowUp from '../../../assets/wiki/Arrow.svg';

function Wiki() {
  const [showMore, setShowMore] = useState(false);

  const { data, isLoading } = useGetAllPostsQuery();

  const postsToDisplay = showMore ? data : data?.slice(0, 6);

  const handleBackToTopClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className="wiki-page">
      {isLoading ? (
        <div className="wiki-page__loader" />
      ) : (
        <div>
          <div className="wiki-page__wrapper">
            <div className="wiki-page__head-text">
              <img className="wiki-page__head-text--logo" src={ulexWiki} alt="Ulex Wiki" />
              <h3 className="wiki-page__head-text--secondary-text">
                Загальні інструкції по використанню сервісу Ulex.
              </h3>
            </div>
            <div className="wiki-page__post--wrapper">
              {postsToDisplay?.map((post, index) => {
                return (
                  <Post
                    key={post._id}
                    image={post.thumbnail}
                    index={index}
                    title={post.title}
                    date={formatDistance(new Date(post.createdAt), new Date(), {
                      addSuffix: true,
                      locale: uk,
                    })}
                    postId={post._id}
                    content={post.content}
                  />
                );
              })}
            </div>
            <>
              {!showMore ? (
                <div className="wiki-page__button--wrapper">
                  <Button
                    className="wiki-page__button--show-more"
                    onClick={() => setShowMore(!showMore)}
                  >
                    Завантажити ще
                  </Button>
                </div>
              ) : (
                <>
                  <div className="wiki-page__button--back-top--wrapper">
                    <Button
                      className="wiki-page__button--back-top"
                      onClick={() => handleBackToTopClick()}
                    >
                      Нагору
                    </Button>
                    <img src={arrowUp} alt="arrow up" />
                  </div>
                </>
              )}
            </>
          </div>
        </div>
      )}
    </div>
  );
}

export default Wiki;
