import React, { useState } from 'react';
import './style.scss';
import { useDispatch } from 'react-redux';
// import { ReactComponent as Edit } from '../../../../../assets/edit.svg';
import { setAnswers, useCase } from '../../../../store/modules/Case';

function ItemTypeSigning({ item, index }) {
  const dispatch = useDispatch();
  const { currentCase } = useCase();
  const [update, setUpdate] = useState(false);
  const answer = currentCase?.answers?.find((el) => el.question === item._id);

  const handleSelect = (el) => () => {
    setUpdate(false);
    if (answer) {
      dispatch(setAnswers({ question: item._id, value: el }));
    } else {
      dispatch(setAnswers({ question: item._id, value: el, new: true }));
    }
  };
  return (
    <>
      {!answer || update
        ? (
          <>{index > currentCase?.answers?.length
            ? (
              <div className="content-item-step-inactive">
                <div className="title-item-step-inactive"><div>{item.title}</div></div>
                <div className="description-item-step-inactive">Не пройдено</div>
              </div>
            )
            : (
              <div className="content-item-type-signing">
                <div className="bang-item-type-signing" />
                <div className="title-item-type-signing">{item.title}</div>
                <div className="description-item-type-signing">{item.description}</div>
                <div className="btn-item-type-signing" onClick={handleSelect('Пройдено')}>{item.button}</div>
              </div>
            )}
          </>
        )
        : (
          <>
            <div className="content-item-step-inactive">
              <div className="title-item-step-inactive"><div>{item.title}</div></div>
              <div className="description-item-step-inactive">Пройдено</div>
            </div>
          </>
        )}
    </>
  );
}
export default ItemTypeSigning;
