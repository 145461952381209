import React, { useMemo } from 'react';
import './style.scss';
import { ReactComponent as List } from 'assets/list.svg';
import { useDispatch } from 'react-redux';
import Button from '../ui/Button';
import { setSection } from '../../store/modules/Common';

function SuccessModal({
  type, onClose, deleteCar = null, handleDeleteCar,
}) {
  const dispatch = useDispatch();

  const updateSection = (s) => {
    dispatch(setSection(s));
    onClose();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const content = useMemo(() => {
    switch (type) {
      case 'document':
        return (
          <>
            <div className="successModal__title">Документ <span>підписано</span>!</div>
            <div className="successModal__desc">Документ тепер має цифровий підпис від Дії.</div>
          </>
        );
      case 'payment':
        return (
          <>
            <div className="successModal__title">Оплата прошла <span>успішно</span>!</div>
          </>
        );
      case 'carAdded':
        return (
          <>
            <div className="successModal__title">Авто було успішно <span>додане</span>!</div>
          </>
        );
      case 'carDelete':
        return (
          <>
            <div className="successModal__title">Ви впевнені, що хочете видалити з нашої бази данних машину з номером <span>{deleteCar?.number}</span>?</div>
          </>
        );
      case 'data':
        return (
          <>
            <div className="successModal__title">Дані успішно <span>отримані</span>!</div>
          </>
        );
      case 'advice':
        return (
          <>
            <div className="successModal__title">Що вибрати?</div>
            <div className="successModal__desc">
              Перевірте текст постанови інспектора з паркування або поліції про притягнення Вас до адміністративної відповідальності. <br /> <br />

              Після опису порушення зазначено має бути зазначено статтю Правил дорожнього руху, яку порушено. <br /> <br />

              Цей пункт у постанові може називатися так:<br /><br />
              <ul>
                <li>«Опис порушення»</li>
                <li>«Час та місце скоєння, суть та обставини порушення»</li>
                <li>«Правова кваліфікація порушення».</li>
              </ul>
              <br />

              Цей пункт у постанові може називатися так:<br /><br />

              <ul>
                <li>Якщо зазначено пункт 15.9 ПДР – обирайте варіант «Зупинка»</li>
                <li>Якщо зазначено пункт 15.10 ПДР – обирайте варіант «Стоянка»</li>
                <li>Якщо зазначено інший пункт, або не зазначено взагалі, який пункт Правил дорожнього руху порушено – обирайте варіант «не зазначено».</li>
              </ul>
            </div>
          </>
        );
      case 'statement':
        return (
          <>
            <div className="successModal__title">Заява відправлена!</div>
            <div className="successModal__desc statement">
              Ваша заява успішно відправлена та буде розглянута нами найближчим часом. <br /> <br />
              Ви можете переглянути статус заяви в особистому кабінеті.
            </div>
            <Button linkTo="/my-statements"><List /> Мої заяви</Button>
          </>
        );
      case 'auth':
        return (
          <>
            <div className="successModal__title">Авторизація</div>
            <div className="successModal__desc statement">
              Ми помітили, що Ви не авторизовані. <br /> <br />
              Для подальшого продовження створення заявки - пройдіть авторизацію.
            </div>
            <Button onClick={() => updateSection('authorization')} className="black">Пройти авторизацію</Button>
          </>
        );
      default:
        return (
          <>
            <div className="successModal__title">Дані успішно <span>отримані</span>!</div>
          </>
        );
    }
  }, [type]);

  const button = useMemo(() => {
    switch (type) {
      case 'advice':
        return (
          <>
            <div className="successModal__footer advice">
              <Button className="clear" onClick={onClose}>Закрити</Button>
            </div>
          </>
        );
      case 'statement':
        return (
          <>
            <div className="successModal__footer statement">
              <Button linkTo="/" className="clear" onClick={onClose}>На головну сторінку</Button>
            </div>
          </>
        );
      case 'carDelete':
        return (
          <>
            <div className="successModal__footer carDelete">
              <Button className="clear" onClick={handleDeleteCar}>Видалити</Button>
              <Button className="clear" onClick={onClose}>Залишити</Button>
            </div>
          </>
        );
      case 'auth':
        return (
          <>
            <div className="successModal__footer advice">
              <Button className="clear" onClick={onClose}>Закрити</Button>
            </div>
          </>
        );
      default:
        return (
          <>
            <div className="successModal__footer">
              <Button className="clear" onClick={onClose}>Добре</Button>
            </div>
          </>
        );
    }
  }, [type]);

  return (
    <div className="successModal">
      <div className="successModal__container">
        <div className="successModal__content">
          {content}
          {button}
        </div>
      </div>
    </div>
  );
}

export default SuccessModal;
