import { createApi, retry } from '@reduxjs/toolkit/query/react';
import axios from 'axios';

const baseQuery = async ({
  url, method, data, params, headers,
}) => {
  try {
    const result = await axios({
      url, method, data, params, headers,
    });
    return { data: result?.data };
  } catch (axiosError) {
    return {
      error: {
        status: axiosError.response?.status,
        data: axiosError.response?.data || axiosError.message,
      },
    };
  }
};

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 6 });

export default createApi({
  reducerPath: 'splitApi',
  baseQuery: baseQueryWithRetry,
  tagTypes: ['Posts'],
  endpoints: () => ({}),
});
