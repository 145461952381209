import React, { useEffect, useState } from 'react';
import './style.scss';
import { ReactComponent as Edit } from 'assets/edit.svg';
import { ReactComponent as Save } from 'assets/save.svg';
import { toast } from 'react-toastify';
import InputMask from 'react-input-mask';
import Select from 'react-select';
import { useMoreData } from '../../../../context';
import { optionsSelectPassport } from '../../../itemStep2';

export const colourStylesData = {
  container: (styles) => {
    return {
      ...styles,
    };
  },
  control: (styles) => {
    return {
      ...styles,
      alignItems: 'center',
      marginTop: '-10px',
      marginBottom: '-10px',
      padding: '0',
      cursor: 'pointer',
      boxShadow: 'none',
      color: '#FC6B58',
      border: '1px solid transparent',
      ':hover': {
        ...styles[':hover'],
        border: '1px solid transparent',
      },
    };
  },
  option: (styles) => {
    return {
      ...styles,
      color: 'black',
      cursor: 'pointer',
      backgroundColor: 'transparent !important',
      ':hover': {
        ...styles[':hover'],
        cursor: 'pointer',
        color: '#FC6B58',
        backgroundColor: 'none',
      },
      '.active': {
        ...styles['.active'],
        backgroundColor: 'white',
        color: '#000000',
      },
    };
  },
  valueContainer: (styles) => {
    return {
      ...styles,
      padding: '0px',
    };
  },
  singleValue: (styles) => {
    return {
      ...styles,
      padding: '2px',
      backgroundColor: 'transparent',
      ':hover': {
        ...styles[':hover'],
        color: '#000000',
      },
    };
  },
};

function DataBlock2({ checkEdit, setCheckEdit }) {
  const { answers, setAnswers } = useMoreData();
  const [identCodeInput, setIdentCodeInput] = useState('');
  const [phoneNumberInput, setPhoneNumberInput] = useState('');
  const [birthdayInput, setBirthdayInput] = useState('');
  const [passportIDInput, setPassportIDInput] = useState('');
  const [viewPassport, setViewPassport] = useState(optionsSelectPassport[0]);
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    setIdentCodeInput(answers.identCode);
    setPhoneNumberInput(answers.phoneNumber);
    setBirthdayInput(answers.birthday);
    setPassportIDInput(answers.passportID);
  }, []);

  useEffect(() => {
    const regex = /^[А-Я]{2}\d{6}$/i;
    if (!answers?.passportID?.length) return;
    if (regex.test(answers.passportID)) {
      setViewPassport(optionsSelectPassport[1]);
    }
  }, []);

  const updateViewPassport = (option) => {
    setViewPassport(option);
    setAnswers({ ...answers, passportID: '' });
    setPassportIDInput('');
  };

  const updateIdentCode = () => {
    if (identCodeInput.length) {
      if (identCodeInput.length === 10 || identCodeInput.length === 12) {
        setAnswers({ ...answers, identCode: identCodeInput });
      } else {
        toast.error('Невірний IНН');
      }
    }
  };

  const updatePhoneNumber = () => {
    const checkPhoneInput = phoneNumberInput.replace(/\D/g, '').replace(/^7/, '');

    if (checkPhoneInput.length < 12) {
      toast.error('Невірно набраний номер');
    } else {
      setAnswers({ ...answers, phoneNumber: checkPhoneInput });
    }
  };

  const updateBirthday = () => {
    const checkBirthdayInput = birthdayInput.replace(/\/+$/, '').replaceAll('_', '');

    if (birthdayInput.length) {
      if (checkBirthdayInput.length === 10) {
        setAnswers({ ...answers, birthday: checkBirthdayInput });
      } else {
        toast.error('Невірна дата народження');
      }
    }
  };

  const updatePassport = () => {
    if (passportIDInput.length) {
      if (viewPassport === optionsSelectPassport[0]) {
        if (passportIDInput.length !== 9) {
          toast.error('Заповніть вірно поле паспорта');
          return;
        }
      } else {
        const regex = /^[А-Я]{2}\d{6}$/i;
        if (!regex.test(passportIDInput)) {
          toast.error('Заповніть вірно поле паспорта');
          return;
        }
      }
      setAnswers({ ...answers, passportID: passportIDInput });
    }
  };

  const handleEdit = () => {
    if (checkEdit && checkEdit !== 'block2') return toast.error('Спочатку відредагуйте минулі дані');
    if (!edit) {
      setCheckEdit('block2');
      return setEdit(true);
    }

    const allFieldsFilled = answers.identCode.length && answers.phoneNumber.length === 12 && answers.birthday.length && answers.passportID.length;
    if (!allFieldsFilled) {
      return toast.error('Заповніть всі поля');
    }

    setCheckEdit(false);
    setEdit(false);
  };

  return (
    <>
      <div className="dataFinishBlock">
        <div className="dataFinishBlock__wrapper">
          <div className="dataFinishBlock__header">
            <div className="dataFinishBlock__header__left">
              <div className="dataFinishBlock__header__step">Крок 2</div>
              <div className="dataFinishBlock__header__title">Персональні дані</div>
            </div>
            <div onClick={handleEdit} className="dataFinishBlock__header__edit">{edit ? <Save /> : <Edit />}</div>
          </div>
          <div className="dataFinishBlock__allItems">
            <div className="dataFinishBlock__item">
              <span>Ідентифікаційний код</span>
              <input
                disabled={!edit}
                onBlur={updateIdentCode}
                onChange={(e) => (e.target.value.length <= 12 ? setIdentCodeInput(e.target.value) : null)}
                value={identCodeInput}
                type="number"
                placeholder="2131241212"
              />
            </div>
            <div className="dataFinishBlock__item">
              <span>Номер телефона</span>
              <InputMask
                disabled={!edit}
                mask="+380 (99) 999 9999"
                onBlur={updatePhoneNumber}
                value={phoneNumberInput}
                onChange={(e) => setPhoneNumberInput(e.target.value)}
                placeholder="+380 (00) 000 0000"
              />
            </div>
            <div className="dataFinishBlock__item">
              <span>Дата народження</span>
              <InputMask
                disabled={!edit}
                mask="9999/99/99"
                onBlur={updateBirthday}
                value={birthdayInput}
                onChange={(e) => setBirthdayInput(e.target.value)}
                placeholder="2000/10/20"
              />
            </div>
            <div className="dataFinishBlock__item">
              <Select
                isDisabled={!edit}
                placeholder="Виберіть паспорт"
                options={optionsSelectPassport}
                styles={colourStylesData}
                value={viewPassport}
                onChange={updateViewPassport}
              />
              <input
                disabled={!edit}
                onBlur={updatePassport}
                onChange={(e) => (e.target.value.length <= 9 ? setPassportIDInput(e.target.value.toUpperCase()) : null)}
                value={passportIDInput}
                type={viewPassport === optionsSelectPassport[0] ? 'number' : 'text'}
                placeholder={viewPassport === optionsSelectPassport[0] ? '2131241212' : 'КМ797483'}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default DataBlock2;
