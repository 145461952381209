import React, { useEffect, useState } from 'react';
import './style.scss';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import QRCode from 'qrcode';
import { useTimer } from 'react-timer-hook';
import Button from '../ui/Button';
import { SpinnerNew } from '../Loading';
import { getDeeplinkAuth } from '../../store/modules/Profile/actions';
import useWindowDimensions from '../../hooks/useWindowDimensions';
// import { useMoreData } from '../../views/MoreData/context';

// eslint-disable-next-line no-unused-vars
const testData = {
  firstName: 'TestDiya',
  lastName: 'TestDiya',
  middleName: 'TestDiya',
  city: 'TestCityDiya',
  street: 'TestStreetDiya',
};

// eslint-disable-next-line no-unused-vars
function DiyaQR({ setDataDiya, onClose, reg = false }) {
  // eslint-disable-next-line no-unused-vars
  // const { setAnswers } = useMoreData();
  const { width } = useWindowDimensions();
  const tablet = width < 1100;
  const expiryTimestamp = new Date(new Date().getTime() + 3 * 60000);
  const dispatch = useDispatch();
  const [code, setCode] = useState('');
  const [srcCode, setSrcCode] = useState('');
  const [expireQR, setExpireQR] = useState(false);
  const {
    seconds,
    minutes,
    restart,
  } = useTimer({ expiryTimestamp, onExpire: () => setExpireQR(true) });

  useEffect(() => {
    if (code) {
      QRCode.toDataURL(code.deeplink).then(setSrcCode);

      // eslint-disable-next-line no-unused-vars
      // let timerId = setTimeout(function request() {
      //   dispatch(getDeeplinkAuth()).then((res) => {
      //     if (!res.error) {
      //       setAnswers((prev) => {
      //         return {
      //           ...prev,
      //           ...testData,
      //         };
      //       });
      //       setDataDiya(true);
      //       clearTimeout(timerId);
      //       onClose();
      //     } else {
      //       timerId = setTimeout(request, 2000);
      //       toast.error('Помилка ');
      //     }
      //   });
      // }, 2000);
    }
  }, [code]);

  const createQrCode = () => {
    setSrcCode('');
    setExpireQR(false);
    dispatch(getDeeplinkAuth()).then((res) => {
      if (!res.error) {
        setCode(res.payload);
        restart(new Date(new Date().getTime() + 3 * 60000));
      } else {
        toast.error('Помилка ');
        onClose();
      }
    });
  };

  // const checkResponseData = () => {
  //
  // };

  useEffect(() => {
    createQrCode();
  }, []);

  return (
    <div className="diyaQR">
      <div className="diyaQR__container">
        <div className="diyaQR__content">
          <div className="diyaQR__title">{reg ? 'Регістрація з Дія.Підписом' : 'Увійти з Дія.Підписом'}</div>
          <div className="diyaQR__desc">Зчитайте QR-код сканером у застосунку Дія та дотримуйтесь інструкцій.</div>
          <div className="diyaQR__code">
            {srcCode
              ? !tablet ? (
                <>
                  <div><img src={srcCode} alt="" /></div>
                  QR оновиться через {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                </>
              ) : (
                <>
                  <a href={code.deeplink} target="_blank" rel="noreferrer"><img src={srcCode} alt="qr" /></a>
                  {expireQR
                    ? (
                      <>
                        <Button onClick={() => createQrCode()}>Оновити QR</Button>
                      </>
                    )
                    : <>QR оновиться через {minutes}:{seconds < 10 ? `0${seconds}` : seconds}</>}
                </>
              )
              : <SpinnerNew />}
          </div>
          <div className="diyaQR__footer">
            <Button className="clear" onClick={onClose}>Відмінити</Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DiyaQR;
