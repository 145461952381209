import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  initialization,
  registrationByEmail,
  registrationByPhone,
  loginByPhone,
  verifiedCode,
  loginByEmail,
  updateUser,
  logout,
  getDeeplinkAuth,
  addCar,
  updateCar,
  deleteCar,
  removeGoogle,
  setPassword,
} from './actions';

const initialState = {
  profile: null,
  qrCodeData: null,
  selectedCar: null,

  isLoading: true,
  error: '',
};

export const profileModule = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setCar: (state, action) => {
      state.selectedCar = action.payload;
    },
  },
  extraReducers: {
    // init
    [initialization.fulfilled.type]: (state, action) => {
      state.profile = action.payload;
      state.isLoading = false;
    },
    [initialization.pending.type]: (state) => {
      state.isLoading = true;
    },
    [initialization.rejected.type]: (state) => {
      state.isLoading = false;
    },

    // updateUser
    [updateUser.fulfilled.type]: (state, action) => {
      state.profile = action.payload;
      state.isLoading = false;
    },
    [updateUser.pending.type]: (state) => {
      state.isLoading = true;
    },
    [updateUser.rejected.type]: (state) => {
      state.isLoading = false;
    },

    // remove google
    [removeGoogle.fulfilled.type]: (state, action) => {
      if (action.payload.user) {
        state.profile = action.payload.user;
      }
      state.isLoading = false;
    },

    // registrationByEmail
    [registrationByEmail.fulfilled.type]: (state) => {
      state.isLoading = false;
      document.location.reload();
    },
    [registrationByEmail.pending.type]: (state) => {
      state.isLoading = true;
    },
    [registrationByEmail.rejected.type]: (state, action) => {
      state.isLoading = false;
      toast.error(action.payload);
    },

    // registrationByPhone
    [registrationByPhone.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      // eslint-disable-next-line no-alert
      alert(action.payload);
    },
    [registrationByPhone.pending.type]: (state) => {
      state.isLoading = true;
    },
    [registrationByPhone.rejected.type]: (state, action) => {
      state.isLoading = false;
      toast.error(action.payload);
    },

    // loginByPhone
    [loginByPhone.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      // eslint-disable-next-line no-alert
      alert(action.payload);
    },
    [loginByPhone.pending.type]: (state) => {
      state.isLoading = true;
    },
    [loginByPhone.rejected.type]: (state, action) => {
      state.isLoading = false;
      toast.error(action.payload);
    },

    // verifiedCode
    [verifiedCode.fulfilled.type]: (state) => {
      state.isLoading = false;
      document.location.reload();
    },
    [verifiedCode.pending.type]: (state) => {
      state.isLoading = true;
    },
    [verifiedCode.rejected.type]: (state) => {
      state.isLoading = false;
      //toast.error(action.payload)
    },

    // loginByEmail
    [loginByEmail.fulfilled.type]: (state) => {
      state.isLoading = false;
      document.location.reload();
    },
    [loginByEmail.pending.type]: (state) => {
      state.isLoading = true;
    },
    [loginByEmail.rejected.type]: (state, action) => {
      state.isLoading = false;
      toast.error(action.payload);
    },

    // logout
    [logout.fulfilled.type]: (state) => {
      state.profile = null;
      state.isLoading = false;
    },
    [logout.pending.type]: (state) => {
      state.isLoading = true;
    },
    [logout.rejected.type]: (state, action) => {
      state.isLoading = false;
      toast.error(action.payload);
    },

    // getDeeplinkAuth
    [getDeeplinkAuth.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.qrCodeData = action.payload;
      toast.error(action.payload);
    },

    // Car
    [addCar.fulfilled.type]: (state, action) => {
      state.profile = action.payload;
    },
    [updateCar.fulfilled.type]: (state, action) => {
      state.profile = action.payload;
    },
    [deleteCar.fulfilled.type]: (state, action) => {
      state.profile = action.payload;
    },
  },
});

const { setCar } = profileModule.actions;

export {
  initialization,
  registrationByEmail,
  registrationByPhone,
  updateUser,
  loginByPhone,
  verifiedCode,
  loginByEmail,
  logout,
  setCar,
  addCar,
  updateCar,
  deleteCar,
  removeGoogle,
  setPassword,
};

export default profileModule.reducer;

export const useProfile = () => useSelector((state) => state.profile);
