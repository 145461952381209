import React, { useEffect, useState } from 'react';
// import Select from 'react-select';
import './style.scss';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { ReactComponent as Edit } from '../../../../../assets/edit.svg';
import { setAnswers, useCase } from '../../../../store/modules/Case';

export const optionsSelectRegiment = [
  { value: '0', label: 'Одеська область' },
  { value: '1', label: 'Київська область' },
  { value: '2', label: 'Дніпропетровська область' },
  { value: '3', label: 'Чернігівська область' },
  { value: '4', label: 'Харківська область' },
  { value: '5', label: 'Житомирська область' },
  { value: '6', label: 'Полтавська область' },
  { value: '7', label: 'Херсонська область' },
  { value: '8', label: 'Запорізька область' },
  { value: '9', label: 'Луганська область' },
  { value: '10', label: 'Донецька область' },
  { value: '11', label: 'Вінницька область' },
  { value: '12', label: 'Кiровоградська область' },
  { value: '13', label: 'Миколаївська область' },
  { value: '14', label: 'Сумська область' },
  { value: '15', label: 'Львівська область' },
  { value: '16', label: 'Черкаська область' },
  { value: '17', label: 'Хмельницька область' },
  { value: '18', label: 'Волинська область' },
  { value: '19', label: 'Рівненська область' },
  { value: '20', label: 'Івано-Франківська область' },
  { value: '21', label: 'Тернопільська область' },
  { value: '22', label: 'Закарпатська область' },
  { value: '23', label: 'Чернівецька область' },
];

function ItemTypeDelivery({ item, index }) {
  const dispatch = useDispatch();
  const { currentCase } = useCase();
  const [update, setUpdate] = useState(false);
  const [policeName, setPoliceName] = useState('');
  const answer = currentCase?.answers?.find((el) => el.question === item._id);

  const finishDelivery = {
    policeName,
  };

  useEffect(() => {
    if (answer) {
      setPoliceName(answer.value.policeName);
    }
  }, [answer]);

  const handleSelect = () => () => {
    if (!policeName) {
      toast.error('Заповніть дані');
      return;
    }
    setUpdate(false);
    if (answer) {
      dispatch(setAnswers({ question: item._id, value: finishDelivery }));
    } else {
      dispatch(setAnswers({ question: item._id, value: finishDelivery, new: true }));
    }
  };

  return (

    <>
      {!answer || update
        ? (
          <>{index > currentCase?.answers?.length
            ? (
              <div className="content-item-step-inactive">
                <div className="title-item-step-inactive"><div>{item.title}</div></div>
                <div className="description-item-step-inactive">Не пройдено</div>
              </div>
            )
            : (
              <div className="content-item-type-delivery">
                <div className="bang-item-type-delivery" />
                <div className="title-item-type-delivery">{item.title}</div>
                <div className="description-item-type-delivery">{item.description}</div>
                <div className="entry-field-item-type-delivery">
                  <div className="title-entry-field-item-type-delivery">ПІБ поліцейського:</div>
                  <input value={policeName} onChange={(e) => setPoliceName(e.target.value)} type="text" placeholder="ПІБ" />
                </div>
                <div className="btn-item-type-delivery" onClick={handleSelect('Пройдено')}>{item.button}</div>
              </div>
            )}
          </>
        )
        : (
          <>
            <div className="content-item-step-inactive">
              <div className="title-item-step-inactive"><div>{item.title}</div>{answer ? <span onClick={() => setUpdate(true)}><Edit /></span> : null}</div>
              <div className="description-item-step-inactive">Пройдено</div>
            </div>
          </>
        )}
    </>

  );
}
export default ItemTypeDelivery;
