import React, { useEffect, useState } from 'react';
import './style.scss';
import imgWiki from 'assets/wiki/1.jpg';
import Post from '../../../../views/Wiki/components/Post';

const state = [
  {
    title: 'Як створити заяву?',
    image: imgWiki,
    date: '1 хв.',
  },
  {
    title: 'Як створити аккаунт?',
    image: imgWiki,
    date: '5 хв.',
  },
  {
    title: 'Як підписати заяву через “Дія”?',
    image: imgWiki,
    date: '10 хв.',
  },
  {
    title: 'Проблеми з оплатою?',
    image: imgWiki,
    date: '3 днi',
  },
  {
    title: 'тестовый',
    image: imgWiki,
    date: '3 год.',
  },
  {
    title: 'тестовый3',
    image: imgWiki,
    date: '3 год.',
  },
  {
    title: 'тестовый2',
    image: imgWiki,
    date: '3 год.',
  },
  {
    title: 'тестовый1',
    image: imgWiki,
    date: '3 год.',
  },
  {
    title: 'тестовый1',
    image: imgWiki,
    date: '3 год.',
  },
  {
    title: 'тестовый1',
    image: imgWiki,
    date: '3 год.',
  },
];

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return windowSize;
};

function Search() {
  const size = useWindowSize();
  const [searchInput, setSearchInput] = useState('');
  const stateFilter = state.filter(
    (item) => !searchInput || item.title.toLowerCase().includes(searchInput.toLowerCase()),
  );

  return (
    <div className="wrapper-search-ulex">
      <div className="content-search-ulex">
        <input
          type="text"
          onChange={(e) => {
            setSearchInput(e.target.value);
          }}
          value={searchInput}
          placeholder={
            size.width > 918
              ? 'Введіть ключове слово для пошуку в Ulex Wiki...'
              : 'Пошук в Ulex Wiki...'
          }
          className="item-input-search"
        />

        {!searchInput && (
          <div className="item-example-search">
            Наприклад:{' '}
            <span
              onClick={() => {
                setSearchInput('Як створити заяву');
              }}
            >
              Як створити заяву
            </span>{' '}
            або{' '}
            <span
              onClick={() => {
                setSearchInput('де дізнатися про деталі постанови');
              }}
            >
              де дізнатися про деталі постанови
            </span>
          </div>
        )}

        {searchInput && stateFilter.length < 1 && (
          <div className="item-example-search">
            Наприклад:{' '}
            <span
              onClick={() => {
                setSearchInput('Як створити заяву');
              }}
            >
              Як створити заяву
            </span>{' '}
            або{' '}
            <span
              onClick={() => {
                setSearchInput('де дізнатися про деталі постанови');
              }}
            >
              де дізнатися про деталі постанови
            </span>
          </div>
        )}

        {searchInput && stateFilter.length >= 1 && (
          <div className="item-qty-resalt-search">
            {stateFilter.length} результатів найдено{' '}
            {stateFilter.length > 4 ? '( 4 показано);' : `( ${stateFilter.length} показано);`}
          </div>
        )}

        <div className="container-result-search">
          {!searchInput ? (
            <div className="item-result-search-start" />
          ) : stateFilter.length ? (
            stateFilter.map((item, index) => {
              if (index >= 4) return null;
              return <Post image={item.image} index={index} title={item.title} date={item.date} />;
            })
          ) : (
            <div className="item-result-search-null">Пошук не дав результатів :(</div>
          )}
        </div>
        {!searchInput && (
          <div className="info-search-wiki">
            *Ulex Wiki – це великий набір загальних інструкцій по використанню сервісу Ulex.{' '}
          </div>
        )}
        {searchInput && stateFilter.length <= 4 && (
          <div className="info-search-wiki">
            *Ulex Wiki – це великий набір загальних інструкцій по використанню сервісу Ulex.{' '}
          </div>
        )}
        {searchInput && stateFilter.length > 4 && (
          <div className="item-all-resalt-search">
            Показати усі {stateFilter.length} результатів
          </div>
        )}
      </div>
    </div>
  );
}
export default Search;
