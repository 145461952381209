import React from 'react';
import { Link } from 'react-router-dom';
import './style.scss';

import { useDispatch } from 'react-redux';
import { ReactComponent as Arrow } from 'assets/arrow-stick.svg';
import { ReactComponent as Twitter } from 'assets/twitter.svg';
import { ReactComponent as Telegram } from 'assets/telegram.svg';
import { ReactComponent as Fackebook } from 'assets/fackebook.svg';
import { setSection } from 'app/store/modules/Common';

function Menu() {
  const dispatch = useDispatch();

  const updateSection = (s) => {
    dispatch(setSection(s));
  };

  return (
    <div className="wrapper-menu-ulex">
      <div className="content-menu-ulex">
        <div className="title-menu">Навігація</div>
        <Link to="/" className="item-menu-nav" onClick={() => updateSection('')}>
          <div className="title-item-menu-nav">Головна сторінка</div>
          <Arrow />
        </Link>
        <Link to="/" className="item-menu-nav">
          <Link to="/wiki" className="title-item-menu-nav">
            Ulex Wiki
          </Link>
          <Arrow />
        </Link>
        <div className="item-line-menu" />
        <div className="item-social-network-language-menu">
          <div className="social-network-menu">
            <div className="title-social-network-language-menu">Наші соцмережі</div>
            <div className="container-social-network">
              <div className="item-social-network">
                <Twitter />
              </div>
              <div className="item-social-network">
                <Telegram />
              </div>
              <div className="item-social-network">
                <Fackebook />
              </div>
            </div>
          </div>
          {/*<div className="language-menu">*/}
          {/*  <div className="title-social-network-language-menu">Moва</div>*/}
          {/*  <div className="container-language-network">Українська</div>*/}
          {/*</div>*/}
        </div>
      </div>
    </div>
  );
}
export default Menu;
