import Button from 'app/components/ui/Button';
import React from 'react';
import './style.scss';

function PartArticle({ onClose }) {
  return (
    <div className="how-to-choose">
      <div className="how-to-choose__wrapper">
        <div className="how-to-choose__header">
          <h2>Частина статті</h2>
        </div>
        <div className="how-to-choose__content">
          <h4 className="how-to-choose__content__text--regular">
            Має бути зазначено у 7 пункті постанови
          </h4>
        </div>
        <div className="how-to-choose__footer">
          <Button className="clear" onClick={onClose}>
            Закрити
          </Button>
        </div>
      </div>
    </div>
  );
}

export default PartArticle;
