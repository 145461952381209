import React, { useEffect, useState } from 'react';
import './index.scss';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { ReactComponent as Edit } from '../../../../../assets/edit.svg';
// import { ReactComponent as Question } from '../../../../../assets/question.svg';
import { setAnswers, useCase } from '../../../../store/modules/Case';
import ModalWrapper from '../../../../components/ModalWrapper';
import PartArticle from '../../../Main/components/PartArticle';

export const optionsSelectPartArticle = [
  { value: '0', label: '1' },
  { value: '1', label: '2' },
  { value: '2', label: '3' },
];

function ItemTypePartArticle({ item, index }) {
  const dispatch = useDispatch();
  const { currentCase } = useCase();
  const [update, setUpdate] = useState(false);
  const answer = currentCase?.answers?.find((el) => el.question === item._id);
  const [currentPart, setCurrentPart] = useState(optionsSelectPartArticle[0]);
  const [openWarningModal, setOpenWarningModal] = useState(false);

  const handleOpenWarningModal = (isOpen) => {
    setOpenWarningModal(isOpen);
  };

  const colourStyles = {
    control: (styles) => {
      return {
        ...styles,
        marginTop: '12px',
        padding: '6px 6px',
        cursor: 'pointer',
        boxShadow: 'none',
        color: '#FC6B58',
        border: '1px solid #ccc',
        ':hover': {
          ...styles[':hover'],
          border: '1px solid #ccc',
        },
      };
    },
    option: (styles) => {
      return {
        ...styles,
        color: 'black',
        cursor: 'pointer',
        backgroundColor: 'transparent !important',
        ':hover': {
          ...styles[':hover'],
          cursor: 'pointer',
          color: '#FC6B58',
          backgroundColor: 'none',
        },
        '.active': {
          ...styles['.active'],
          backgroundColor: 'white',
          color: '#FC6B58',
        },
      };
    },
    singleValue: (styles) => {
      return {
        ...styles,
        backgroundColor: 'transparent',
        ':hover': {
          ...styles[':hover'],
          color: '#FC6B58',
        },
      };
    },
  };

  const finishPartArticle = {
    partArticle: currentPart.label,
  };

  useEffect(() => {
    if (answer) {
      setCurrentPart(optionsSelectPartArticle[optionsSelectPartArticle.find(((el) => el.label === answer.value.partArticle)).value]);
    }
  }, [answer]);

  const handleSelect = () => () => {
    if (!currentPart) {
      toast.error('Заповніть дані');
      return;
    }
    setUpdate(false);
    if (answer) {
      dispatch(setAnswers({ question: item._id, value: finishPartArticle }));
    } else {
      dispatch(setAnswers({ question: item._id, value: finishPartArticle, new: true }));
    }
  };

  return (
    <>
      {!answer || update
        ? (
          <>{index > currentCase?.answers?.length
            ? (
              <div className="content-item-step-inactive">
                <div className="title-item-step-inactive"><div>{item.title}</div></div>
                <div className="description-item-step-inactive">Не пройдено</div>
              </div>
            )
            : (
              <div className="content-item-type-partArticle">
                <div className="bang-item-type-partArticle" />
                <div className="title-item-type-partArticle">
                  <div>{item.title}</div>
                  {/*<span onClick={() => handleOpenWarningModal(true)}>*/}
                  {/*  <Question />*/}
                  {/*</span>*/}
                </div>
                <div className="description-item-type-partArticle">{item.description}</div>
                <div className="entry-field-item-type-partArticle">
                  <div className="title-entry-field-item-type-partArticle">Частина статті:</div>
                  <div className="container-select-item-type-partArticle">
                    <Select onChange={setCurrentPart} value={currentPart} options={optionsSelectPartArticle} styles={colourStyles} />
                  </div>
                </div>

                <div className="btn-item-type-partArticle" onClick={handleSelect('Пройдено')}>{item.button}</div>
              </div>
            )}
          </>
        )
        : (
          <>
            <div className="content-item-step-inactive">
              <div className="title-item-step-inactive"><div>{item.title}</div>{answer ? <span onClick={() => setUpdate(true)}><Edit /></span> : null}</div>
              <div className="description-item-step-inactive">Пройдено</div>
            </div>
          </>
        )}
      <ModalWrapper
        isOpened={openWarningModal}
        onClose={() => handleOpenWarningModal(false)}
      >
        {(onClose) => <PartArticle onClose={onClose} />}
      </ModalWrapper>
    </>
  );
}

export default ItemTypePartArticle;
