import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useProfile } from '../store/modules/Profile';
import Loading from '../components/Loading';

function AuthGuard() {
  const { profile, isLoading } = useProfile();

  if (isLoading) return <Loading />;

  if (!profile) {
    return <Navigate to="/" replace />;
  }

  return <Outlet />;
}

export default AuthGuard;
