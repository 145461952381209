import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import Registration from 'app/components/Header/components/Registration';
import Search from 'app/components/Header/components/Search';
import Profile from 'app/components/Header/components/Profile';
import SettingsProfile from 'app/components/Header/components/SettingsProfile';
import Authorization from 'app/components/Header/components/Authorization';
import Menu from 'app/components/Header/components/Menu';
import AddCar from 'app/components/Header/components/AddCar';
import { setSection, useCommon } from 'app/store/modules/Common';

function MegaMenu() {
  const dispatch = useDispatch();
  const { section } = useCommon();

  const updateSection = (s) => {
    dispatch(setSection(s));
  };

  const content = useMemo(() => {
    switch (section) {
      case 'authorization':
        return <Authorization />;
      case 'registration':
        return <Registration />;
      case 'menu':
        return <Menu />;
      case 'search':
        return <Search />;
      case 'profile':
        return <Profile />;
      case 'settingsProfile':
        return <SettingsProfile />;
      case 'addcar':
        return <AddCar />;
      default:
        return null;
    }
  }, [section]);

  return (
    <>
      {section ? <div onClick={() => updateSection('')} className="blackout" /> : null}
      {content}
    </>
  );
}

export default MegaMenu;
