import React, { useEffect, useState } from 'react';
import './style.scss';
import { toast } from 'react-toastify';
import InputMask from 'react-input-mask';
import Select from 'react-select';
import { useMoreData } from '../../context';
import Button from '../../../../components/ui/Button';

export const optionsSelectPassport = [
  { value: '0', label: 'ID картка' },
  { value: '1', label: 'Паспорт книжкою' },
];

export const colourStyles = {
  container: (styles) => {
    return {
      ...styles,
      width: '100%',
    };
  },
  control: (styles) => {
    return {
      ...styles,
      alignItems: 'center',
      marginTop: '-10px',
      marginBottom: '-10px',
      padding: '0',
      cursor: 'pointer',
      boxShadow: 'none',
      color: '#FC6B58',
      border: '1px solid transparent',
      ':hover': {
        ...styles[':hover'],
        border: '1px solid transparent',
      },
    };
  },
  option: (styles) => {
    return {
      ...styles,
      color: 'black',
      cursor: 'pointer',
      backgroundColor: 'transparent !important',
      ':hover': {
        ...styles[':hover'],
        cursor: 'pointer',
        color: '#FC6B58',
        backgroundColor: 'none',
      },
      '.active': {
        ...styles['.active'],
        backgroundColor: 'white',
        color: '#000000',
      },
    };
  },
  valueContainer: (styles) => {
    return {
      ...styles,
      padding: '0px',
    };
  },
  singleValue: (styles) => {
    return {
      ...styles,
      padding: '2px',
      backgroundColor: 'transparent',
      ':hover': {
        ...styles[':hover'],
        color: '#000000',
      },
    };
  },
};

function ItemStep2() {
  const { answers, setAnswers, setStep } = useMoreData();
  const [identCodeInput, setIdentCodeInput] = useState('');
  const [phoneNumberInput, setPhoneNumberInput] = useState('');
  const [birthdayInput, setBirthdayInput] = useState('');
  const [passportIDInput, setPassportIDInput] = useState('');
  const [viewPassport, setViewPassport] = useState(optionsSelectPassport[0]);
  const [error, setError] = useState(false);

  useEffect(() => {
    setIdentCodeInput(answers.identCode);
    setPhoneNumberInput(answers.phoneNumber);
    setBirthdayInput(answers.birthday);
    setPassportIDInput(answers.passportID);
  }, []);

  useEffect(() => {
    const regex = /^[А-Я]{2}\d{6}$/i;
    if (!answers?.passportID?.length) return;
    if (regex.test(answers.passportID)) {
      setViewPassport(optionsSelectPassport[1]);
    }
  }, []);

  const updateIdentCode = () => {
    if (identCodeInput.length) {
      if (identCodeInput.length === 10 || identCodeInput.length === 12) {
        setAnswers({ ...answers, identCode: identCodeInput });
      } else {
        toast.error('Невірний IНН');
      }
    }
  };

  const updatePhoneNumber = () => {
    const checkPhoneInput = phoneNumberInput.replace(/\D/g, '').replace(/^7/, '');

    if (checkPhoneInput.length < 12) {
      toast.error('Невірно набраний номер');
    } else {
      setAnswers({ ...answers, phoneNumber: checkPhoneInput });
    }
  };

  const updateBirthday = () => {
    const checkBirthdayInput = birthdayInput.replace(/\/+$/, '').replaceAll('_', '');

    if (birthdayInput.length) {
      if (checkBirthdayInput.length === 10) {
        setAnswers({ ...answers, birthday: checkBirthdayInput });
      } else {
        toast.error('Невірна дата народження');
      }
    }
  };

  const updatePassport = () => {
    if (passportIDInput.length) {
      if (viewPassport === optionsSelectPassport[0]) {
        if (passportIDInput.length !== 9) {
          toast.error('Заповніть вірно поле паспорта');
          setError(true);
          return;
        }
      } else {
        const regex = /^[А-Я]{2}\d{6}$/i;
        if (!regex.test(passportIDInput)) {
          toast.error('Заповніть вірно поле паспорта');
          setError(true);
          return;
        }
      }
      setError(false);
      setAnswers({ ...answers, passportID: passportIDInput });
    }
  };

  const changePassportInput = (e) => {
    if (viewPassport === optionsSelectPassport[0]) {
      if (e.target.value.length <= 9) {
        setPassportIDInput(e.target.value.toUpperCase());
      }
    } else if (e.target.value.length <= 8) {
      setPassportIDInput(e.target.value.toUpperCase());
    }
  };

  const nextStep = () => {
    if (error) return;
    if (answers.identCode.length && answers.phoneNumber.length === 12 && answers.birthday.length && answers.passportID.length) {
      setStep(3);
    } else {
      toast.error('Заповніть всі поля вірно');
    }
  };

  return (
    <>
      <div className="wrapper-step">
        <div className="items-input-step">
          <div className="items-input-step__blockInputs">
            <div className="item-input-step">
              Ідентифікаційний код:
              <input
                onBlur={updateIdentCode}
                onChange={(e) => (e.target.value.length <= 12 ? setIdentCodeInput(e.target.value) : null)}
                value={identCodeInput}
                type="number"
                placeholder="2131241212"
              />
            </div>
            <div className="item-input-step">
              Номер телефону:
              <InputMask
                mask="+380 (99) 999 9999"
                onBlur={updatePhoneNumber}
                value={phoneNumberInput}
                onChange={(e) => setPhoneNumberInput(e.target.value)}
                placeholder="+380 (00) 000 0000"
              />
            </div>
          </div>
          <div className="items-input-step__blockInputs">
            <div className="item-input-step">
              Дата народження:
              <InputMask
                mask="9999/99/99"
                onBlur={updateBirthday}
                value={birthdayInput}
                onChange={(e) => setBirthdayInput(e.target.value)}
                placeholder="2000/10/20"
              />
            </div>
            <div className="item-input-step">
              <Select
                placeholder="Виберіть паспорт"
                options={optionsSelectPassport}
                styles={colourStyles}
                value={viewPassport}
                onChange={(e) => {
                  setError(true);
                  setPassportIDInput('');
                  setViewPassport(e);
                }}
              />
              <input
                onBlur={updatePassport}
                onChange={changePassportInput}
                value={passportIDInput}
                type={viewPassport === optionsSelectPassport[0] ? 'number' : 'text'}
                placeholder={viewPassport === optionsSelectPassport[0] ? '2131241212' : 'КМ797483'}
              />
            </div>
          </div>
        </div>
        <Button className="black" onClick={nextStep}>Наступний крок</Button>
        <div className="footer-step">
          <Button onClick={() => setStep(null)} className="clear">Відмінити</Button>
        </div>
      </div>
    </>
  );
}

export default ItemStep2;
