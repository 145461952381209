import React from 'react';
import './style.scss';
import { formatDistance } from 'date-fns';
import { uk } from 'date-fns/locale';

import { useGetAllPostsQuery } from 'app/services/post.service';
import Post from '../../../components/Post';

function PostSideBar(props) {
  const { postId } = props;

  const { data } = useGetAllPostsQuery();

  //Filtered post without already picked one
  const showAnotherPosts = data?.filter((post) => post._id !== postId);

  return (
    <div className="post-side-bar">
      <div className="post-side-bar__content">
        <div className="post-side-bar__head-text">
          <h4 className="post-side-bar__head-text--post">Інші статті</h4>
          <h4 className="post-side-bar__head-text--interesting">Вас також може зацікавити:</h4>
        </div>
        <div className="post-side-bar__posts">
          {showAnotherPosts?.map((post) => {
            return (
              <Post
                key={post._id}
                image={post.thumbnail}
                title={post.title}
                date={formatDistance(new Date(post.createdAt), new Date(), {
                  addSuffix: true,
                  locale: uk,
                })}
                postId={post._id}
                content={post.content}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default PostSideBar;
