import React, { useEffect, useState } from 'react';
import './index.scss';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { ReactComponent as Edit } from '../../../../../assets/edit.svg';
import 'react-datepicker/dist/react-datepicker.css';
import { setAnswers, useCase } from '../../../../store/modules/Case';

function ItemTypeMeansFixation({ item, index }) {
  const dispatch = useDispatch();
  const { currentCase } = useCase();
  const [toolModel, setToolModel] = useState();
  const [serialNumber, setSerialNumber] = useState();
  const answer = currentCase?.answers?.find((el) => el.question === item._id);

  const finishMeansFixation = {
    toolModel,
    serialNumber,
  };

  const [update, setUpdate] = useState(false);

  useEffect(() => {
    if (answer) {
      setToolModel(answer.value.toolModel);
      setSerialNumber(answer.value.serialNumber);
    }
  }, [answer]);

  const handleSelect = () => () => {
    if (!toolModel || !serialNumber) {
      toast.error('Вкажіть данi');
      return;
    }

    if (answer) {
      dispatch(setAnswers({ question: item._id, value: finishMeansFixation }));
    } else {
      dispatch(setAnswers({ question: item._id, value: finishMeansFixation, new: true }));
    }
    setUpdate(false);
  };

  return (

    <>
      {!answer || update
        ? (
          <>{index > currentCase?.answers?.length
            ? (
              <div className="content-item-step-inactive">
                <div className="title-item-step-inactive"><div>{item.title}</div></div>
                <div className="description-item-step-inactive">Не пройдено</div>
              </div>
            )
            : (
              <div className="content-item-type-meansFixation">
                <div className="bang-item-type-meansFixation" />
                <div className="title-item-type-meansFixation">{item.title}</div>
                <div className="description-item-type-meansFixation">{item.description}</div>

                <div className="entry-field-item-type-meansFixation">
                  <div className="title-entry-field-item-type-meansFixation">Модель засобу, яким здійснили фіксацію порушення:</div>
                  <input onChange={(e) => setToolModel(e.target.value)} value={toolModel} type="text" placeholder="Модель засобу" />
                </div>
                <div className="entry-field-item-type-meansFixation">
                  <div className="title-entry-field-item-type-meansFixation">IMEI або серійний номер:</div>
                  <input onChange={(e) => setSerialNumber(e.target.value)} value={serialNumber} type="text" placeholder="IMEI" />
                </div>
                <div className="btn-item-type-meansFixation" onClick={handleSelect('Пройдено')}>{item.button}</div>
              </div>
            )}
          </>
        )
        : (
          <>
            <div className="content-item-step-inactive">
              <div className="title-item-step-inactive"><div>{item.title}</div>{answer ? <span onClick={() => setUpdate(true)}><Edit /></span> : null}</div>
              <div className="description-item-step-inactive">Пройдено</div>
            </div>
          </>
        )}
    </>

  );
}
export default ItemTypeMeansFixation;
