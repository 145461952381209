import query from '../store/query.options';

export const postsApi = query.injectEndpoints({
  endpoints: (builder) => ({
    getAllPosts: builder.query({
      query: () => ({
        url: '/api/v1/post',
        method: 'GET',
      }),
      providesTags: () => [
        { type: 'Posts', id: 'LIST' },
      ],
    }),
    getSinglePost: builder.query({
      query: (id) => ({
        url: `/api/v1/post/${id}`,
        method: 'GET',
      }),
      providesTags: (result, error, id) => [{ type: 'Post', id }],
    }),
  }),
});

export const {
  useGetAllPostsQuery,
  useGetSinglePostQuery,
} = postsApi;
