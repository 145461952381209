import React, { useState } from 'react';
import './style.scss';
import { ReactComponent as Master } from 'assets/logo-masterCard.svg';
import { ReactComponent as Wallet } from 'assets/icon-wallet.svg';
import Select from 'react-select';
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import Button from '../ui/Button';
import { setAnswers } from '../../store/modules/Case';

const months = [
  { value: '01', label: '01' },
  { value: '02', label: '02' },
  { value: '03', label: '03' },
  { value: '04', label: '04' },
  { value: '05', label: '05' },
  { value: '06', label: '06' },
  { value: '07', label: '07' },
  { value: '08', label: '08' },
  { value: '09', label: '09' },
  { value: '10', label: '10' },
  { value: '11', label: '11' },
  { value: '12', label: '12' },
];

const colourStyles = {
  container: (styles) => {
    return {
      ...styles,
      width: '100%',
    };
  },
  control: (styles) => {
    return {
      ...styles,
      width: '100%',
      marginTop: '12px',
      padding: '6px 6px',
      cursor: 'pointer',
      boxShadow: 'none',
      // color: '#FC6B58',
      color: 'white',
      border: '1px solid #404040',
      ':hover': {
        ...styles[':hover'],
        border: '1px solid #6f6f6f',
      },
      backgroundColor: 'transparent',
    };
  },
  option: (styles) => {
    return {
      ...styles,
      color: 'white',
      cursor: 'pointer',
      backgroundColor: '#202020',
      ':hover': {
        ...styles[':hover'],
        cursor: 'pointer',
        // color: '#FC6B58',
        color: 'white',
        backgroundColor: '#FC6B58',
      },
      '.active': {
        ...styles['.active'],
        backgroundColor: '#202020',
        color: 'white',
      },
    };
  },
  valueContainer: (styles) => {
    return {
      ...styles,
      padding: '0px 10px',
      fontSize: '16px',
      color: 'white',
    };
  },
  indicatorsContainer: (styles) => {
    return {
      // ...styles,
      color: 'red !important',
      ':hover': {
        ...styles[':hover'],
        color: '#FC6B58',
      },
    };
  },
  singleValue: (styles) => {
    return {
      ...styles,
      backgroundColor: 'transparent',
      color: 'white',
      ':hover': {
        ...styles[':hover'],
        color: '#FC6B58',
      },
    };
  },
};

function PaymentModal({ item, onClose }) {
  const dispatch = useDispatch();
  const [cardNumber, setCardNumber] = useState('');
  const [ccvCode, setCcvCode] = useState('');
  const [checkAddress, setCheckAddress] = useState(true);

  const maxYear = 2060;

  const years = Array.from(
    { length: maxYear - new Date().getFullYear() + 1 },
    (_, i) => {
      const year = new Date().getFullYear() + i;
      return { value: year.toString().slice(-2), label: year.toString().slice(-2) };
    },
  );
  const [selectedMonth, setSelectedMonth] = useState(months[0]);
  const [selectedYear, setSelectedYear] = useState(years[0]);

  const createPayment = () => {
    if (cardNumber.length < 19) return toast.error('Заповніть номер картки');
    if (ccvCode.length < 3) return toast.error('Заповніть CVV код');
    dispatch(setAnswers({ question: item._id, value: 'Пройдено', new: true }));
    onClose();
  };

  return (
    <div className="paymentModal">
      <div className="paymentModal__container">
        <div className="paymentModal__content">
          <div className="paymentModal__title">Сплата послуги</div>
          <div className="paymentModal__desc statement">
            До сплати: <span>500 ₴</span>
          </div>
          <div className="paymentModal__descSecond statement">
            Вкажіть дані для сплати:
          </div>
          <div className="paymentModal__card">
            <div className="paymentModal__card__logo">
              <Master />
            </div>
            <div className="paymentModal__card__number">
              <div className="paymentModal__card__input">
                Номер картки:
                <InputMask
                  mask="9999 9999 9999 9999"
                  disableUnderline
                  value={cardNumber}
                  maskChar=""
                  onChange={(e) => setCardNumber(e.target.value)}
                  placeholder="5375 2850 7182 3818"
                />
                {/*<input onChange={(e) => setCardNumber(e.target.value)} value={cardNumber} type="number" placeholder="5375 2850 7182 3818" />*/}
              </div>
            </div>
            <div className="paymentModal__card__secondParam">
              <div className="paymentModal__card__input">
                CCV:
                <input style={{ maxWidth: '100px' }} onChange={(e) => e.target.value.length <= 3 && setCcvCode(e.target.value)} value={ccvCode} type="number" placeholder="378" />
              </div>
              <div className="paymentModal__card__input date">
                Термін дії
                <div className="paymentModal__card__data">
                  <Select
                    options={months}
                    styles={colourStyles}
                    value={selectedMonth}
                    onChange={(selectedOption) => setSelectedMonth(selectedOption)}
                    placeholder="Месяц"
                  />
                  <Select
                    options={years}
                    styles={colourStyles}
                    value={selectedYear}
                    onChange={(selectedOption) => setSelectedYear(selectedOption)}
                    placeholder="Год"
                  />
                </div>
              </div>
            </div>
            <Button className="payment" onClick={createPayment}><Wallet /> Сплатити</Button>
          </div>
          <label className="item-radio-item-type-questionnaire">
            <span className="text-item-radio-item-type-questionnaire">
              Зберегти та використовувати ці дані для подальших сплат.
            </span>
            <input checked={checkAddress} onChange={() => setCheckAddress(!checkAddress)} type="checkbox" name="answer" />
            <span className="checkmark-item-radio-item-type-questionnaire" />
          </label>
          <div className="paymentModal__footer payment">
            <Button className="clear" onClick={onClose}>Закрити</Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentModal;
