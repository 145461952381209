import React, { useEffect, useState } from 'react';
import './style.scss';
import { useDispatch } from 'react-redux';
import { ReactComponent as Edit } from '../../../../../assets/edit.svg';
import { setAnswers, useCase } from '../../../../store/modules/Case';
import MoreData from '../../../MoreData';
import ModalWrapper from '../../../../components/ModalWrapper';
import { useProfile } from '../../../../store/modules/Profile';

function ItemTypeAuth({ item, index }) {
  const dispatch = useDispatch();
  const { currentCase } = useCase();
  const { profile } = useProfile();
  const [update, setUpdate] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const answer = currentCase?.answers?.find((el) => el.question === item._id);

  const handleSelect = () => {
    setOpenModal(true);
  };

  useEffect(() => {
    if (profile && profile.data) {
      setUpdate(false);
      if (answer) {
        dispatch(setAnswers({ question: item._id, value: 'Пройдено' }));
      } else {
        dispatch(setAnswers({ question: item._id, value: 'Пройдено', new: true }));
      }
    }
  }, [profile]);

  return (
    <>
      <ModalWrapper isOpened={openModal} onClose={() => setOpenModal(false)}>
        {(onClose) => {
          const handleClose = () => {
            sessionStorage.setItem('showedMoreData', 'true');
            onClose();
          };

          return <MoreData edit={profile && profile.data} onClose={handleClose} />;
        }}
      </ModalWrapper>
      {!answer || update
        ? (
          <>{index > currentCase?.answers?.length
            ? (
              <div className="content-item-step-inactive">
                <div className="title-item-step-inactive">
                  <div>Внесення персональних данних</div>
                </div>
                <div className="description-item-step-inactive">Не пройдено</div>
              </div>
            )
            : (
              <div className="content-item-type-auth">
                <div className="bang-item-type-auth" />
                <div
                  className="title-item-type-auth"
                >{profile && profile.data ? 'Редагування персональних данних' : 'Внесення персональних данних'}
                </div>
                <div className="description-item-type-auth">
                  {profile && profile.data ? 'Якщо ви бажаєте відредагувати свої персональні дані. Будь ласка, введіть відредаговані дані для оновлення інформації.' : 'Ми помітили, що ви ще не ввели додаткові персональні дані. Щоб продовжити заповнення заявки, будь ласка, введіть потрібну інформацію'}
                </div>
                <div
                  className="btn-item-type-auth"
                  onClick={handleSelect}
                >{profile && profile.data ? 'Редагувати дані' : 'Внести дані'}
                </div>
                {profile && profile.data ? (
                  <div
                    className="btn-item-type-auth second"
                    onClick={() => setUpdate(false)}
                  >Вiдминити
                  </div>
                ) : null}
              </div>
            )}
          </>
        )
        : (
          <div className="content-item-step-inactive">
            <div className="title-item-step-inactive">
              <div>Внесення персональних данних
              </div>
              {answer ? <span onClick={() => setUpdate(true)}><Edit /></span> : null}
            </div>
            <div className="description-item-step-inactive">Пройдено</div>
          </div>
        )}
    </>
  );
}

export default ItemTypeAuth;
