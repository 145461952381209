import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { useProfile } from '../../../store/modules/Profile';
import config from '../../../../config';

const MoreDataContext = React.createContext(undefined);

const data = {
  firstName: '',
  lastName: '',
  middleName: '',
  identCode: '',
  phoneNumber: '',
  birthday: '',
  passportID: '',
  city: '',
  regiment: '',
  street: '',
  index: '',
  house: '',
  citySecond: '',
  regimentSecond: '',
  streetSecond: '',
  indexSecond: '',
  houseSecond: '',
};

const changeUrl = (url) => {
  return `${config.apiUrl}/api/v1/files/?path=${url}`;
};

export function MoreDataProvider({ children }) {
  const { profile } = useProfile();
  const [step, setStep] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState(data);
  const [passportFiles, setPassportFiles] = useState([]);

  useEffect(() => {
    if (profile.data) {
      setAnswers(profile.data);
    }
    if (profile.docs) {
      const newData = Object.keys(profile.docs).reduce((acc, key) => {
        switch (key) {
          case 'passportBook':
            profile.docs[key].forEach((url, index) => {
              acc[`passportFile${index + 1}`] = { url: changeUrl(url) };
            });
            break;
          case 'passportCard':
            profile.docs[key].forEach((url, index) => {
              acc[`passportFile${index + 1}`] = { url: changeUrl(url) };
            });
            break;
          case 'driveLicense':
            acc.driveLicense = { url: changeUrl(profile.docs[key]) };
            break;
          case 'driveLicenseSecond':
            acc.driveLicenseSecond = { url: changeUrl(profile.docs[key]) };
            break;
          case 'identCode':
            acc.identCode = { url: changeUrl(profile.docs[key]) };
            break;
          default: break;
        }
        return acc;
      }, {});
      setPassportFiles(newData);
    }
  }, []);

  const value = useMemo(() => ({
    step,
    setStep,
    answers,
    setAnswers,
    questions,
    setQuestions,
    passportFiles,
    setPassportFiles,
  }), [
    step,
    setStep,
    questions,
    setQuestions,
    answers,
    setAnswers,
    passportFiles,
    setPassportFiles,
  ]);

  return (
    <MoreDataContext.Provider value={value}>
      {children}
    </MoreDataContext.Provider>
  );
}

export const useMoreData = () => useContext(MoreDataContext);
