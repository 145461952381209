import React, { useEffect, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import uk from 'date-fns/locale/uk';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { ReactComponent as Edit } from '../../../../../assets/edit.svg';
import 'react-datepicker/dist/react-datepicker.css';
import './style.scss';
import { setAnswers, useCase } from '../../../../store/modules/Case';
// eslint-disable-next-line import/order
import Select from 'react-select';

export const optionsSelectPerson = [
  { value: '0', label: 'поліцейський' },
  { value: '1', label: 'інспектор' },
];

function ItemTypeDecree({ item, index }) {
  registerLocale('uk', uk);
  const dispatch = useDispatch();
  const { currentCase } = useCase();
  const [startDate, setStartDate] = useState(new Date());
  const [numberDecree, setNumberDecree] = useState('');
  const [timeFirst, setTimeFirst] = useState('');
  const [timeSecond, setTimeSecond] = useState('');
  // const [selectedOption, setSelectedOption] = useState('Select an option...');
  const [currentPerson, setCurrentPerson] = useState(optionsSelectPerson[0]);
  const answer = currentCase?.answers?.find((el) => el.question === item._id);

  const finishDecree = {
    number: numberDecree,
    date: startDate,
    time: `${timeFirst}:${timeSecond}`,
    person: currentPerson.label,
  };

  const [update, setUpdate] = useState(false);

  useEffect(() => {
    if (answer) {
      setStartDate(new Date(answer.value.date));
      setNumberDecree(answer.value.number);
      setTimeFirst(answer.value.time.split(':')[0]);
      setTimeSecond(answer.value.time.split(':')[1]);
      setCurrentPerson(optionsSelectPerson[optionsSelectPerson.find(((el) => el.label === answer.value.person)).value]);

      // setSelectedOption(answer.value.person);
    }
  }, [answer]);

  const handleSelect = () => () => {
    if (!currentPerson) {
      toast.error('Выберiть хто виписав постанову');
      return;
    }
    if (!numberDecree || !timeFirst.length || !timeSecond.length) {
      toast.error('Заповніть всі дані');
      return;
    }

    if (timeFirst.length < 2 || timeFirst > 23 || timeFirst < 0) {
      toast.error('Заповніть вірно поле с годинами');
      return;
    }
    if (timeSecond.length < 2 || timeSecond > 59 || timeSecond < 0) {
      toast.error('Заповніть вірно поле с хвилинами');
      return;
    }

    if (answer) {
      dispatch(setAnswers({ question: item._id, value: finishDecree }));
    } else {
      dispatch(setAnswers({ question: item._id, value: finishDecree, new: true }));
    }
    setUpdate(false);
  };

  const colourStyles = {
    control: (styles) => {
      return {
        ...styles,
        marginTop: '12px',
        padding: '6px 6px',
        cursor: 'pointer',
        boxShadow: 'none',
        color: '#FC6B58',
        border: '1px solid #ccc',
        ':hover': {
          ...styles[':hover'],
          border: '1px solid #ccc',
        },
      };
    },
    option: (styles) => {
      return {
        ...styles,
        color: 'black',
        cursor: 'pointer',
        backgroundColor: 'transparent !important',
        ':hover': {
          ...styles[':hover'],
          cursor: 'pointer',
          color: '#FC6B58',
          backgroundColor: 'none',
        },
        '.active': {
          ...styles['.active'],
          backgroundColor: 'white',
          color: '#FC6B58',
        },
      };
    },
    singleValue: (styles) => {
      return {
        ...styles,
        backgroundColor: 'transparent',
        ':hover': {
          ...styles[':hover'],
          color: '#FC6B58',
        },
      };
    },
  };

  return (

    <>
      {!answer || update
        ? (
          <>{index > currentCase?.answers?.length
            ? (
              <div className="content-item-step-inactive">
                <div className="title-item-step-inactive"><div>{item.title}</div></div>
                <div className="description-item-step-inactive">Не пройдено</div>
              </div>
            )
            : (
              <div className="content-item-type-decree">
                <div className="bang-item-type-decree" />
                <div className="title-item-type-decree">{item.title}</div>
                <div className="description-item-type-decree">{item.description}</div>

                <div className="entry-field-item-type-decree">
                  <div className="title-entry-field-item-type-decree">Номер постанови:</div>
                  <input onChange={(e) => setNumberDecree(e.target.value)} value={numberDecree} type="number" placeholder="000000000" />
                </div>
                <div className="entry-field-item-type-decree">
                  <div className="title-entry-field-item-type-decree">Хто виписав?</div>
                  <div className="container-select-item-type-hearing">
                    <Select onChange={setCurrentPerson} value={currentPerson} options={optionsSelectPerson} styles={colourStyles} />
                  </div>
                </div>
                <div className="entry-field-item-type-decree">
                  <div className="title-entry-field-item-type-decree">Дата постанови:</div>
                  <DatePicker className="date-entry-field-item-type-decree" selected={startDate} dateFormat="dd.MM.yyyy" locale="uk" onChange={(date) => setStartDate(date)} />
                </div>
                <div className="entry-field-item-type-decree">
                  <div className="title-entry-field-item-type-decree">Час виписки постанови:</div>
                  <div className="time-entry-field-item-type-decree">
                    <input onChange={(e) => (e.target.value.length < 3 && e.target.value <= 23 ? setTimeFirst(e.target.value) : '')} max="24" min="0" type="number" placeholder="00" value={timeFirst} />
                    <span>:</span>
                    <input onChange={(e) => (e.target.value.length < 3 && e.target.value <= 59 ? setTimeSecond(e.target.value) : '')} max="60" min="0" type="number" placeholder="00" value={timeSecond} />
                  </div>
                </div>
                <div className="btn-item-type-decree" onClick={handleSelect('Пройдено')}>{item.button}</div>
              </div>
            )}
          </>
        )
        : (
          <>
            <div className="content-item-step-inactive">
              <div className="title-item-step-inactive"><div>{item.title}</div>{answer ? <span onClick={() => setUpdate(true)}><Edit /></span> : null}</div>
              <div className="description-item-step-inactive">Пройдено</div>
            </div>
          </>
        )}
    </>

  );
}
export default ItemTypeDecree;
