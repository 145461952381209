/* eslint-disable react/no-danger */
import React from 'react';
import { ReactComponent as Submit } from 'assets/submit.svg';
import './style.scss';
import { Link, useLocation } from 'react-router-dom';

import { useGetSinglePostQuery } from 'app/services/post.service';
import time from '../../../../assets/time.svg';
import backArrow from '../../../../assets/wiki/BackArrow.svg';
import PostSideBar from './components/PostSideBar';

function WikiPost() {
  const { state } = useLocation();
  const {
    postId, title, image, date,
  } = state;

  const { data, isLoading } = useGetSinglePostQuery(postId);

  const postContent = data?.content;

  return (
    <div className="post-page">
      {isLoading ? (
        <div className="post-page__loader" />
      ) : (
        <>
          <div className="post-page__content">
            <div className="post-page__content--breadcrumbs">
              <Link className="post-page__content--breadcrumbs--link" to="/wiki">
                Wiki
              </Link>
              <span>&gt;</span>
              <div className="post-page__content--breadcrumbs--title">{title}</div>
            </div>
            <div className="post-page__content--return-button">
              <Link to="/wiki" className="post-page__content--return-button--button">
                <img src={backArrow} alt="back" />
                Назад
              </Link>
            </div>
            <div className="post-page__title">
              <h1 className="post-page__title--text">{title}</h1>
            </div>
            <div className="post-page__post-date--wrapper">
              <div className="post-page__post-date--date">
                <img src={time} alt="" />
                <span>{date}</span>
              </div>
              <div className="post-page__post-date--point"> </div>
              <div className="post-page__post-date--submit">
                <Submit className="post-page__post-date--submit__submit" />
                <div>Поділитися</div>
              </div>
            </div>
            <div className="post-page__title-image">
              <img src={image} alt="post img" />
            </div>
            <div className="post-page__post-content">
              <div dangerouslySetInnerHTML={{ __html: postContent }} />
            </div>
          </div>
          <PostSideBar postId={postId} />
        </>
      )}
    </div>
  );
}

export default WikiPost;
