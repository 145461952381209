import Button from 'app/components/ui/Button';
import React from 'react';
import './style.scss';

function HowToChoose({ onClose }) {
  return (
    <div className="how-to-choose">
      <div className="how-to-choose__wrapper">
        <div className="how-to-choose__header">
          <h2>Що вибрати?</h2>
        </div>
        <div className="how-to-choose__content">
          <h4 className="how-to-choose__content__text--regular">
            Перевірте текст постанови інспектора з паркування або поліції про притягнення Вас до
            адміністративноï вiдповiдальності.
          </h4>
          <h4 className="how-to-choose__content__text--regular">
            Після опису порушення зазначено має бути зазначено статтю Правил дорожнього руху, яку
            порушено.
          </h4>
          <h4 className="how-to-choose__content__text--regular">
            Цей пункт у постанові може називатися так:
          </h4>
          <h4 className="how-to-choose__content__text--strong">
            «Опис порушення» <br /> • «Час та мiсце скоення, суть та обставини порушення »
            <br />• «Правова квалiфiкацiя порушення.
          </h4>
          <h4 className="how-to-choose__content__text--regular">
            Цей пункт у постановi може називатися так:
          </h4>
          <h4 className="how-to-choose__content__text--strong">
            • Якщо зазначено пункт 15.9 ПДР - обирайте варіант «Зупинка» <br /> • Якщо зазначено
            пункт 15.10 ПДР-обирайте варiант «Стоянка» <br /> • Якщо зазначено інший пункт, або не
            зазначено взагал який пункт Правил дорожнього руху порушено-обирайте варiант «не
            зазначено».
          </h4>
        </div>
        <div className="how-to-choose__footer">
          <Button className="clear" onClick={onClose}>
            Закрити
          </Button>
        </div>
      </div>
    </div>
  );
}

export default HowToChoose;
