import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
// import { ReactComponent as Arrow } from '../../../../../assets/arrow-stick.svg';
import { ReactComponent as Question } from '../../../../../assets/question.svg';
import { ReactComponent as Edit } from '../../../../../assets/edit.svg';
import './style.scss';
import { setAnswers, useCase } from '../../../../store/modules/Case';
// import WarningModal from '../../../../components/WarningModal';
import ModalWrapper from '../../../../components/ModalWrapper';
import HowToChoose from '../../../Main/components/HowToChoose';

export const optionsSelectArticle = [
  { value: '0', label: '15.9' },
  { value: '1', label: '15.10' },
];

export const optionsSelectLetter = [
  { value: '0', label: 'a' },
  { value: '1', label: 'б' },
  { value: '2', label: 'в' },
  { value: '3', label: 'г' },
  { value: '4', label: 'д' },
  { value: '5', label: 'е' },
  { value: '6', label: 'э' },
  { value: '7', label: 'ж' },
  { value: '8', label: 'з' },
  { value: '9', label: 'и' },
];

const articleOptions = {
  15.9: [
    { value: '0', label: 'а' },
    { value: '1', label: 'б' },
    { value: '2', label: 'в' },
    { value: '3', label: 'г' },
    { value: '4', label: 'ґ' },
    { value: '5', label: 'д' },
    { value: '6', label: 'е' },
    { value: '7', label: 'є' },
    { value: '8', label: 'ж' },
    { value: '9', label: 'з' },
    { value: '10', label: 'й' },
  ],
  '15.10': [
    { value: '0', label: 'a' },
    { value: '1', label: 'б' },
    { value: '2', label: 'в' },
    { value: '3', label: 'г' },
    { value: '4', label: 'ґ' },
    { value: '5', label: 'д' },
    { value: '6', label: 'е' },
    { value: '7', label: 'є' },
  ],
};

function ItemTypeOption({ item, index }) {
  const dispatch = useDispatch();
  const { currentCase } = useCase();
  const [update, setUpdate] = useState(false);
  const [currentArticle, setCurrentArticle] = useState(optionsSelectArticle[0]);
  const [currentLetter, setCurrentLetter] = useState(optionsSelectLetter[0]);
  const answer = currentCase?.answers?.find((el) => el.question === item._id);
  const [openWarningModal, setOpenWarningModal] = useState(false);

  const handleOpenWarningModal = (isOpen) => {
    setOpenWarningModal(isOpen);
  };

  const handleNextClick = () => {
    setUpdate(false);
    if (answer) {
      dispatch(setAnswers({ question: item._id, value: `${currentArticle.label}, ${currentLetter.label}` }));
    } else {
      dispatch(setAnswers({ question: item._id, value: `${currentArticle.label}, ${currentLetter.label}`, new: true }));
    }
  };

  // const handleNextClick = () => {
  //   setUpdate(false);
  //   if (answer) {
  //     dispatch(setAnswers({ question: item._id, value: dropdownValue }));
  //   } else {
  //     dispatch(setAnswers({ question: item._id, value: dropdownValue, new: true }));
  //   }
  // };

  // const handleSelect = (dropdownId) => (event) => {
  //   const el = event.target.value;
  //   setUpdate(false);
  //   setSelectedDropdown(dropdownId);
  //
  //   // Reset the other dropdown if it was previously selected
  //   if (selectedDropdown && selectedDropdown !== dropdownId) {
  //     dispatch(setAnswers({ question: item._id, value: null }));
  //   }
  //
  //   let newValue = '';
  //   if (answer) {
  //     newValue = `${answer.value} ${el}`;
  //   } else {
  //     newValue = el;
  //   }
  //
  //   if (answer) {
  //     dispatch(setAnswers({ question: item._id, value: newValue, new: false }));
  //   } else {
  //     dispatch(setAnswers({ question: item._id, value: newValue, new: true }));
  //   }
  // };

  const colourStyles = {
    control: (styles) => {
      return {
        ...styles,
        marginTop: '12px',
        padding: '6px 6px',
        cursor: 'pointer',
        boxShadow: 'none',
        color: '#FC6B58',
        border: '1px solid #ccc',
        ':hover': {
          ...styles[':hover'],
          border: '1px solid #ccc',
        },
      };
    },
    option: (styles) => {
      return {
        ...styles,
        color: 'black',
        cursor: 'pointer',
        backgroundColor: 'transparent !important',
        ':hover': {
          ...styles[':hover'],
          cursor: 'pointer',
          color: '#FC6B58',
          backgroundColor: 'none',
        },
        '.active': {
          ...styles['.active'],
          backgroundColor: 'white',
          color: '#FC6B58',
        },
      };
    },
    singleValue: (styles) => {
      return {
        ...styles,
        backgroundColor: 'transparent',
        ':hover': {
          ...styles[':hover'],
          color: '#FC6B58',
        },
      };
    },
  };

  return (
    <>
      {!answer || update ? (
        <>
          {index > currentCase?.answers?.length
            ? (
              <div className="content-item-step-inactive">
                <div className="title-item-step-inactive"><div>{item.title}</div></div>
                <div className="description-item-step-inactive">Не пройдено</div>
              </div>
            )
            : (
              <div className="content-item-type-option">
                <div className="bang-item-type-option" />
                <div className="title-item-type-option">
                  <div>{item.title}</div>
                  <span onClick={() => handleOpenWarningModal(true)}>
                    <Question />
                  </span>
                </div>
                <div className="description-item-type-option">{item.description}</div>
                <div className="options-type-option">
                  <div className="container-select-item-type-option">
                    <Select
                      onChange={(selectedOption) => {
                        setCurrentArticle(selectedOption);
                        setCurrentLetter(articleOptions[selectedOption.label][0]); // Установите первую букву по умолчанию при смене статьи
                      }}
                      value={currentArticle}
                      options={optionsSelectArticle}
                      styles={colourStyles}
                    />
                  </div>
                  <div className="container-select-item-type-option">
                    <Select
                      onChange={setCurrentLetter}
                      value={currentLetter}
                      options={articleOptions[currentArticle.label]}
                      styles={colourStyles}
                    />
                  </div>
                </div>
                <div className="container-button-type-option">
                  {/*<div className="btn-switching-item-type-option prev" onClick={prevOption}>Назад</div>*/}
                  <div className="btn-type-option next" onClick={handleNextClick}>Далі</div>
                </div>
              </div>
            )}
        </>
      )
        : (
          <>
            <div className="content-item-step-inactive">
              <div className="title-item-step-inactive"><div>{item.title}</div>{answer ? <span onClick={() => setUpdate(true)}><Edit /></span> : null}</div>
              <div className="description-item-step-inactive">{answer?.value}</div>
            </div>
          </>
        )}
      <ModalWrapper
        isOpened={openWarningModal}
        onClose={() => handleOpenWarningModal(false)}
      >
        {(onClose) => <HowToChoose onClose={onClose} />}
      </ModalWrapper>
    </>
  );
}
export default ItemTypeOption;
