import axios from 'axios';

const CaseService = {

  async createCase(body) {
    const response = await axios.post('/api/v1/case', body);
    return response.data;
  },

  async updateCase(body) {
    const response = await axios.patch(`/api/v1/case/${body._id}`, body);
    return response.data;
  },

  async getAllCase() {
    const response = await axios.get('/api/v1/case');
    return response.data;
  },

  async getCase(id) {
    const response = await axios.get(`/api/v1/case/${id}`);
    return response.data;
  },
};

export default CaseService;
