import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useDispatch } from 'react-redux';
import { ReactComponent as Plus } from '../../../../../assets/plus.svg';
import { ReactComponent as Edit } from '../../../../../assets/edit.svg';
import './style.scss';
import { useProfile } from '../../../../store/modules/Profile';
import { setAnswers, useCase } from '../../../../store/modules/Case';
import ModalWrapper from '../../../../components/ModalWrapper';
import AddCar from '../../../../components/Header/components/AddCar';

function ItemTypeVehicle({ item, index }) {
  const { profile } = useProfile();
  const dispatch = useDispatch();
  const { currentCase } = useCase();
  const [update, setUpdate] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [car, setCar] = useState(false);
  const answer = currentCase?.answers?.find((el) => el.question === item._id);

  useEffect(() => {
    if (!answer) return;
    setCar(answer.value);
  }, [answer]);

  const handleSelect = (el) => () => {
    setUpdate(false);
    if (answer) {
      dispatch(setAnswers({ question: item._id, value: el }));
    } else {
      dispatch(setAnswers({ question: item._id, value: el, new: true }));
    }
  };

  const handleSelectCar = (option) => {
    setCar(option.value);
  };

  const colourStyles = {
    control: (styles) => {
      return {
        ...styles,
        marginTop: '12px',
        padding: '6px 6px',
        cursor: 'pointer',
        boxShadow: 'none',
        color: '#FC6B58',
        border: '1px solid #ccc',
        ':hover': {
          ...styles[':hover'],
          border: '1px solid #ccc',
        },
      };
    },
    option: (styles) => {
      return {
        ...styles,
        color: 'black',
        cursor: 'pointer',
        backgroundColor: 'transparent !important',
        ':hover': {
          ...styles[':hover'],
          cursor: 'pointer',
          color: '#FC6B58',
          backgroundColor: 'none',
        },
        '.active': {
          ...styles['.active'],
          backgroundColor: 'white',
          color: '#FC6B58',
        },
      };
    },
    singleValue: (styles) => {
      return {
        ...styles,
        backgroundColor: 'transparent',
        ':hover': {
          ...styles[':hover'],
          color: '#FC6B58',
        },
      };
    },
  };

  const optionsSelectCars = profile.cars.map((el) => ({
    value: el._id, label: `${el.brand} ${el.model}`,
  }));

  const value = optionsSelectCars.find((el) => el.value === car);

  const addNewCar = (cars) => {
    const last = cars.slice(-1)[0];
    setCar(last._id);
  };

  return (
    <>
      <ModalWrapper isOpened={openModal} onClose={() => setOpenModal(false)}>
        {(onClose) => <AddCar addNewCar={addNewCar} onClose={onClose} />}
      </ModalWrapper>
      {!answer || update
        ? (
          <>{index > currentCase?.answers?.length
            ? (
              <div className="content-item-step-inactive">
                <div className="title-item-step-inactive"><div>{item.title}</div></div>
                <div className="description-item-step-inactive">Не пройдено</div>
              </div>
            )
            : (
              <div className="content-item-type-vehicle">
                <div className="bang-item-type-vehicle" />
                <div className="title-item-type-vehicle">{item.title}</div>
                <div className="description-item-type-vehicle">{item.description}</div>
                <div className="entry-field-item-type-vehicle">
                  <div className="title-entry-field-item-type-vehicle">Автомобіль:</div>
                  <div className="container-select-item-type-vehicle">
                    <Select
                      placeholder="Виберіть авто"
                      options={optionsSelectCars}
                      styles={colourStyles}
                      value={value}
                      onChange={handleSelectCar}
                    />
                  </div>
                </div>
                <div className="or-item-type-vehicle">
                  <div className="title-or-item-type-vehicle">Або</div>
                  <div className="line-or-item-type-vehicle" />
                </div>
                <div className="enter-data-manually-item-type-vehicle" onClick={() => setOpenModal(true)}><Plus /> Внести дані вручну</div>

                <div className="btn-item-type-vehicle" onClick={handleSelect(car)}>{item.button}</div>
              </div>
            )}
          </>
        )
        : (
          <>
            <div className="content-item-step-inactive">
              <div className="title-item-step-inactive"><div>{item.title}</div>{answer ? <span onClick={() => setUpdate(true)}><Edit /></span> : null}</div>
              <div className="description-item-step-inactive">Пройдено</div>
            </div>
          </>
        )}
    </>
  );
}
export default ItemTypeVehicle;
