import React, { useState } from 'react';
import './style.scss';
import { ReactComponent as Eye } from 'assets/icon-eye.svg';
import { useMoreData } from '../../../../context';
import PreviewModal from '../../../../../../components/Header/components/AddCar/components/PreviewModal';
import ModalWrapper from '../../../../../../components/ModalWrapper';

function DataBlock4() {
  const { passportFiles } = useMoreData();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [file, setFile] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(false);

  const handleCancel = () => setPreviewOpen(false);

  const chooseImage = (fileImage, url) => {
    setFile(fileImage);
    setPreviewUrl(url);
    setPreviewOpen(true);
  };

  return (
    <>
      <ModalWrapper className="full" isOpened={previewOpen} onClose={() => handleCancel()}>
        {(onClose) => <PreviewModal file={file} previewUrl={previewUrl} onClose={onClose} />}
      </ModalWrapper>
      <div className="dataFinishBlock">
        <div className="dataFinishBlock__wrapper">
          <div className="dataFinishBlock__header">
            <div className="dataFinishBlock__header__left">
              <div className="dataFinishBlock__header__step">Крок 4</div>
              <div className="dataFinishBlock__header__title">Скани документів</div>
            </div>
          </div>
          <div className="dataFinishBlock__allItems">
            {passportFiles.passportFile3 ? (
              <>
                <div onClick={() => chooseImage(passportFiles?.passportFile1?.file, passportFiles.passportFile1.url)} className="dataFinishBlock__item">
                  <div className="dataFinishBlock__item__eye">
                    <Eye />
                  </div>
                  <span>Паспорт 1 стр.</span>
                  <input disabled value={passportFiles?.passportFile1?.file?.name || ''} type="text" />
                </div>
                <div onClick={() => chooseImage(passportFiles?.passportFile2?.file, passportFiles.passportFile2.url)} className="dataFinishBlock__item">
                  <div className="dataFinishBlock__item__eye">
                    <Eye />
                  </div>
                  <span>Паспорт 2 стр.</span>
                  <input disabled value={passportFiles.passportFile2?.file?.name || ''} type="text" />
                </div>
                <div onClick={() => chooseImage(passportFiles?.passportFile3?.file, passportFiles.passportFile3.url)} className="dataFinishBlock__item">
                  <div className="dataFinishBlock__item__eye">
                    <Eye />
                  </div>
                  <span>Паспорт 11 стр.</span>
                  <input disabled value={passportFiles?.passportFile3?.file?.name || ''} type="text" />
                </div>
              </>
            ) : (
              <>
                <div onClick={() => chooseImage(passportFiles.passportFile1?.file, passportFiles.passportFile1.url)} className="dataFinishBlock__item">
                  <div className="dataFinishBlock__item__eye">
                    <Eye />
                  </div>
                  <span>Паспорт (лицева)</span>
                  <input disabled value={passportFiles.passportFile1?.file?.name || ''} type="text" />
                </div>
                <div onClick={() => chooseImage(passportFiles.passportFile2?.file, passportFiles.passportFile2.url)} className="dataFinishBlock__item">
                  <div className="dataFinishBlock__item__eye">
                    <Eye />
                  </div>
                  <span>Паспорт (тильна)</span>
                  <input disabled value={passportFiles.passportFile2?.file?.name || ''} type="text" />
                </div>
              </>
            )}
            <div onClick={() => chooseImage(passportFiles.identCode?.file, passportFiles.identCode.url)} className="dataFinishBlock__item">
              <div className="dataFinishBlock__item__eye">
                <Eye />
              </div>
              <span>Ідентифікаційний код</span>
              <input disabled value={passportFiles.identCode?.file?.name || ''} type="text" />
            </div>
            <div onClick={() => chooseImage(passportFiles.driveLicense?.file, passportFiles.driveLicense.url)} className="dataFinishBlock__item">
              <div className="dataFinishBlock__item__eye">
                <Eye />
              </div>
              <span>Водійське посвідчення (лицева)</span>
              <input disabled value={passportFiles.driveLicense?.file?.name || ''} type="text" />
            </div>
            <div onClick={() => chooseImage(passportFiles.driveLicenseSecond?.file, passportFiles.driveLicenseSecond.url)} className="dataFinishBlock__item">
              <div className="dataFinishBlock__item__eye">
                <Eye />
              </div>
              <span>Водійське посвідчення (тильна)</span>
              <input disabled value={passportFiles.driveLicenseSecond?.file?.name || ''} type="text" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default DataBlock4;
