import React, { useState } from 'react';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import Dragger from 'antd/es/upload/Dragger';
import { useDispatch } from 'react-redux';
import { Button as AntButton } from 'antd';

import './style.scss';
import tiedup from 'assets/tiedup.svg';
import general from 'assets/general.svg';
import lock from 'assets/lock.svg';

import diya from 'assets/diya.svg';
// import apple from 'assets/apple.svg';
import gmail from 'assets/gmail.svg';

import { ReactComponent as Edit } from 'assets/edit.svg';
import { ReactComponent as Key } from 'assets/key.svg';
import { ReactComponent as Untie } from 'assets/untie.svg';
import { ReactComponent as Bind } from 'assets/bind.svg';

import MoreData from '../../../../views/MoreData';
import ModalWrapper from '../../../ModalWrapper';
import Button from '../../../ui/Button';
import {
  updateUser, useProfile, removeGoogle,
} from '../../../../store/modules/Profile';
import config from '../../../../../config';
import ChangePassword from './ChangePassword';

function SettingsProfile() {
  const dispatch = useDispatch();
  const { profile } = useProfile();
  const [openModal, setOpenModal] = useState(false);
  const [bindDiya, setBindDiya] = useState(false);
  // const [bindApple, setBindApple] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [imageAvatar, setImageAvatar] = useState(null);
  const [uploading, setUploading] = useState(false);
  const bindGmail = profile?.provider?.find((el) => el.type === 'google');

  const updateAvatar = async () => {
    setUploading(true);
    const formData = new FormData();
    formData.append('data', JSON.stringify(profile.data));
    formData.append('avatar', imageAvatar);
    dispatch(updateUser(formData)).then((res) => {
      if (!res.error) {
        setImageAvatar(null);
        setUploading(false);
        toast.success('Успішно');
      } else {
        toast.error('Помилка');
      }
    });
  };

  const handleFileChange = (info) => {
    if (info.file.size > 50000000) {
      toast.error('Файл більше 50мб');
      return;
    }
    setImageAvatar(info.file.originFileObj);
  };

  const handleRemoveGoogle = () => {
    if (!bindGmail) {
      window.location.replace(`${config.apiUrl}/api/v1/auth/google?userId=${profile._id}`);
    }

    dispatch(removeGoogle()).then((res) => {
      if (!res.error) {
        if (res.payload.password) {
          toast.success('Успішно');
        } else {
          toast.error('Необхідно встановити пароль');
          setShowPasswordModal(true);
        }
      } else {
        toast.error('Помилка');
      }
    });
  };

  return (
    <>
      <ModalWrapper isOpened={openModal} onClose={() => setOpenModal(false)}>
        {(onClose) => {
          const handleClose = () => {
            sessionStorage.setItem('showedMoreData', 'true');
            onClose();
          };

          return <MoreData edit={!!profile.data} onClose={handleClose} />;
        }}
      </ModalWrapper>
      <ModalWrapper isOpened={showPasswordModal} onClose={() => setShowPasswordModal(false)}>
        {(onClose) => <ChangePassword onClose={onClose} />}
      </ModalWrapper>
      <div className="wrapper-settings-profile-ulex">
        <div className="content-settings-profile-ulex">
          <div className="title-content-settings-profile">Налаштування профілю</div>
          <div className="body-settings-profile">
            <div className="container-settings-profile-body first">
              <div className="avatar-settings-profile">
                {profile?.image ? <img src={profile.image.includes('googleusercontent') ? profile?.image : `${config.apiUrl}/${profile?.image}`} alt="" /> : null}
              </div>
              <div className="avatar-setting-buttons">
                <Dragger
                  name="avatar"
                  listType="text"
                  className="draggerAvatar"
                  showUploadList={false}
                  onChange={handleFileChange}
                  accept=".jpg,.png,.jpeg,.hevc"
                  customRequest={({ onSuccess }) => (onSuccess ? setTimeout(() => onSuccess('ok'), 0) : '')}
                >
                  {/*{previewUrl ? finishButton : uploadButton}*/}
                  {imageAvatar ? imageAvatar.name : 'Вибрати файл'}
                </Dragger>
                {imageAvatar ? <AntButton type="primary" onClick={updateAvatar}>{uploading ? 'Завантаження' : 'Завантажити'}</AntButton> : null}
              </div>
            </div>
            <div className="container-settings-profile-body second">
              <div className="column-settings-profile">
                <div className="item-settings-profile">
                  <div className="title-item-settings-profile"><img src={general} alt="" /><span>Загальна інформація</span></div>
                  <div className="description-item-settings-profile">Зміна особистих даних можлива лише при умові, коли дані були введені вручну. В іншому випадку Вам потрібно відв’язати аккаунт Дії.</div>
                  <div className="container-btns-settings-profile">
                    <Button onClick={() => setOpenModal(true)} className="settings-profile"><Edit />Редагувати дані</Button>
                  </div>
                  <div className="note-item-settings-profile">Дані, що були надані внаслідок прив’язання аккаунту*</div>
                </div>
                <div className="item-settings-profile">
                  <div className="title-item-settings-profile"><img src={lock} alt="" /><span>Пароль</span></div>
                  <div className="description-item-settings-profile">Якщо вважаєте, що Ваш аккаунт можуть взламати, то зміна пароля  - це найкращий вибір протидіяти цій небезпеці.</div>
                  <div className="container-btns-settings-profile">
                    <Button className="settings-profile" onClick={() => setShowPasswordModal(true)}><Key />Змінити пароль</Button>
                  </div>
                </div>
              </div>
              <div className="column-settings-profile">
                <div className="item-settings-profile">
                  <div className="title-item-settings-profile"><img src={tiedup} alt="" /><span>Прив’язані аккаунти</span></div>
                  <div className="description-item-settings-profile">Завдяки прив’язуванню сторонніх аккаунтів Ви зможете входити у власний за пару кліків.</div>
                  <div className="container-diya-linked-account">
                    <div className="item-linked-account">
                      <div className="first-item-linked-account">
                        <div className="title-img-linked-account">
                          <img src={diya} alt="" />
                          <div className="title-linked-account">Дія</div>
                        </div>
                        {bindDiya ? <div className="date-linked-account">12.10.2021 о 21:00</div> : null }
                      </div>
                      <Button className={!bindDiya ? 'linked-account bind' : 'linked-account untie'} onClick={() => { setBindDiya((prev) => !prev); }}>{!bindDiya ? <><Bind />Прив’язати</> : <><Untie />Відв’язати</>}</Button>
                    </div>
                    <div className="note-linked-account">Використовується для заповнення особистої інформації.</div>
                  </div>
                  <div className="title-others-linked-accounts">Інші сервіси:</div>
                  <div className="container-others-linked-accounts">
                    <div className="item-linked-account">
                      <div className="first-item-linked-account">
                        <div className="title-img-linked-account">
                          <img src={gmail} alt="" />
                          <div className="title-linked-account">Google</div>
                        </div>
                        {bindGmail ? <div className="date-linked-account">{format(new Date(bindGmail?.createdAt || ''), "dd.MM.yyyy 'o' HH:mm")}</div> : null }
                      </div>
                      <Button className={!bindGmail ? 'linked-account bind' : 'linked-account untie'} onClick={handleRemoveGoogle}>{!bindGmail ? <><Bind />Прив’язати</> : <><Untie />Відв’язати</>}</Button>
                    </div>
                    {/*<div className="item-linked-account">*/}
                    {/*  <div className="first-item-linked-account">*/}
                    {/*    <div className="title-img-linked-account">*/}
                    {/*      <img src={apple} alt="" />*/}
                    {/*      <div className="title-linked-account">Apple ID</div>*/}
                    {/*    </div>*/}
                    {/*    {bindApple ? <div className="date-linked-account">12.10.2021 о 21:00</div> : null }*/}
                    {/*  </div>*/}
                    {/*  <Button className={!bindApple ? 'linked-account bind' : 'linked-account untie'} onClick={() => { setBindApple((prev) => !prev); }}>{!bindApple ? <><Bind />Прив’язати</> : <><Untie />Відв’язати</>}</Button>*/}
                    {/*</div>*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default SettingsProfile;
