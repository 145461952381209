import React, { useEffect, useState } from 'react';
import './index.scss';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { ReactComponent as Edit } from '../../../../../assets/edit.svg';
import { setAnswers, useCase } from '../../../../store/modules/Case';

export const optionsSelectRegiment = [
  { value: '0', label: 'Одеса' },
  { value: '1', label: 'Київ' },
  { value: '2', label: 'Дніпропетровськ' },
  { value: '3', label: 'Чернігів' },
  { value: '4', label: 'Харків' },
  { value: '5', label: 'Житомир' },
  { value: '6', label: 'Полтава' },
  { value: '7', label: 'Херсон' },
  { value: '8', label: 'Запоріжжя' },
  { value: '9', label: 'Луганськ' },
  { value: '10', label: 'Донецьк' },
  { value: '11', label: 'Вінниця' },
  { value: '12', label: 'Кiровоград' },
  { value: '13', label: 'Миколаїв' },
  { value: '14', label: 'Суми' },
  { value: '15', label: 'Львів' },
  { value: '16', label: 'Черкаси' },
  { value: '17', label: 'Хмельницький' },
  { value: '18', label: 'Волинь' },
  { value: '19', label: 'Рівне' },
  { value: '20', label: 'Івано-Франківськ' },
  { value: '21', label: 'Тернопіль' },
  { value: '22', label: 'Закарпаття' },
  { value: '23', label: 'Чернівці' },
];

function ItemTypeHearing({ item, index }) {
  const dispatch = useDispatch();
  const { currentCase } = useCase();
  const [update, setUpdate] = useState(false);
  const answer = currentCase?.answers?.find((el) => el.question === item._id);
  const [currentCity, setCurrentCity] = useState(optionsSelectRegiment[0]);
  const [street, setStreet] = useState('');
  const [number, setNumber] = useState('');

  const colourStyles = {
    control: (styles) => {
      return {
        ...styles,
        marginTop: '12px',
        padding: '6px 6px',
        cursor: 'pointer',
        boxShadow: 'none',
        color: '#FC6B58',
        border: '1px solid #ccc',
        ':hover': {
          ...styles[':hover'],
          border: '1px solid #ccc',
        },
      };
    },
    option: (styles) => {
      return {
        ...styles,
        color: 'black',
        cursor: 'pointer',
        backgroundColor: 'transparent !important',
        ':hover': {
          ...styles[':hover'],
          cursor: 'pointer',
          color: '#FC6B58',
          backgroundColor: 'none',
        },
        '.active': {
          ...styles['.active'],
          backgroundColor: 'white',
          color: '#FC6B58',
        },
      };
    },
    singleValue: (styles) => {
      return {
        ...styles,
        backgroundColor: 'transparent',
        ':hover': {
          ...styles[':hover'],
          color: '#FC6B58',
        },
      };
    },
  };

  const finishhearing = {
    city: currentCity.label,
    street,
    number,
  };

  useEffect(() => {
    if (answer) {
      setStreet(answer.value.street);
      setNumber(answer.value.number);
      setCurrentCity(optionsSelectRegiment[optionsSelectRegiment.find(((el) => el.label === answer.value.city)).value]);
    }
  }, [answer]);

  const handleSelect = () => () => {
    const containsDigits = /\d/.test(number);

    if (!currentCity || !street || !number) {
      toast.error('Заповніть дані');
      return;
    }

    if (!containsDigits) {
      toast.error('Введіть коректнi данi номеру будинку');
      return;
    }
    setUpdate(false);
    if (answer) {
      dispatch(setAnswers({ question: item._id, value: finishhearing }));
    } else {
      dispatch(setAnswers({ question: item._id, value: finishhearing, new: true }));
    }
  };

  return (
    <>
      {!answer || update
        ? (
          <>{index > currentCase?.answers?.length
            ? (
              <div className="content-item-step-inactive">
                <div className="title-item-step-inactive"><div>{item.title}</div></div>
                <div className="description-item-step-inactive">Не пройдено</div>
              </div>
            )
            : (
              <div className="content-item-type-hearing">
                <div className="bang-item-type-hearing" />
                <div className="title-item-type-hearing">{item.title}</div>
                <div className="description-item-type-hearing">{item.description}</div>
                <div className="entry-field-item-type-hearing">
                  <div className="title-entry-field-item-type-hearing">Місто:</div>
                  <div className="container-select-item-type-hearing">
                    <Select onChange={setCurrentCity} value={currentCity} options={optionsSelectRegiment} styles={colourStyles} />
                  </div>
                </div>
                <div className="entry-field-item-type-hearing">
                  <div className="title-entry-field-item-type-hearing">Вулиця:</div>
                  <input
                    value={street}
                    onChange={(e) => setStreet(e.target.value)}
                    type="text"
                    placeholder="Назва вулицi"
                  />
                </div>
                <div className="entry-field-item-type-hearing">
                  <div className="title-entry-field-item-type-hearing">Номер:</div>
                  <input value={number} onChange={(e) => setNumber(e.target.value)} type="text" placeholder="Номер будинку" />
                </div>

                <div className="btn-item-type-hearing" onClick={handleSelect('Пройдено')}>{item.button}</div>
              </div>
            )}
          </>
        )
        : (
          <>
            <div className="content-item-step-inactive">
              <div className="title-item-step-inactive"><div>{item.title}</div>{answer ? <span onClick={() => setUpdate(true)}><Edit /></span> : null}</div>
              <div className="description-item-step-inactive">Пройдено</div>
            </div>
          </>
        )}
    </>
  );
}

export default ItemTypeHearing;
