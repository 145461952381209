import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import './style.scss';

export default ({
  children, isOpened, onClose, className = '',
}) => {
  useEffect(() => {
    const modals = document.getElementsByClassName('modalWrapper');
    if (isOpened) {
      document.body.style.overflow = 'hidden';
    } else if (!modals.length) {
      document.body.style.overflow = 'auto';
    }
  }, [isOpened]);

  if (!isOpened) {
    return null;
  }

  return ReactDOM.createPortal(
    <div className={`modalWrapper ${className}`}>
      <div onClick={onClose} className="modalWrapper__bg" />
      <div className="modalWrapper__content">
        {children(onClose)}
      </div>
    </div>,
    document.body,
  );
};
