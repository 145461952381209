import React, { useEffect, useState } from 'react';
import './style.scss';
import { toast } from 'react-toastify';
import { useMoreData } from '../../context';
import Button from '../../../../components/ui/Button';
import FileInput from '../../../../components/Header/components/AddCar/components/FileInput';

function ItemStep4() {
  const {
    answers, setStep, passportFiles, setPassportFiles,
  } = useMoreData();
  const [passportFile1, setPassportFile1] = useState(null);
  const [passportFile2, setPassportFile2] = useState(null);
  const [passportFile3, setPassportFile3] = useState(null);

  const [oldPassport, setOldPassport] = useState(null);

  const [passportUrl1, setPassportUrl1] = useState(passportFiles?.passportFile1?.url || null);
  const [passportUrl2, setPassportUrl2] = useState(passportFiles?.passportFile2?.url || null);
  const [passportUrl3, setPassportUrl3] = useState(passportFiles?.passportFile3?.url || null);

  const [identCode, setIdentCode] = useState(null);
  const [identCodeUrl, setIdentCodeUrl] = useState(passportFiles?.identCode?.url || null);

  const [driveLicense, setDriveLicense] = useState(null);
  const [driveLicenseUrl, setDriveLicenseUrl] = useState(passportFiles?.driveLicense?.url || null);
  const [driveLicenseSecond, setDriveLicenseSecond] = useState(null);
  const [driveLicenseSecondUrl, setDriveLicenseSecondUrl] = useState(passportFiles?.driveLicenseSecond?.url || null);

  // const regex = /^[А-Я]{2}$/i;

  useEffect(() => {
    const pass = /[а-яА-Я]/.test(answers.passportID);
    if (pass) {
      setOldPassport(true);
    } else {
      setOldPassport(false);
    }
  }, [answers.passportID]);

  const nextStep = () => {
    if (!identCodeUrl || !driveLicenseUrl || !driveLicenseSecondUrl) {
      return toast.error('Прикріпіть усі фото');
    }

    if ((oldPassport && (!passportUrl1 || !passportUrl2 || !passportUrl3))
      || (!oldPassport && (!passportUrl1 || !passportUrl2))) {
      return toast.error('Прикріпіть усі фото');
    }

    setStep(5);
  };

  return (
    <>
      <div className="wrapper-step">
        <div className="itemStep4">
          <div className="itemStep4__title">
            Щоб мати доступ до наших послуг - необхідно верифікувати введені дані. Зробити це можна за допомогою прикріплення фото/сканів нижчезазначених документів.
          </div>
          <div className="itemStep4__files">
            <div className="itemStep4__files__wrapper">
              {oldPassport
                ? (
                  <>
                    <FileInput dataUser="passportFile1" file={passportFile1} setFile={setPassportFile1} previewUrl={passportUrl1} setPreviewUrl={setPassportUrl1} setPassportFiles={setPassportFiles} text="Паспорт 1 стр." />
                    <FileInput dataUser="passportFile2" file={passportFile2} setFile={setPassportFile2} previewUrl={passportUrl2} setPreviewUrl={setPassportUrl2} setPassportFiles={setPassportFiles} text="Паспорт 2 стр." />
                    <FileInput dataUser="passportFile3" file={passportFile3} setFile={setPassportFile3} previewUrl={passportUrl3} setPreviewUrl={setPassportUrl3} setPassportFiles={setPassportFiles} text="Паспорт 11 стр." />
                  </>
                )
                : (
                  <>
                    <FileInput dataUser="passportFile1" file={passportFile1} setFile={setPassportFile1} previewUrl={passportUrl1} setPreviewUrl={setPassportUrl1} setPassportFiles={setPassportFiles} text="ID картка лицева сторона" />
                    <FileInput dataUser="passportFile2" file={passportFile2} setFile={setPassportFile2} previewUrl={passportUrl2} setPreviewUrl={setPassportUrl2} setPassportFiles={setPassportFiles} text="ID картка тильна сторона" />
                  </>
                )}
              <FileInput dataUser="identCode" file={identCode} setFile={setIdentCode} previewUrl={identCodeUrl} setPreviewUrl={setIdentCodeUrl} setPassportFiles={setPassportFiles} text="Ідентифікаційний код" />
              <FileInput dataUser="driveLicense" file={driveLicense} setFile={setDriveLicense} previewUrl={driveLicenseUrl} setPreviewUrl={setDriveLicenseUrl} setPassportFiles={setPassportFiles} text="Водійське посвідчення лицева" />
              <FileInput dataUser="driveLicenseSecond" file={driveLicenseSecond} setFile={setDriveLicenseSecond} previewUrl={driveLicenseSecondUrl} setPreviewUrl={setDriveLicenseSecondUrl} setPassportFiles={setPassportFiles} text="Водійське посвідчення тильна" />
            </div>
          </div>
        </div>
        <div className="buttons-step">
          <Button className="black" onClick={nextStep}>Зберегти дані</Button>
        </div>
        <div className="footer-step">
          <Button onClick={() => setStep(null)} className="clear">Відмінити</Button>
        </div>
      </div>
    </>
  );
}

export default ItemStep4;
