import React from 'react';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import { Provider } from 'react-redux';
import Footer from './components/Footer';
import Header from './components/Header';
import MegaMenu from './components/MegaMenu';
import config from '../config';
import Router from './router';
import setupStore from './store';

axios.defaults.baseURL = config.apiUrl;
axios.defaults.withCredentials = true;
axios.defaults.headers['x-ulex-static'] = 'client';

const store = setupStore();

function App() {
  return (
    <Provider store={store}>
      <div className="wrapper-app-ulex">
        <Header />
        <div className="wrapper-over-content-ulex">
          <MegaMenu />
        </div>
        <div className="wrapper-content-ulex">
          <Router />
          <Footer />
        </div>
      </div>
      <ToastContainer
        className="notification"
        position="bottom-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Provider>
  );
}

export default App;
