import React from 'react';
import './style.scss';
import { Link } from 'react-router-dom';

function Button({ className, ...props }) {
  if (props.link) {
    return (
      <a href={props.link} className={`app-btn ${className ?? ''}`} {...props}>
        {props.children || props.value}
      </a>
    );
  }
  if (props.linkTo) {
    return (
      <Link to={props.linkTo} href={props.link} className={`app-btn ${className ?? ''}`} {...props}>
        {props.children || props.value}
      </Link>
    );
  }
  return (
    <button type="button" className={`app-btn ${className ?? ''}`} {...props}>
      {props.children || props.value}
    </button>
  );
}

export default Button;
