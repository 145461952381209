import React from 'react';
import './style.scss';
import visa from 'assets/visa.png';
import mastercard from 'assets/mastercard.png';
import mtb from 'assets/mtb.png';
import config from '../../../config';

function Footer() {
  return (
    <div className="wrapper-footer-ulex">
      <div className="content-firstBlockFooter-ulex">
        <div className="title-footer">ТОВ «Улєкс» 2023. Всі права захищені.</div>
      </div>
      <div className="content-lastBlockFooter-ulex">
        <div className="iconWrapper-footer">
          <img src={visa} alt="visa" />
          <img src={mastercard} alt="mastercard" />
          <img src={mtb} alt="mtb" />
        </div>
        <div className="description-footer">
          <a target="_blank" rel="noreferrer" href={`${config.apiUrl}/public/pages/info_service.html`}>
            Iнформацiя про сервiс
          </a>
          <a target="_blank" rel="noreferrer" href={`${config.apiUrl}/public/pages/terms_admin.html`}>
            Умови використання
          </a>
          <a target="_blank" rel="noreferrer" href={`${config.apiUrl}/public/pages/politics.html`}>
            Політика Конфіденційності
          </a>
        </div>
      </div>
    </div>
  );
}
export default Footer;
