import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import './style.scss';
import 'swiper/css';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import arrow from 'assets/arrow.svg';
import user from 'assets/user.svg';
import { ReactComponent as Edit } from 'assets/edit.svg';
import { ReactComponent as Delete } from 'assets/icon-trash.svg';
import { ReactComponent as Arrow } from 'assets/arrow-stick.svg';
import { ReactComponent as Plus } from 'assets/plus.svg';
import carSvg from 'assets/car.svg';
import { setSection } from 'app/store/modules/Common';
import { toast } from 'react-toastify';
import { deleteCar, setCar, useProfile } from '../../store/modules/Profile';
import SuccessModal from '../../components/SuccessModal';
import ModalWrapper from '../../components/ModalWrapper';

function MyCars() {
  const { profile } = useProfile();
  const dispatch = useDispatch();
  const [chosenCar, setChosenCar] = useState(null);

  const updateSection = (s) => {
    dispatch(setSection(s));
  };

  const handleSelectCar = (car) => () => {
    dispatch(setCar(car));
    updateSection('addcar');
  };

  const handleDeleteCar = () => {
    toast
      .promise(dispatch(deleteCar({ idUser: profile._id.toString(), idCar: chosenCar._id })), {
        pending: 'Видалення автомобіля',
      })
      .then((res) => {
        if (!res.error) {
          setChosenCar(null);
          toast.success('Успішно');
        } else {
          toast.error('Помилка ');
        }
      });
  };

  return (
    <>
      <ModalWrapper isOpened={chosenCar} onClose={() => setChosenCar(null)}>
        {() => <SuccessModal handleDeleteCar={handleDeleteCar} deleteCar={chosenCar} onClose={() => setChosenCar(null)} type="carDelete" />}
      </ModalWrapper>
      <div className="wrapper-my-cars-ulex">
        <div className="content-my-cars-ulex">
          <div className="location-site-my-cars">
            <span>Головна</span> <img src={arrow} alt="" /> Мої автомобілі
          </div>
          <div className="container-head-my-cars">
            <div className="item-my-cars-head first">
              <div className="item-backmain-title-my-cars">
                <Link to="/" className="btn-backmain-item-backmain-title-my-cars"><Arrow /> Назад</Link>
                <div className="separator-item-backmain-title-my-cars" />
                <div className="title-item-backmain-title-my-cars">Мої автомобілі</div>
              </div>
            </div>
            <div className="item-my-cars-head second">
              <div onClick={() => updateSection('addcar')} className="item-container-add-car">
                <Plus /><span>Додати автомобіль</span>
              </div>
            </div>
          </div>
          <div className="container-swiper-my-cars">
            <Swiper
              className="swiper-my-cars"
              spaceBetween={24}
              slidesPerView="auto"
            >
              {profile?.cars?.length ? profile.cars.map((car) => {
                return (
                  <SwiperSlide className="item-slide-my-cars" key={car._id}>
                    <div className="car-number-item-slide-my-cars">{car.number}</div>
                    <div onClick={() => setChosenCar(car)} className="delete-number-item-slide-my-cars"><Delete /></div>
                    <img src={carSvg} alt="" />
                    <div className="car-name-item-slide-my-cars">{car.brand} {car.model}</div>
                    <div className="owner-item-slide-my-cars">
                      <img src={user} alt="" />
                      <span>Ви {car.owner ? '' : 'не '}є власником</span>
                    </div>
                    <div className="separator-item-slider-my-cars" />
                    <div className="btn-item-slider-my-cars" onClick={handleSelectCar(car)}><Edit /> <span>Редагувати дані</span></div>
                  </SwiperSlide>
                );
              }) : null}
            </Swiper>
          </div>

        </div>
      </div>
    </>
  );
}
export default MyCars;
