import { createAsyncThunk } from '@reduxjs/toolkit';
import CaseService from 'app/services/case.service';

const demoData = [
  {
    _id: '33adc6123f5e223e1f87ec9c',
    type: 'questionnaire',
    title: 'Опитування',
    description: 'Пройдіть спеціальне опитування, яке допоможе нам визначити основні деталі вчиненого правопорушення.',
    button: 'Почати',
    questions: [
      {
        _id: '33adc6123f5e223e1f87ec91',
        question: 'Чи є Ви власником транспорту?',
        options: ['Так', 'Ні'],
      },
      // {
      //   _id: '33adc6123f5e223e1f87ec92',
      //   question: 'Чи було порушення вчинено Вами?',
      //   options: ['Так', 'Ні'],
      // },
    ],
  },
  {
    _id: '23adc6123f5e223e1f87ec9c',
    type: 'options',
    title: 'Вид порушення',
    info: 'текст',
    description: 'Виберіть один із зазначених видів порушення, яке Ви скоїли.',
    options15_9: ['15.9', '15.10'],
    options15_10: ['a', 'б', 'в', 'г', 'д', 'е', 'э', 'ж', 'з', 'и'],
  },
  {
    _id: '43adc6123f5e223e1f87ec9c',
    type: 'auth',
    title: 'Авторизація',
    description: 'Ми помітили, що Ви не авторизовані. Для подальшого продовження створення заявки - пройдіть авторизацію.',
    button: 'Пройти авторизацію',
  },
  {
    _id: '53adc6123f5e223e1f87ec9c',
    type: 'decree',
    title: 'Постанова',
    description: 'Вкажіть деталі постанови.',
    button: 'Наступний крок',
  },
  {
    _id: '53adc6123f5e223e1f87ec9a',
    type: 'hearing',
    title: 'Місце розгляду справи',
    description: '',
    button: 'Наступний крок',
  },
  {
    _id: '63adc6123f5e223e1f87ec9c',
    type: 'delivery',
    title: 'Деталі вручення',
    description: '',
    button: 'Наступний крок',
  },
  {
    _id: '64a4b8ca1711b81bc5d66888',
    type: 'inquirer',
    title: 'Опитування',
    description: '',
    button: 'Наступний крок',
  },
  {
    _id: '73adc6123f5e223e1f87ec9c',
    type: 'vehicle',
    title: 'Транспортний засіб',
    description: 'Вкажіть автомобіль, яким Ви керували.',
    button: 'Наступний крок',
  },
  {
    _id: '73adc6123f7e223e1f87ec9c',
    type: 'circumstances',
    title: 'Вкажiть обставини порушення',
    description: 'будь ласка, вкажіть усі дані, вказані в пункті 6.',
    button: 'Наступний крок',
  },
  {
    _id: '73adc6123f7e223e1f80ec9c',
    type: 'partArticle',
    title: 'Вкажіть частину статті',
    description: 'Має бути зазначено у 7 пункті постанови',
    button: 'Наступний крок',
  },
  {
    _id: '73adc6123e7e223e1f80ec9c',
    type: 'fineAmount',
    title: 'Сума штрафу',
    description: '',
    button: 'Наступний крок',
  },
  {
    _id: '71adc6123e7e223e1f80ec9c',
    type: 'meansFixation',
    title: 'Внесення даних',
    description: '',
    button: 'Наступний крок',
  },
  {
    _id: '83adc6123f5e223e1f87ec9c',
    type: 'payment',
    title: 'Оплата',
    description: 'Послуги, які надає Ulex не є безкоштовними. Тому, тільки сплативши, Вашу заяву розглянуть.',
    button: 'Оплатити',
    cost: '500',
  },
  {
    _id: '93adc6123f5e223e1f87ec9c',
    type: 'signing',
    title: 'Підписання',
    description: 'Ваша заява відправляється у вигляді документу, який повинен бути завірений Вашим підписом.',
    button: 'Підписати через Дію',
  },
];

export const createCase = createAsyncThunk('case/createCase', async (body, thunkAPI) => {
  try {
    return await CaseService.createCase(body);
  } catch (error) {
    if (error.response.status === 400) {
      return thunkAPI.rejectWithValue('Помилка');
    }
    return thunkAPI.rejectWithValue('Error');
  }
});

export const updateCase = createAsyncThunk('case/updateCase', async (body, thunkAPI) => {
  try {
    return await CaseService.updateCase(body);
  } catch (error) {
    if (error.response.status === 400) {
      return thunkAPI.rejectWithValue('Помилка');
    }
    return thunkAPI.rejectWithValue('Error');
  }
});

export const getAllCase = createAsyncThunk('case/getAllCase', async (thunkAPI) => {
  try {
    return await CaseService.getAllCase();
  } catch (error) {
    if (error.response.status === 400) {
      return thunkAPI.rejectWithValue('Помилка');
    }
    return thunkAPI.rejectWithValue('Error');
  }
});

export const getCase = createAsyncThunk('case/getCase', async (id, thunkAPI) => {
  try {
    return await CaseService.getCase(id);
  } catch (error) {
    if (error.response.status === 400) {
      return thunkAPI.rejectWithValue('Помилка');
    }
    return thunkAPI.rejectWithValue('Error');
  }
});

export const getQuestion = createAsyncThunk('case/getQuestion', async (_, thunkAPI) => {
  try {
    return await new Promise((resolve) => setTimeout(() => {
      resolve(demoData);
    }, 1000));
  } catch (error) {
    if (error.response.status === 400) {
      return thunkAPI.rejectWithValue('Помилка');
    }
    return thunkAPI.rejectWithValue('Error');
  }
});
