import React, { useEffect, useState } from 'react';
import './style.scss';
import { toast } from 'react-toastify';
import { useMoreData } from '../../context';
import Button from '../../../../components/ui/Button';

function ItemStep1() {
  const {
    answers, setAnswers, setStep,
  } = useMoreData();
  const [firstNameInput, setFirstNameInput] = useState('');
  const [lastNameInput, setLastNameInput] = useState('');
  const [middleNameInput, setMiddleNameInput] = useState('');

  useEffect(() => {
    setFirstNameInput(answers.firstName);
    setLastNameInput(answers.lastName);
    setMiddleNameInput(answers.middleName);
  }, []);

  const updateName = () => {
    if (firstNameInput.length) {
      setAnswers({ ...answers, firstName: firstNameInput });
    }
  };

  const updateLastName = () => {
    if (lastNameInput.length) {
      setAnswers({ ...answers, lastName: lastNameInput });
    }
  };

  const updateMiddleName = () => {
    if (middleNameInput.length) {
      setAnswers({ ...answers, middleName: middleNameInput });
    }
  };

  const nextStep = () => {
    if (firstNameInput.length && lastNameInput.length && middleNameInput) {
      setStep(2);
    } else {
      toast.error('Заповніть всі поля');
    }
  };

  return (
    <>
      <div className="wrapper-step">
        <div className="items-input-step">
          <div className="items-input-step__blockInputs">
            <div className="item-input-step">
              Ім’я:
              <input onBlur={updateName} onChange={(e) => setFirstNameInput(e.target.value)} value={firstNameInput} type="text" placeholder="Iван" />
            </div>
            <div className="item-input-step">
              Прізвище:
              <input onBlur={updateLastName} onChange={(e) => setLastNameInput(e.target.value)} value={lastNameInput} type="text" placeholder="Iванов" />
            </div>
          </div>
          <div className="item-input-step">
            По батькові:
            <input onBlur={updateMiddleName} onChange={(e) => setMiddleNameInput(e.target.value)} value={middleNameInput} type="text" placeholder="Iванович" />
          </div>
        </div>
        <Button className="black" onClick={nextStep}>Наступний крок</Button>
        <div className="footer-step">
          <Button onClick={() => setStep(null)} className="clear">Відмінити</Button>
        </div>
      </div>
    </>
  );
}
export default ItemStep1;
