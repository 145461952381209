import React, { useState } from 'react';
import './style.scss';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Edit } from '../../../../../assets/edit.svg';
import { setAnswers, useCase } from '../../../../store/modules/Case';
import ModalWrapper from '../../../../components/ModalWrapper';
import WarningModal from '../../../../components/WarningModal';

function ItemTypeQuestionnaire({ item, index }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentCase } = useCase();
  const [update, setUpdate] = useState(false);
  const [checkBox, setCheckBox] = useState(null);
  const [allAnswers, setAllAnswers] = useState([]);
  const answer = currentCase?.answers?.find((el) => el.question === item._id);

  const [openWarningModal, setOpenWarningModal] = useState(false);

  const handleOpenWarningModal = (isOpen) => {
    if (!isOpen) {
      navigate('/');
    }
    setOpenWarningModal(isOpen);
  };

  const handleCheckBox = (el, option, key) => {
    setCheckBox(key);
    const object = { question: el._id, value: option };
    const haveAnswer = allAnswers?.find((j) => j.question === el._id);

    if (haveAnswer) {
      setAllAnswers(() => {
        return [
          ...allAnswers.map((i) => {
            if (i.question === el._id) {
              return {
                ...i,
                question: el._id,
                value: option,
              };
            }
            return i;
          }),
        ];
      });
    } else {
      setAllAnswers((prev) => {
        return [
          ...prev,
          object,
        ];
      });
    }
  };

  const handleSelect = () => {
    setUpdate(false);
    if (answer) {
      dispatch(setAnswers({ question: item._id, value: allAnswers, type: 'questionnaire' }));
    } else {
      dispatch(setAnswers({
        question: item._id, value: allAnswers, type: 'questionnaire', new: true,
      }));
    }
  };

  const [step, setStep] = useState(0);

  const next = () => {
    if (checkBox === null && step >= 0) {
      toast.error('Виберіть один із варіантів');
      return;
    }
    if (step >= item.questions.length - 1) {
      if (allAnswers[0].value === 'Ні') {
        handleOpenWarningModal(true);
        return;
      }
      setCheckBox(null);
      handleSelect();
      return;
    }
    setCheckBox(null);
    setStep((prev) => prev + 1);
  };
  // const prev = () => {
  //   if (step <= -1) return;
  //   setCheckBox(null);
  //   setStep((prevState) => prevState - 1);
  // };

  return (
    <>
      {!answer || update
        ? (
          <>{index > currentCase?.answers?.length
            ? (
              <div className="content-item-step-inactive">
                <div className="title-item-step-inactive"><div>{item.title}</div></div>
                <div className="description-item-step-inactive">Не пройдено</div>
              </div>
            )
            : (
              <div className="content-item-type-questionnaire">
                <div className="bang-item-type-questionnaire" />
                <div className="title-item-type-questionnaire">{item.title}</div>
                {step === -1 && (
                  <>
                    <div className="description-item-type-questionnaire">{item.description}</div>
                    <div className="btn-item-type-questionnaire" onClick={next}>{item.button}</div>
                  </>
                ) }
                <div>
                  {item.questions.map((el, idx) => {
                    if (idx >= 0 && idx === step) {
                      return (
                        <div key={JSON.stringify(el)}>
                          {/*<div className="number-question-item-type-questionnaire">Питання <span>{index + 1}</span></div>*/}
                          <div className="item-question-item-type-questionnaire"> {el.question}</div>
                          <div className="container-radio-item-type-questionnaire">
                            {el.options.map((option, key) => (
                              <>
                                <label key={key} className="item-radio-item-type-questionnaire"> <span className="text-item-radio-item-type-questionnaire">{option}</span>
                                  <input checked={checkBox === key} onChange={() => handleCheckBox(el, option, key)} type="radio" name="answer" />
                                  <span className="checkmark-item-radio-item-type-questionnaire" />
                                </label>
                              </>
                            ))}
                          </div>
                          <div className="container-switching-item-type-questionnaire">
                            {/*<div className="btn-switching-item-type-questionnaire prev" onClick={() => prev(el)}>Назад</div>*/}
                            <div className="btn-switching-item-type-questionnaire next" onClick={() => next(el)}>Далі</div>
                          </div>

                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              </div>
            )}
          </>
        )
        : (
          <>
            <div className="content-item-step-inactive">
              <div className="title-item-step-inactive"><div>{item.title}</div>{answer ? <span onClick={() => setUpdate(true)}><Edit /></span> : null}</div>
              <div className="description-item-step-inactive">Пройдено</div>
            </div>
          </>
        )}
      <ModalWrapper
        isOpened={openWarningModal}
        onClose={() => handleOpenWarningModal(false)}
      >
        {(onClose) => <WarningModal onClose={onClose} />}
      </ModalWrapper>
    </>

  );
}
export default ItemTypeQuestionnaire;
