import React from 'react';
import './style.scss';
import animation from '../../../assets/icon-loading.svg';
import animationWhite from '../../../assets/icon-loadingWhite.svg';

export function Spinner() {
  return (
    <div className="lds-ring">
      <div />
      <div />
      <div />
      <div />
    </div>
  );
}

export function SpinnerNew() {
  return (
    <span className="loaderNew" />
  );
}

function Loading({ white = false }) {
  return (
    <div className="spinner-wrapper">
      <img src={white ? animationWhite : animation} alt="spinner" />
    </div>
  );
}

export default Loading;
