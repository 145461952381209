import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  getQuestion,
  createCase,
  updateCase,
  getAllCase,
  getCase,
} from './actions';

const initialState = {
  currentCase: null,
  cases: [],
  questions: [],
  isLoading: false,
  error: '',
};

export const caseModule = createSlice({
  name: 'case',
  initialState,
  reducers: {
    setAnswers: (state, action) => {
      // { questions: item._id, value: el, new: true }
      // state.currentCase = action.payload;
      const { answers } = state.currentCase;

      if (action.payload.new) {
        if (action.payload.type === 'questionnaire') {
          state.currentCase = {
            ...state.currentCase,
            answers: [...answers, { question: action.payload.question, questions: action.payload.value }],
          };
        } else {
          state.currentCase = {
            ...state.currentCase,
            answers: [...answers, { question: action.payload.question, value: action.payload.value }],
          };
        }
      } else if (action.payload.type === 'questionnaire') {
        state.currentCase = {
          ...state.currentCase,
          answers: answers.map((item) => {
            if (item.question === action.payload.question) {
              item.question = action.payload.question;
              item.questions = action.payload.value;
            }
            return item;
          }),
        };
      } else {
        state.currentCase = {
          ...state.currentCase,
          answers: answers.map((item) => {
            if (item.question === action.payload.question) {
              item.question = action.payload.question;
              item.value = action.payload.value;
            }
            return item;
          }),
        };
      }
    },
  },
  extraReducers: {
    // createCase
    [createCase.fulfilled.type]: (state, action) => {
      state.currentCase = action.payload;
      state.isLoading = false;
    },
    [createCase.pending.type]: (state) => {
      state.isLoading = true;
    },
    [createCase.rejected.type]: (state) => {
      state.isLoading = false;
    },

    // updateCase
    [updateCase.fulfilled.type]: (state) => {
      // state.currentCase = action.payload;
      state.isLoading = false;
    },
    [updateCase.pending.type]: () => {
      // state.isLoading = true;
    },
    [updateCase.rejected.type]: (state) => {
      state.isLoading = false;
    },

    // getAllCase
    [getAllCase.fulfilled.type]: (state, action) => {
      state.cases = action.payload;
      state.isLoading = false;
    },
    [getAllCase.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getAllCase.rejected.type]: (state, action) => {
      state.isLoading = false;
      toast.error(action.payload);
    },

    // getCase
    [getCase.fulfilled.type]: (state, action) => {
      state.currentCase = action.payload;
      state.isLoading = false;
    },
    [getCase.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getCase.rejected.type]: (state, action) => {
      state.isLoading = false;
      toast.error(action.payload);
    },

    // getQuestion
    [getQuestion.fulfilled.type]: (state, action) => {
      state.questions = action.payload;
      state.isLoading = false;
    },
    [getQuestion.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getQuestion.rejected.type]: (state, action) => {
      state.isLoading = false;
      toast.error(action.payload);
    },
  },
});

export {
  createCase,
  updateCase,
  getAllCase,
  getCase,
  getQuestion,
};

export default caseModule.reducer;

export const { setAnswers } = caseModule.actions;

export const useCase = () => useSelector((state) => state.case);
