import React, { useState } from 'react';
// import InputMask from 'react-input-mask';
import './style.scss';
import { toast } from 'react-toastify';

import { useDispatch } from 'react-redux';
import login from 'assets/login.svg';
// import { ReactComponent as Diya } from 'assets/diya.svg';
// import { ReactComponent as Apple } from 'assets/apple.svg';
// import { ReactComponent as Email } from 'assets/email.svg';
import { ReactComponent as Gmail } from 'assets/gmail.svg';

import config from 'config';
import { validateEmail } from 'app/utils/validation';
import Loading from 'app/components/Loading/index';
import { setSection } from 'app/store/modules/Common';
import { loginByEmail, useProfile } from 'app/store/modules/Profile';
import Button from 'app/components/ui/Button';
import DiyaQR from '../../../DiyaQR';
import ModalWrapper from '../../../ModalWrapper';

function Authorization() {
  const dispatch = useDispatch();
  const { isLoading } = useProfile();

  const [step, setStep] = useState(0);
  // const [type, setType] = useState('number');

  const [loginInput, setLoginInput] = useState('');
  const [passwordInput, setPasswordInput] = useState('');
  // const [phoneInput, setPhoneInput] = useState('');
  // const [codeInput, setCodeInput] = useState('');

  const [openDiya, setOpenDiya] = useState(false);

  const updateSection = (s) => {
    dispatch(setSection(s));
  };

  const handleLoginByEmail = async () => {
    if (!validateEmail(loginInput) || !passwordInput.length) {
      return toast.error('Перевірте правильність написання email чи пароля');
    }

    dispatch(
      loginByEmail({
        email: loginInput.toLowerCase(),
        password: passwordInput,
      }),
    );
  };

  // const handleSubmitPhone = async () => {
  //   if (step) {
  //     if (!codeInput.length || codeInput.length < 6) {
  //       toast.error('Код повинен мати 6 цифр');
  //     }

  //     dispatch(verifiedCode({
  //       code: codeInput,
  //     }));
  //   } else {
  //     const checkPhoneInput = phoneInput.replace(/\D/g, '').replace(/^7/, '');

  //     if (checkPhoneInput.length < 12) {
  //       toast.error('Невірно набраний номер');
  //     }

  //     await dispatch(loginByPhone({
  //       phone: checkPhoneInput,
  //     })).then((state) => {
  //       if (!state.error) {
  //         setStep(1);
  //       }
  //     });
  //   }
  // };

  return (
    <>
      <ModalWrapper isOpened={openDiya} onClose={() => setOpenDiya(false)}>
        {(onClose) => <DiyaQR onClose={onClose} />}
      </ModalWrapper>
      <div className="wrapper-authorization-ulex">
        {isLoading ? (
          <div className="loading">
            <Loading white />
          </div>
        ) : null}
        <div className="content-authorization-ulex">
          <div className="container-authorization first">
            <div className="item-authorization-icon">
              <img src={login} alt="" />
            </div>
            <div className="item-authorization-title">
              <span>Вхід</span> в кабінет
            </div>
            <div className="item-authorization-info">
              Щоб увійти в особистий кабінет - введіть Ваш номер телефону та пароль.
            </div>
            <div className="item-authorization-link-registration">
              Новий користувач?{' '}
              <span onClick={() => updateSection('registration')}>Зареєструватися</span>
            </div>
          </div>
          <div className="container-authorization second">
            <form className="form-authorization">
              {/* {type === 'number' ? (
                <>
                  <div className="item-authorization">
                    <label>Номер телефону:</label>
                    <InputMask
                      mask="+380 (99) 999 9999"
                      value={phoneInput}
                      onChange={(e) => setPhoneInput(e.target.value)}
                      placeholder="+380 (00) 000 0000"
                    />
                  </div>

                  {step === 1 ? (
                    <div className="item-authorization-password">
                      <label>Пароль:</label>
                      <input
                        type="password"
                        onChange={(e) => (e.target.value.length < 7 ? setCodeInput(e.target.value) : null)}
                        value={codeInput}
                        placeholder="000000"
                      />
                    </div>
                  ) : null}

                  <div className="btn-form-authorization" onClick={handleSubmitPhone}>{step ? 'Авторизуватися' : 'Вперед'}</div>
                </>
              ) : null} */}

              <>
                <div className="item-authorization">
                  <label>Електронна пошта:</label>
                  <input
                    type="text"
                    onChange={(e) => {
                      setLoginInput(e.target.value);
                    }}
                    value={loginInput}
                    placeholder="ulex@example.ua"
                  />
                </div>
                <div className="item-authorization-password">
                  <label>Пароль:</label>
                  <input
                    type="password"
                    onChange={(e) => {
                      setPasswordInput(e.target.value);
                    }}
                    value={passwordInput}
                    placeholder="********"
                  />
                </div>
                <div className="btn-form-authorization" onClick={handleLoginByEmail}>
                  Авторизуватися
                </div>
              </>
            </form>
            {!step ? (
              <>
                <div className="item-authorization-link-registration-mobile">
                  Новий користувач?{' '}
                  <span onClick={() => updateSection('registration')}>Зареєструватися</span>
                </div>
                <div className="item-or-authorization">
                  <div className="title-or-authorization">Інші способи авторизації</div>
                  <div className="line-or-authorization" />
                </div>
                <div className="item-other-options-authorization">
                  {/* {type === 'number' ? (
                    <Button
                      onClick={() => {
                        setType('email');
                        setLoginInput('');
                        setStep(0);
                      }}
                    >
                      <Email /> Увійти через пошту
                    </Button>
                  ) : null}
                  {type === 'email' ? (
                    <Button
                      onClick={() => {
                        setType('number');
                        setLoginInput('');
                        setStep(0);
                      }}
                    >
                      <Email /> Увійти за номером
                    </Button>
                  ) : null} */}
                  {/* <Button>
                    <Apple /> Увійти через Apple
                  </Button> */}
                  <Button link={`${config.apiUrl}/api/v1/auth/google`}>
                    <Gmail /> Увійти через Google
                  </Button>
                  {/* <Button onClick={() => setOpenDiya(true)} className="diya">
                    <Diya /> Увійти через Дія
                  </Button> */}
                </div>
              </>
            ) : null}
            {step === 1 ? (
              <div
                className="btn-back-start-authorization"
                onClick={() => {
                  setStep(0);
                }}
              >
                Повернутися назад
              </div>
            ) : null}
          </div>
        </div>
        {/* <div className='btn-close-authorization' onClick={() => {setAuthorization(false)}} >Закрити</div> */}
      </div>
    </>
  );
}
export default Authorization;
