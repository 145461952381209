import React from 'react';
import { Link } from 'react-router-dom';
import './style.scss';
import { useDispatch } from 'react-redux';
import { setSection, useCommon } from 'app/store/modules/Common';
import { useProfile } from 'app/store/modules/Profile';

import { ReactComponent as MenuSVG } from 'assets/menu.svg';
import { ReactComponent as StatementsSVG } from 'assets/statements.svg';
// import { ReactComponent as SearchSVG } from 'assets/search.svg';

import login from 'assets/login.svg';
import diya from 'assets/diya.svg';
import ulex from 'assets/ulex.svg';
import config from '../../../config';

function Header() {
  const dispatch = useDispatch();
  const { profile } = useProfile();
  const { section } = useCommon();

  const updateSection = (s) => {
    dispatch(setSection(s));
  };

  return (
    <div className="wrapper-header-ulex">
      <div className="content-header-ulex">
        <div className="container-header">
          <div className="item-header-logos">
            <img src={ulex} alt="" />
            <div className="logo-separator">на технологіях</div>
            <img src={diya} alt="" />
          </div>
          <Link to="/" className="item-header-nav" onClick={() => updateSection('')}>
            Головна сторінка
          </Link>
          <Link to="/wiki" className="item-header-nav" onClick={() => updateSection('')}>
            Ulex Wiki
          </Link>
        </div>
        <div className="container-header">
          {/* <div className="item-header-search">
            <SearchSVG onClick={() => updateSection('search')} />
            {section === 'search' ? <div className="active-item-header" /> : null}
          </div> */}

          <div className="item-header-menu-mobile">
            <MenuSVG onClick={() => updateSection('menu')} />
            {section === 'menu' ? <div className="active-item-header" /> : null}
          </div>
          <div className="item-header-user-login">
            {profile ? (
              <div className="user-is-authorized">
                <Link
                  to="/my-statements"
                  className="item-header-my-statements"
                  onClick={() => updateSection('')}
                >
                  <div className="title-my-statements">Мої заяви</div>
                  <StatementsSVG />
                </Link>
                <div className="user-container-header" onClick={() => updateSection('profile')}>
                  <div className="welcone-user-is-authorized">
                    <div className="welcome-user-header">Доброго дня!</div>
                    <div
                      title={
                        profile?.data?.firstName && profile?.data?.lastName
                          ? `${profile?.data?.firstName} ${profile?.data?.lastName}`
                          : "Iм'я Прізвище"
                      }
                      className="name-user-header"
                    >
                      {profile?.data?.firstName && profile?.data?.lastName
                        ? `${profile?.data?.firstName} ${profile?.data?.lastName}`
                        : "Iм'я Прізвище"}
                    </div>
                  </div>
                  <div className="avatar-user-is-authorized">
                    {profile?.image ? <img src={profile?.image.includes('googleusercontent') ? profile?.image : `${config.apiUrl}/${profile?.image}`} alt="" /> : null}
                  </div>
                  {section === 'profile' ? <div className="active-item-header" /> : null}
                </div>
              </div>
            ) : (
              <>
                <div
                  className="user-is-not-authorized-mobile"
                  onClick={() => updateSection('authorization')}
                >
                  <img src={login} alt="" />
                  {section === 'authorization' ? <div className="active-item-header" /> : null}
                </div>
                <div className="user-is-not-authorized">
                  <div
                    className="item-user-is-not-authorized-1"
                    onClick={() => updateSection('authorization')}
                  >
                    Вхід в кабінет
                  </div>
                  <div
                    className="item-user-is-not-authorized-2"
                    onClick={() => updateSection('registration')}
                  >
                    Реєстрація
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default Header;
