import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { setPassword, useProfile } from '../../../../store/modules/Profile';
import Button from '../../../ui/Button';

function ChangePassword({ onClose }) {
  const dispatch = useDispatch();
  const { profile } = useProfile();
  const [values, setValues] = useState({
    oldPassword: '',
    password: '',
    retypePassword: '',
  });
  const [errors, setErrors] = useState({});
  const isValid = !Object.keys(errors).length;

  useEffect(() => {
    const result = {};

    if (values.password !== values.retypePassword) {
      result.retypePassword = 'Пароль не співпадає!';
    }

    if (values.password && values.password.length < 6) {
      result.password = 'Пароль повинен мати не менше 6 символів!';
    }

    setErrors(result);
  }, [values]);

  const handleChangeInput = (e) => setValues({ ...values, [e.target.name]: e.target.value });

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(setPassword(values)).then((res) => {
      if (!res.error) {
        onClose();
        toast.success('Успішно');
      } else {
        toast.error(`Помилка - ${res.payload}`);
      }
    });
  };

  return (
    <div className="change-password-modal">
      <form onSubmit={handleSubmit} className="change-password-modal__form">
        {profile.password ? (
          <div className="change-password-modal__form-item">
            <input placeholder="Старий пароль" required type="password" name="oldPassword" onChange={handleChangeInput} />
          </div>
        ) : null}
        <div className="change-password-modal__form-item">
          <input placeholder="Новий пароль" required type="password" name="password" onChange={handleChangeInput} />
          <small>{errors.password ? errors.password : ''}</small>
        </div>
        <div className="change-password-modal__form-item">
          <input placeholder="Повторіть пароль" required type="password" name="retypePassword" onChange={handleChangeInput} />
          <small>{errors.retypePassword ? errors.retypePassword : ''}</small>
        </div>
        <Button type="submit" disabled={!isValid}>Зберегти</Button>
      </form>
      <div className="change-password-modal__footer">
        <Button onClick={onClose} className="clear">Відмінити</Button>
      </div>
    </div>
  );
}

export default ChangePassword;
