import React, { useEffect, useState } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { createCase } from 'app/store/modules/Case/actions';
import moment from 'moment';
import arrow from '../../../assets/arrow.svg';
import { ReactComponent as Arrow } from '../../../assets/arrow-stick.svg';
import { ReactComponent as Plus } from '../../../assets/plus.svg';
import svgbg from '../../../assets/not-assigned.svg';

import './style.scss';
import Button from '../../components/ui/Button';
import { useProfile } from '../../store/modules/Profile';
import { useCase } from '../../store/modules/Case';

const optionsSelect = [
  { value: 'dateDown', label: 'За датою(спадання)' },
  { value: 'dateUp', label: 'За датою(зростання)' },
];

export const tabsCase = [
  { label: 'Усі', filter: '', length: '' },
  { label: 'Чернетки', filter: 'created', length: '' },
  { label: 'На розгляді', filter: 'submitted', length: '' },
  { label: 'Прийнято', filter: 'archived', length: '' },
  { label: 'Повернуто', filter: 'returned', length: '' },
];

const filterCase = (cases, filter) => {
  const result = [...cases];

  if (filter === 'dateUp') {
    result.sort((a, b) => {
      return new Date(a.createdAt) - new Date(b.createdAt);
    });
  } else {
    result.sort((a, b) => {
      return new Date(b.createdAt) - new Date(a.createdAt);
    });
  }
  return result;
};

export const statusCase = {
  created: 'Чернетка',
  returned: 'Повернуто',
  archived: 'Прийнято',
  submitted: 'На розгляді',
};

function MyStatements() {
  const dispatch = useDispatch();
  const { profile } = useProfile();
  const navigate = useNavigate();
  const { cases, isLoading } = useCase();
  const [sortBy, setSortBy] = useState(optionsSelect[0].value);
  const [dataSort, setDataSort] = useState([]);
  const [allTabs, setAllTabs] = useState(tabsCase);
  const [filter, setFilter] = useState(tabsCase[0].filter);

  const colourStyles = {
    control: (styles) => {
      return {
        ...styles,
        width: '205px',
        height: '30px',

        cursor: 'pointer',
        boxShadow: 'none',
        color: '#FC6B58',
        border: '1px solid #ccc',
        ':hover': {
          ...styles[':hover'],
          border: '1px solid #ccc',
        },
      };
    },
    option: (styles) => {
      return {
        ...styles,
        color: 'black',
        cursor: 'pointer',
        backgroundColor: 'transparent !important',
        ':hover': {
          ...styles[':hover'],
          cursor: 'pointer',
          color: '#FC6B58',
          backgroundColor: 'none',
        },
        '.active': {
          ...styles['.active'],
          backgroundColor: 'white',
          color: '#FC6B58',
        },
      };
    },
    singleValue: (styles) => {
      return {
        ...styles,
        backgroundColor: 'transparent',
        ':hover': {
          ...styles[':hover'],
          color: '#FC6B58',
        },
      };
    },
  };

  const handleOpenCase = (caseId) => {
    navigate(caseId);
  };

  useEffect(() => {
    if (cases.length) {
      setDataSort(filterCase(cases, sortBy));
      const newTabs = tabsCase.map((item) => {
        return {
          ...item,
          length: cases.filter((el) => el.status.includes(item.filter)).length,
        };
      });
      setAllTabs(newTabs);
    }
  }, [cases, sortBy]);

  const handleChangeSort = (option) => {
    setSortBy(option.value);
  };

  const handleCreateCase = async () => {
    const startCase = {
      client: profile?._id,
      answers: [
        // {
        //   questionId: 'string',
        //   value: 'string',
        //   questions: [
        //     'string',
        //   ],
        // },
      ],
      status: 'created',
    };

    dispatch(createCase(startCase)).then((res) => {
      if (!res.error) {
        navigate(`${res.payload._id}`);
      } else {
        toast.error('Помилка ');
      }
    });
  };

  return (
    <div className="wrapper-my-statements-ulex">
      <div className="content-my-statements-ulex">
        <div className="location-site-my-statements">
          <Link to="/">Головна</Link><img src={arrow} alt="" /> Мої заяви
        </div>
        <div className="container-head-my-statements">
          <div className="item-my-statements-head first">
            <div className="item-backmain-title-my-statements">
              <Link to="/" className="btn-backmain-item-backmain-title-my-statements"><Arrow /> Назад</Link>
              <div className="separator-item-backmain-title-my-statements" />
              <div className="title-item-backmain-title-my-statements">Мої заяви</div>
            </div>
            <div className="item-filter-all-my-statements">
              {allTabs.map((tab, index) => (
                <div
                  key={index}
                  className={`item-filter-my-statements-declare ${filter === tab.filter ? 'active' : ''}`}
                  onClick={() => setFilter(tab.filter)}
                >
                  {tab.label} {tab.length}
                </div>
              ))}
            </div>
          </div>
          <div className="item-my-statements-head second">
            {/*<Link to="/creating-statement" className="item-newstatement-my-statements">*/}
            {/*  <Plus /><span>Нова заява</span>*/}
            {/*</Link>*/}
            <Button onClick={() => handleCreateCase()} className="clear width">
              <Plus />Нова заява
            </Button>
            <div className="item-sort-my-statements">
              <div className="title-item-sort-my-statements">Сортувати за</div>
              <div className="select-item-sort-my-statements">
                <Select
                  defaultValue={optionsSelect[0]}
                  options={optionsSelect}
                  onChange={handleChangeSort}
                  styles={colourStyles}
                  isSearchable={false}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container-all-my-statements">
          <div className="list-all-my-statements">
            {!isLoading && dataSort.length ? dataSort.filter((el) => el.status.includes(filter)).map((item, index) => (
              <div className="item-my-statements" key={index}>
                <div onClick={() => handleOpenCase(item._id)} className="wrapper-img-item-my-statements">
                  <div className="progress-status-item-my-statements">
                    {item.answers.length >= 10 || item.status !== 'created' ? null : (
                      <div className="progress-item-my-statements">
                        {item.answers.length ? (
                          <div style={{ width: 20, height: 20 }}>
                            <CircularProgressbar
                              value={item.answers.length / 11}
                              maxValue={1}
                              strokeWidth={20}
                              styles={buildStyles({
                                pathColor: '#FC6B58',
                                trailColor: 'transparent',
                                strokeLinecap: 'butt',
                              })}
                            />
                          </div>
                        ) : null}
                        <span>{item.answers.length * 11}%</span>
                      </div>
                    )}
                    <div className="status-item-my-statements">
                      {statusCase[item.status] || ''}
                    </div>
                  </div>
                  <img src={svgbg} alt="" />
                </div>
                <div className="title-item-my-statements">Порушення швидкісного режиму.</div>
                <div className="date-item-my-statements">{moment(item.createdAt).format('DD.MM.YYYY [о] HH:mm')}</div>
              </div>
            )) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
export default MyStatements;
