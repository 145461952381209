import { createAsyncThunk } from '@reduxjs/toolkit';
import UserService from 'app/services/user.service';
import { getAllCase } from '../Case';

export const initialization = createAsyncThunk('profile/initialization', async (_, thunkAPI) => {
  try {
    const dataUser = await UserService.initialization();
    await thunkAPI.dispatch(getAllCase());
    return dataUser;
  } catch (error) {
    return thunkAPI.rejectWithValue('Error');
  }
});

export const updateUser = createAsyncThunk('profile/updateUser', async (formData, thunkAPI) => {
  try {
    return await UserService.updateUser(formData);
  } catch (error) {
    if (error.response.status === 400) {
      return thunkAPI.rejectWithValue('Невірні дані');
    }
    return thunkAPI.rejectWithValue('Error');
  }
});

export const removeGoogle = createAsyncThunk('profile/removeGoogle', async (_, thunkAPI) => {
  try {
    return await UserService.removeGoogle();
  } catch (error) {
    return thunkAPI.rejectWithValue('Error');
  }
});

export const setPassword = createAsyncThunk('profile/setPassword', async (body, thunkAPI) => {
  try {
    return await UserService.setPassword(body);
  } catch (error) {
    if (error.response.status === 400) {
      return thunkAPI.rejectWithValue('Не вірній пароль');
    }
    return thunkAPI.rejectWithValue('Error');
  }
});

export const registrationByEmail = createAsyncThunk('profile/registrationByEmail', async (body, thunkAPI) => {
  try {
    return await UserService.registrationByEmail(body);
  } catch (error) {
    if (error.response.status === 400) {
      return thunkAPI.rejectWithValue('Перевірте email чи пароль');
    }
    return thunkAPI.rejectWithValue('Error');
  }
});

export const getDeeplinkAuth = createAsyncThunk('profile/getDeeplinkAuth', async (thunkAPI) => {
  try {
    return await UserService.getDeeplinkAuth();
  } catch (error) {
    if (error.response.status === 400) {
      return thunkAPI.rejectWithValue('Перевірте email чи пароль');
    }
    return thunkAPI.rejectWithValue('Error');
  }
});

export const registrationByPhone = createAsyncThunk('profile/registrationByPhone', async (body, thunkAPI) => {
  try {
    return await UserService.registrationByPhone(body);
  } catch (error) {
    if (error.response.status === 409) {
      return thunkAPI.rejectWithValue('Такий акаунт уже існує');
    }
    return thunkAPI.rejectWithValue('Error');
  }
});

export const loginByPhone = createAsyncThunk('profile/loginByPhone', async (body, thunkAPI) => {
  try {
    return await UserService.loginByPhone(body);
  } catch (error) {
    if (error.response.status === 409) {
      return thunkAPI.rejectWithValue('Не існує такого акаунту');
    }

    return thunkAPI.rejectWithValue('Error');
  }
});

export const verifiedCode = createAsyncThunk('profile/verifiedCode', async (body, thunkAPI) => {
  try {
    return await UserService.verifiedCode(body);
  } catch (error) {
    return thunkAPI.rejectWithValue('Невірний код');
  }
});

export const loginByEmail = createAsyncThunk('profile/loginByEmail', async (body, thunkAPI) => {
  try {
    return await UserService.loginByEmail(body);
  } catch (error) {
    if (error.code === 'ERR_BAD_REQUEST') {
      return thunkAPI.rejectWithValue('Невірний email чи пароль');
    }
    return thunkAPI.rejectWithValue('Error');
  }
});

export const logout = createAsyncThunk('profile/logout', async (_, thunkAPI) => {
  try {
    return await UserService.logout();
  } catch (error) {
    return thunkAPI.rejectWithValue('Error');
  }
});

export const addCar = createAsyncThunk('profile/addCar', async (formData, thunkAPI) => {
  try {
    return await UserService.addCar(formData);
  } catch (error) {
    if (error.response.status === 400) {
      return thunkAPI.rejectWithValue('Невірні дані');
    }
    return thunkAPI.rejectWithValue('Error');
  }
});

export const updateCar = createAsyncThunk('profile/updateCar', async (formData, thunkAPI) => {
  try {
    return await UserService.updateCar(formData);
  } catch (error) {
    if (error.response.status === 400) {
      return thunkAPI.rejectWithValue('Невірні дані');
    }
    return thunkAPI.rejectWithValue('Error');
  }
});

export const deleteCar = createAsyncThunk('profile/deleteCar', async (body, thunkAPI) => {
  try {
    return await UserService.deleteCar(body);
  } catch (error) {
    if (error.response.status === 400) {
      return thunkAPI.rejectWithValue('Невірні дані');
    }
    return thunkAPI.rejectWithValue('Error');
  }
});
