import React from 'react';
import './style.scss';
import { ReactComponent as Check } from 'assets/checkmarkerRed.svg';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import Button from '../../../../components/ui/Button';
import {
  updateCase,
  useCase,
} from '../../../../store/modules/Case';
import { useProfile } from '../../../../store/modules/Profile';
import UserService from '../../../../services/user.service';

function finishStep({
  setOpenModal,
}) {
  const dispatch = useDispatch();
  const { currentCase } = useCase();
  const { profile } = useProfile();

  const sendCase = () => {
    const dateStr = currentCase.answers[3]?.value.date;
    const dateObj = new Date(dateStr);

    const day = dateObj.getDate().toString().padStart(2, '0');
    const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
    const year = dateObj.getFullYear();

    const formattedDate = `${day}/${month}/${year}`;

    const matchingCar = profile.cars.find((car) => car._id === currentCase.answers[7].value);

    let carBrand = null;
    let carNumber = null;

    if (matchingCar) {
      carBrand = matchingCar.brand;
      carNumber = matchingCar.number;
    }

    const obj = {
      ...profile.data,
      resolutionNumber: currentCase.answers[3]?.value.number,
      resolutionDate: formattedDate,
      resolutionTime: currentCase.answers[3]?.value.time,
      carBrand,
      carNumber,
      email: profile?.email,
    };
    UserService.generatePDF(obj).then((data) => {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'file.pdf');
      document.body.appendChild(link);
      link.click();
    });
    const newCase = { ...currentCase };
    newCase.status = 'submitted';
    dispatch(updateCase(newCase)).then((state) => {
      if (!state.error) {
        setOpenModal(true);
      } else {
        toast.error('Помилка при відправленні');
      }
    });
  };

  return (
    <div className="finishStep">
      <div className="finishStep__title"><Check /> Готово до відправки</div>
      <div className="finishStep__desc">Усі підготовчі етапи успішно пройдені! Тепер Ви маєте можливість відправити заяву нам на розгляд.</div>
      <Button onClick={sendCase} className="black">Відправити заяву</Button>
    </div>
  );
}

export default finishStep;
